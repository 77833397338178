<template>
  <div class="fixed z-20">
    <div class="relative">
      <button
        v-ripple
        v-tooltip="{
          content: props.tooltipText,
          delay: { show: 1000, hide: 100 },
          disabled:
            isSmallerThanLg /** Disabled for mobile to show context menu on tap and hold */,
        }"
        class="h-10 w-10 rounded-full border border-white shadow-md shadow-lexmea-blue-400/50 transition duration-300 ease-in-out hover:shadow-none"
        :class="{
          'bg-[#597A87]': props.light,
          'bg-lexmea-blue-400': !props.light,
        }"
        :title="title"
      >
        <span class="flex justify-center text-white">
          <slot></slot>
        </span>
      </button>
      <slot name="contextMenu"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { isSmallerThanLg } = useLexMeaTailwindBreakpoints();
const props = withDefaults(
  defineProps<{
    tooltipText: string;
    light?: boolean;
    title: string;
  }>(),
  {
    light: false,
  }
);

const { vRipple } = useVRipple();
</script>
