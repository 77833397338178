import type { InjectionKey } from "vue";
import type { CanBeActive } from "~/models/IUserEntry";

const key: InjectionKey<Ref<CanBeActive | undefined>> = Symbol("activeEntry");

export function useActiveEntry() {
  const instance = getCurrentInstance();
  if (!instance) {
    throw new Error("useActiveEntry must be called within a setup function.");
  }

  const app = instance.appContext.app;
  const active = injectLocal(key, () => ref<CanBeActive>(), true);

  const provideActiveEntry = (entry: typeof active) => {
    app.provide(key, entry);
  };

  return {
    active,
    provideActiveEntry,
  };
}
