export function parseDateWithTime(dateString: string) {
  const date = new Date(dateString);
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();
  const hrs = date.getHours();
  const mins = date.getMinutes();
  return `${dd}.${mm}.${yyyy} um ${hrs < 10 ? `0${hrs}` : hrs}.${
    mins < 10 ? `0${mins}` : mins
  }`;
}

export function parseDate(dateString: string | undefined | null) {
  if (!dateString) return;

  const date = new Date(dateString);
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();
  return `${dd}.${mm}.${yyyy}`;
}
