import { LexmeaApiClient } from "~/api/apiClient";
import type IChannelSearchResponse from "~/models/teamMode/IChannelSearchResponse";
import type { ISchema } from "~/models/IUserEntry";
import type { ISubscribedChannel } from "~/models/teamMode/ISubscribedChannel";
import type IChannel from "~/models/teamMode/IChannel";
import type CreateChannelSubscriptionDTO from "~/dto/teamMode/createChannelSubscriptionDTO";
import type CreateChannelDTO from "~/dto/teamMode/createChannelDTO";

export class TeamModeClient extends LexmeaApiClient {
  searchUser = (name: string): Promise<IChannelSearchResponse> => {
    return this.get("api/search/user", {
      searchParams: {
        query: name,
      },
    });
  };
  shareSchema = (data: {
    schemaId: number;
    channelIds: number[];
  }): Promise<ISchema> => {
    return this.post(`api/schema/${data.schemaId}/share`, {
      channels: data.channelIds,
    });
  };

  getChannelSubscriptions = (): Promise<ISubscribedChannel[]> => {
    return this.get("api/channel/subscription");
  };
  unsubscribeChannel = (channelId: number): Promise<void> => {
    return this.delete(`api/channel/${channelId}/subscription`);
  };
  subscribeChannel = (
    data: CreateChannelSubscriptionDTO
  ): Promise<IChannel> => {
    const { channelId, ...rest } = data;
    return this.post(`api/channel/${channelId}/subscription`, rest);
  };

  listChannels = (): Promise<IChannel[]> => {
    return this.get("api/channel");
  };
  getChannel = (channelId: number): Promise<IChannel> => {
    return this.get(`api/channel/${channelId}`);
  };
  createChannel = (data: CreateChannelDTO): Promise<IChannel> => {
    return this.post("api/channel", { ...data });
  };
  deleteChannel = (channelId: number): Promise<void> => {
    return this.delete(`api/channel/${channelId}`);
  };
}
