export const faq_page = {
  hero_section: {
    main_title: "F.A.Q.",
    slogan: "We'll happily answer your questions!",
  },
  faq_sections: [
    {
      id: "online-lawbooks",
      title: "...about Online Law Books",
      items: [
        {
          question:
            "What are the advantages and disadvantages of analog law books?",
          answer: `Printed statute books are unfortunately still the only aids allowed in legal state exams in
Germany. Due to their weight, the red statute collections are also called 'bricks'. To have
an up-to-date statute book, the loose-leaf collections must be laboriously sorted before
the exam or a new one must be purchased. In any case, personalizations must - if
permitted - be transferred manually.`,
        },
        {
          question:
            "What are the advantages and disadvantages of a digital law book?",
          answer: `Digital statute books are not yet allowed as aids in legal exams. However, they do not
take up space or weight in the backpack and update automatically. The personalizations
are digitally stored for good and can thus be used even in the distant future. The core of
your legal knowledge is thus permanently preserved.`,
        },
        {
          question: "Should I use an analog or digital law book?",
          answer: `In daily work, however, digital statute books offer numerous advantages. They do not
take up space or weight and update automatically. On LexMea, the statute book can be
permanently personalized digitally and the knowledge can be stored permanently for
later work beyond the study/training phases. Since only printed statute books are
allowed in legal exams in Germany, you may still have to buy a printed law book shortly
before an exam and to transfer you personalisations one time`,
        },
      ],
    },
    {
      id: "web-app",
      title: "...about the Web App",
      items: [
        {
          question: "What can I do on LexMea?",
          answer: `LexMea combines in its Workspace everything you need for law in one place: constantly
updated legal texts and extensive personalization functions such as highlights, links,
bookmarks, own notes and documents as well as editorially created content. All this is
also compiled for you in a central overview page, the Library.`,
        },
        {
          question: "What is a Web App?",
          answer:
            "LexMea is a web application that is stored on a remote server and can be accessed by you with any web browser on your device under {emphasized}. So you don't have to download, install, and save a separate file before using it.",
          emphasized: "https://lexmea.de",
        },
        {
          question: "Why should I register with LexMea?",
          answer:
            "If you register, we can permanently save your personalizations and content on LexMea in your account. This is possible free of charge and will always remain free of charge. The registration process takes no longer than 2 minutes. Simply click on the profile icon at the top right of the page.",
        },
        {
          question: "Which laws can I find on LexMea?",
          answer:
            "On LexMea you will find all federal law which is relevant for in the German legal state exams, European law as well as selected norms of the most common focus areas.",
        },
        {
          question: "How up-to-date are the laws and can I rely on them?",
          answer:
            "We import federal law directly from the Federal Ministry of Justice and the Federal Office of Justice (gesetze-im-internet.de). We import European law directly from the official website of the European Union ({emphasized}). You can always find the status of our import under the respective norm.",
          emphasized: "https://eur-lex.europa.eu",
        },
        {
          question: "Where do the LexMea @.lower:documents come from?",
          answer:
            "The LexMea @.lower:documents are editorially created by the content team at LexMea. The quality is ensured by the two CEOs, who both graduated with honors and teach at various universities.",
        },
        {
          question: "Can I download and save my own @.lower:documents locally?",
          answer:
            "Yes, you can download and save your @.lower:documents as a PDF file via the download button on each document as well as on your central overview page, the Library.",
        },
        {
          question: "Will my content be deleted?",
          answer:
            "No, your content will remain securely stored permanently, unless you delete it yourself in the app. Even in this case, it will remain in the trash for 14 days for recovery before it is permanently and irrevocably deleted.",
        },
        {
          question: "How do I report errors or suggestions for improvement?",
          answer:
            'We want to continuously develop LexMea and adapt it to your needs! If you notice an error or have suggestions for improvement, please send them to {emphasized}. You can also use our feedback form "Give Feedback". You can open this either via the link at the bottom right of the page or logged in behind your profile icon at the top right of the page.',
          emphasized: "feedback@lexmea.de",
        },
        {
          question:
            "Can I reuse and edit the @.lower:documents from LexMea without violating copyrights?",
          answer:
            "LexMea @.lower:documents may be retrieved, downloaded, saved locally, copied, and modified for private purposes. Further reproductions or other use or exploitation of documents - especially for commercial purposes - are only permitted with prior written consent from LexMea. Users may pass on copies of editorially created content to individual third parties (e.g. colleagues in the context of legal and tax advice or fellow students). The systematic distribution of editorially created content or their systematic provision to third parties as well as the use for the purpose of commercial information provision (research and document retrieval on behalf of a third party) are not permitted.",
        },
        {
          question: "How can I delete/recover my account?",
          answer:
            "You can delete your account at any time in your account settings. To prevent unauthorized deletions, you need your password for this. We will then confirm your deletion by email and send you a link to recover it, which is valid for 14 days. After that, all your data will be irrevocably removed from our servers.",
        },
      ],
    },
    {
      id: "company",
      title: "...about the Company",
      items: [
        {
          question: "Is LexMea free?",
          answer:
            "Yes, LexMea is free and the basic functions will always remain so. Pro accounts are planned for additional features in the future.",
        },
        {
          question: "How is LexMea financed?",
          answer:
            "Your data will never be passed on to third parties. Personalization takes place exclusively on European servers. For example, we will show job offers in labor law next to norms from this legal area.",
          // "LexMea will be financed through advertisements (especially for legal job offers). Your data will never be passed on to third parties for this purpose. Personalization takes place exclusively on our own European servers. For example, we show job offers in labor law next to norms from this legal area.",
        },
        {
          question: "Who is LexMea?",
          answer:
            "LexMea is a start-up based in Berlin. It was founded by the two lawyers Michael and Tobias Strecker. Both teach at universities and thus discovered the need for a tool like LexMea. An overview of all employees can be found on our team page.",
        },
        {
          question: "Who can I contact if I have questions?",
          answer:
            "If you have any questions, please feel free to contact us at any time under {emphasized}. We also welcome feedback or requests for the use of LexMea.",
          emphasized: "kontakt@lexmea.de",
        },
      ],
    },
    {
      id: "data-protection",
      title: "...about Data Protection",
      items: [
        {
          question: "Is my data safe with LexMea?",
          answer:
            "Yes, data protection and data security are top priorities at LexMea. LexMea regularly backs up all data stored on the website on separate European servers. Thus, even in the unlikely event of damage to the main server, recovery is possible. We also secure our site according to the state of the art against external attacks (e.g. DDOS protection, Web Access Firewall). We also conduct penetration tests - authorized, simulated attacks on our site - to assess its security and identify any vulnerabilities.",
        },
        {
          question: "Will my data remain in Europe (protected by the GDPR)?",
          answer:
            "Yes, LexMea refrains from using services that transfer your data to the USA (such as Google Analytics or the Meta Pixel) to keep your data safe. We store your data securely on exclusively European servers. The integrity of this data is ensured by a contract for order processing (AV contract) that we have concluded with the server operator.",
        },
        {
          question: "Will my data be sold?",
          answer:
            "No, your data will never be sold. We finance ourselves through other sources, including advertisements or fees for premium features. If we personalize the displayed content, this takes place exclusively on our own European servers.",
        },
      ],
    },
  ],
};
