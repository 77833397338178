interface State {
  width: number;
}

export const useNavbarWidthStore = defineStore("navbarWidthStore", {
  state: (): State => {
    return {
      width: 0,
    };
  },

  actions: {
    setWidth(xPos: number) {
      this.width = xPos;
    },
  },

  getters: {
    navbarWidth(state) {
      return computed(() => `${state.width}px`);
    },
  },
});
