export function useSiteWindowSize() {
  const windowSize = useWindowSize();
  return computed(() => {
    return windowSize.width.value > 768 ? "right" : "top";
  });
}

export const useIsLargeScreen = () => {
  const windowSize = useWindowSize();
  return computed(() => windowSize.width.value >= 1048);
};
