import type { DeskTab } from "~/models/ITab";

export function useTabRoute() {
  const { active } = useActiveEntry();

  return (tab: DeskTab) => {
    const { legalText, paragraph = "" } = tab;
    let path = `/gesetz/${legalText}/${paragraph}`;

    if (active.value?.type === "schema") {
      const { id, type, slug, canonical_path } = active.value;
      if (slug && path === canonical_path) {
        path += `/${slug}`;
      } else if (id) {
        const urlParams = new URLSearchParams({ id: id.toString(), type });
        path += "?" + urlParams.toString();
      }
    }
    return path;
  };
}
