import { createApp } from "./app";
import posthog from "posthog-js";
import { hydrate } from "@tanstack/vue-query";
import type { PageContextClient } from "vike/types";
import "~/scss/index.scss";
import "floating-vue/dist/style.css";

let app: Awaited<ReturnType<typeof createApp>>["app"];

export async function onRenderClient(pageContext: PageContextClient) {
  if (!app) {
    const obj = await createApp(pageContext);
    app = obj.app;
    const { posthogKey } = pageContext.envs;

    if (import.meta.env.PROD) {
      registerPosthog(posthogKey);
    }

    obj.store.state.value = pageContext.INITIAL_STATE;
    hydrate(obj.queryClient, pageContext.vueQueryState);
    app.mount("#app");
  } else {
    app.changePage(pageContext);
  }
}

function registerPosthog(key: string) {
  posthog.init(key, {
    api_host: "/ph/ingest",
    ui_host: "https://eu.posthog.com",
    persistence: "memory",
  });
  app.provide("posthog", posthog);
}
