<template>
  <Combobox
    :key="forceRenderKey"
    v-model="model"
    :by="compareBy"
    :nullable="nullable"
    :multiple="multiple"
  >
    <div>
      <ComboboxLabel
        class="relative z-10 block"
        :class="{
          'ml-8': prependIcon,
        }"
      >
        <LexmeaHoverLabel
          v-if="hoverLabel"
          :active="focused || !!query || !!model"
          :focused="focused"
          :label="label"
        />
        <span v-else class="sr-only">{{ label }}</span>
      </ComboboxLabel>
      <div ref="wrapperEl" class="relative">
        <span
          v-if="prependIcon"
          class="absolute flex h-full items-center"
          :class="{
            'right-3': iconPlace === 'right',
            'left-3': iconPlace === 'left',
          }"
        >
          <slot name="icon">
            <img :src="search" class="h-4 w-4" :alt="$t('ui.search.title')" />
          </slot>
        </span>
        <ComboboxInput
          ref="inputEl"
          v-slot="{ open }"
          class="w-full truncate rounded border-none outline-none ring-1 ring-lexmea-gray-700 transition-all placeholder:text-center placeholder:text-sm placeholder:font-medium placeholder:text-gray-400 focus:ring-lexmea-pop-darker md:h-auto"
          :class="{
            'max-md:py-2': !lowHeightOnMobile,
            'max-md:py-1': lowHeightOnMobile,
            'focus:ring-2': thickRing,
            'focus:ring-lexmea-selection-darker': lightRing,
            'bg-white': theme === 'white',
            'bg-lexmea-gray-100': theme === 'grey',
          }"
          as="template"
          :displayValue="(item) => (item ? displayValue(item as T) : '')"
          :placeholder="placeholder"
          @focus="focused = true"
          @blur="focused = false"
          @change="query = $event.target.value"
          @keydown.enter.capture="handleEnterKey"
        >
          <input
            :class="{
              'rounded-b-none': open,
              'px-10 max-md:px-8': prependIcon,
            }"
          />
        </ComboboxInput>
        <Transition name="fade">
          <button
            v-if="query || model"
            class="absolute top-0 flex h-full items-center text-gray-700"
            :class="{
              'left-3': iconPlace === 'right',
              'right-3': iconPlace === 'left',
            }"
            @click="
              () => {
                query = '';
                model = null;
                forceRenderKey++;
              }
            "
          >
            <span class="sr-only">Suche löschen</span>
            <i-heroicons-x-mark-20-solid class="h-4 w-4" aria-hidden="true" />
          </button>
        </Transition>
      </div>
      <TransitionRoot
        as="template"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        @afterLeave="query = ''"
      >
        <div>
          <ComboboxOptions
            v-scroll-sync
            class="z-20 max-h-[calc(100dvh-6.2rem)] w-[95vw] origin-top list-none divide-y divide-lexmea-gray-400 overflow-y-auto overflow-x-hidden rounded-b bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:w-full"
            :class="[dropdownClasses, dropdownFixed ? 'fixed' : 'absolute']"
          >
            <li
              v-if="loading"
              class="relative w-full max-w-full cursor-default select-none px-4 py-2"
            >
              <i-line-md-loading-twotone-loop class="mx-auto h-8 w-8" />
            </li>
            <li
              v-else-if="options.length === 0 && query !== ''"
              class="relative w-full max-w-full cursor-default select-none px-4 py-2"
            >
              Keine Ergebnisse.
            </li>
            <template v-for="(option, index) in options">
              <li
                v-if="option.heading"
                :key="`heading-${optionsKey(option, index)}`"
                :class="option.classes"
              >
                <slot
                  name="heading"
                  :option="option as Exclude<T, SelectableOption>"
                  :index="index"
                >
                  {{ option }}
                </slot>
              </li>
              <ComboboxOption
                v-else
                :key="optionsKey(option, index)"
                v-slot="{ active, selected }"
                as="template"
                :value="option"
                :disabled="option.disabled"
              >
                <li
                  class="lexmea-border-gray-400 block w-full cursor-pointer select-none rounded border-b px-2 text-left transition-colors"
                  :class="{
                    'bg-gray-200': active,
                    'font-medium': selected,
                    'font-normal': !selected,
                    'py-2': !compact,
                  }"
                >
                  <slot
                    :option="option as SelectableOption"
                    :index="index"
                    :active="active"
                    :selected="selected"
                  >
                    {{ option }}
                  </slot>
                </li>
              </ComboboxOption>
            </template>
            <slot name="info" />
          </ComboboxOptions>
        </div>
      </TransitionRoot>
    </div>
  </Combobox>
</template>

<script
  lang="ts"
  setup
  generic="
    T extends object & {
      heading?: true;
      disabled?: boolean;
      classes?: string[];
    }
  "
>
import search from "~/assets/landingpage/icon_search_lg.svg";
import useLexMeaTailwindBreakpoints from "~/composables/useLexMeaTailwindBreakpoints";

type SelectableOption = Exclude<T, { heading: true }>;

interface Props {
  displayValue: (item: T) => string;
  // should be T but throws a type error for some reason
  selectedItem?: string | number | boolean | object | null | undefined;
  query?: string;
  options: T[];
  hoverLabel?: boolean;
  label: string;
  prependIcon?: boolean;
  compareBy?: string | ((item: T) => keyof T);
  compareOptionsBy?: string | ((item: T) => keyof T);
  nullable?: boolean;
  multiple?: boolean;
  dropdownClasses?: string;
  dropdownFixed?: boolean;
  compact?: boolean;
  theme?: "white" | "grey";
  loading?: boolean;
  thickRing?: boolean;
  lightRing?: boolean;
  iconPlace?: "left" | "right";
  showPlaceholderOnFocus?: boolean;
  lowHeightOnMobile?: boolean;
  clearModelOnFocusOut?: boolean;
}

type Emits = {
  "update:selectedItem": [data: SelectableOption];
  "update:query": [data: string];
};

const props = withDefaults(defineProps<Props>(), {
  selectedItem: undefined,
  query: "",
  hoverLabel: false,
  prependIcon: false,
  compareBy: undefined,
  compareOptionsBy: undefined,
  nullable: false,
  multiple: false,
  dropdownClasses: "",
  dropdownFixed: false,
  compact: true,
  theme: "white",
  loading: false,
  thickRing: false,
  lightRing: false,
  iconPlace: "left",
  showPlaceholderOnFocus: false,
  lowHeightOnMobile: false,
  clearModelOnFocusOut: false,
});
const emit = defineEmits<Emits>();

const model = useVModel(props, "selectedItem", emit);

const query = useVModel(props, "query", emit);

const focused = ref(false);

const { isSmallerThanMd } = useLexMeaTailwindBreakpoints();

whenever(
  () => !focused.value && props.clearModelOnFocusOut,
  () =>
    setTimeout(() => {
      query.value = "";
      model.value = null;
      forceRenderKey.value++;
    }, 1)
);

const inputEl = ref<HTMLInputElement>();

const forceRenderKey = ref(0);

whenever(model, async () => {
  await nextTick();
  unrefElement(inputEl)?.setSelectionRange(0, 0);
});

const optionsKey = (item: T, index: number) => {
  if (!props.compareOptionsBy) {
    return index;
  } else if (typeof props.compareOptionsBy === "function") {
    return props.compareOptionsBy(item);
  }
  return item[props.compareOptionsBy];
};

defineExpose({
  blur: () => {
    unrefElement(inputEl)?.blur();
  },
  focus: () => {
    unrefElement(inputEl)?.focus();
  },
});

const wrapperEl = ref<HTMLDivElement>();
const { vScrollSync } = useVScrollSync(() =>
  props.dropdownFixed ? wrapperEl.value : undefined
);

const handleEnterKey = (event: Event) => {
  if (props.options.every((option) => option.heading)) {
    event.preventDefault();
  }
};

// if there's a "Hover label" or if it's on mobile and showPlaceholderOnFocus is enabled but the component is not focused, don't show the placeholder
const placeholder = computed(() =>
  props.hoverLabel ||
  (isSmallerThanMd.value && props.showPlaceholderOnFocus && !focused.value)
    ? undefined
    : props.label
);
</script>
