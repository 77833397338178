import { useSeoMeta } from "@unhead/vue";
import * as colors from "~/../colors.json";

export function useMetadata(
  titleIn?: MaybeRefOrGetter<string | undefined>,
  descriptionIn?: MaybeRefOrGetter<string | undefined>
) {
  const pageContext = usePageContext();

  const title = computed(() => {
    // have different order for static pages from legal texts
    if (pageContext.config.documentProps?.title) {
      return "LexMea | " + pageContext.config.documentProps?.title;
    } else if (toValue(titleIn)) {
      return toValue(titleIn) + " | LexMea";
    }

    return "LexMea";
  });

  const description = computed(
    () =>
      toValue(descriptionIn) ||
      pageContext.config.documentProps?.description ||
      "Dein online Gesetzbuch"
  );

  const siteImage = "/favicon.png";
  const { fullUrl } = useCanonicalUrl();

  useSeoMeta({
    title: () => title.value,
    description: () => description.value,
    charset: "utf-8",
    viewport:
      "width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no",
    themeColor: colors.lexmea.blue["400"],
    ogLocale: "de_DE",
    ogSiteName: "Lexmea",
    ogType: "article",
    ogTitle: () => title.value,
    ogDescription: () => description.value,
    ogUrl: () => fullUrl.value,
    ogImageSecureUrl: siteImage,
    twitterCard: "summary",
    twitterTitle: () => title.value,
    twitterDescription: () => description.value,
    twitterImage: siteImage,
  });
}
