import { useModal, useModalSlot, useVfm } from "vue-final-modal";
import LexmeaModal from "~/components/modal/LexmeaModal.vue";
import IssueReportForm from "~/components/footer/IssueReportForm.vue";

import { useI18n } from "vue-i18n";

const issueReportId = Symbol("issue report modal id");

export function useFeedbackModal() {
  const { t } = useI18n();
  const hide: Ref<boolean> = useLocalStorage("hide-feedback-fab", false, {
    initOnMounted: true,
  });
  const { open, close } = useModal({
    component: LexmeaModal,
    attrs: {
      contentClass: "w-96",
      modalId: issueReportId,
    },
    slots: {
      title: computed(() => t("feedback_form.give_feedback")),
      default: useModalSlot({
        component: IssueReportForm,
        attrs: {
          onClose: () => close(),
        },
      }),
    },
  });

  const vfm = useVfm();

  const isOpen = computed(() => !!vfm.get(issueReportId));

  return { open, close, hide, isOpen };
}
