export function useTimeCounter(init: number, min: number, max: number) {
  const { count, dec, reset } = useCounter(init, { min, max });

  const { pause, resume, isActive } = useIntervalFn(
    () => {
      dec();
    },
    1000,
    { immediate: false }
  );

  watchEffect(() => {
    if (count.value === min) {
      pause();
      reset();
    }
  });

  return { isActive, count, reset, pause, resume };
}
