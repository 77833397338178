import de from "~/i18n/de";
import en from "~/i18n/en";
import { createI18n } from "vue-i18n";
import type { PageContext } from "vike/types";

export const messages = {
  de,
  en,
};

export const i18nPlugin = (pageContext: PageContext) => {
  const localeCookie = useLanguageCookie(pageContext);
  return createI18n<[typeof messages.en], "en" | "de">({
    locale: localeCookie.value ?? "de",
    fallbackLocale: "en",
    legacy: false,
    globalInjection: true,
    messages,
  });
};
