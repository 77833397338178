<template>
  <div ref="wrapper" class="flex flex-row gap-4" @keypress.enter="onEnter">
    <input
      v-for="(_, index) in code"
      :id="'input_' + index"
      :key="index"
      v-model="code[index]"
      type="number"
      class="h-12 w-1/5 rounded-sm border-none bg-white text-center text-2xl outline-none ring-2 ring-lexmea-gray-700 transition-all focus:ring-lexmea-pop-darker"
      pattern="\d*"
      maxlength="1"
      min="0"
      max="9"
      @input="handleInput"
      @keypress="handleKeyPress"
      @keydown.delete.prevent="handleDelete"
      @keydown.left="move('left', $event)"
      @keydown.right="move('right', $event)"
      @paste="onPaste"
    />
  </div>
  <Transition name="scale">
    <div v-if="error.length > 0" class="mt-4 text-sm text-maroon-flush">
      {{ error }}
    </div>
  </Transition>
</template>

<script lang="ts" setup>
const props = defineProps<{
  error: string;
}>();
const { error } = toRefs(props);
const emit = defineEmits<{
  "code:updated": [code: string];
  enterPressed: [];
}>();

const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
const CODE_LENGTH = 5;
let dataFromPaste: string[] | undefined;
const code = ref<(number | undefined)[]>(Array(CODE_LENGTH));
const result = computed(() => code.value.join(""));
watch(result, () => emit("code:updated", result.value));

// needed to reset the code from the parent
const reset = () => {
  code.value = Array(CODE_LENGTH);
  focusFirstInput();
};
defineExpose({ reset });

const onEnter = () => {
  if (result.value.length === CODE_LENGTH) {
    emit("enterPressed");
  }
};

const move = (moveTo: "left" | "right", event: KeyboardEvent) => {
  const currentActiveElement = event.target as HTMLElement;
  if (moveTo === "right" && currentActiveElement.nextElementSibling) {
    (currentActiveElement.nextElementSibling as HTMLElement).focus();
  } else if (moveTo === "left" && currentActiveElement.previousElementSibling) {
    (currentActiveElement.previousElementSibling as HTMLElement).focus();
  }
};

const handleKeyPress = (event: KeyboardEvent) => {
  const keyPressed = event.key;
  if (keysAllowed.includes(keyPressed)) {
    // the value in the array needs to be reset here. Otherwise the
    // number would get appended to the previous number
    const id = getID(event.currentTarget as HTMLInputElement);
    code.value[id] = undefined;
  } else {
    // prevent all inputs that are not numbers.
    event.preventDefault();
  }
};

const handleInput = (event: Event) => {
  const inputType = (event as InputEvent).inputType;
  let currentActiveElement = event.target as HTMLInputElement;

  if (inputType === "insertText")
    (currentActiveElement.nextElementSibling as HTMLElement)?.focus();

  if (inputType === "insertFromPaste" && dataFromPaste) {
    for (const num of dataFromPaste) {
      const id = getID(currentActiveElement);
      currentActiveElement.value = num;
      code.value[id] = parseInt(num);
      if (currentActiveElement.nextElementSibling) {
        currentActiveElement =
          currentActiveElement.nextElementSibling as HTMLInputElement;
        currentActiveElement.focus();
      }
    }
  }
};

const handleDelete = (event: Event) => {
  const currentActiveElement = event.target as HTMLInputElement;
  // only focus the previous input, if there was no digit deleted
  if (!currentActiveElement.value)
    (currentActiveElement.previousElementSibling as HTMLElement)?.focus();

  const id = getID(currentActiveElement);
  code.value[id] = undefined;
};

const onPaste = (event: Event) => {
  dataFromPaste = (event as ClipboardEvent).clipboardData
    ?.getData("text")
    .trim()
    .split("", CODE_LENGTH);

  if (dataFromPaste) {
    for (const digit of dataFromPaste) {
      if (!keysAllowed.includes(digit)) {
        event.preventDefault();
      }
    }
  }
};
const getID = (element: HTMLInputElement) => parseInt(element.id.split("_")[1]);

const wrapper = ref<HTMLElement>();
const focusFirstInput = () =>
  (wrapper.value?.firstElementChild as HTMLElement)?.focus();

onMounted(() => focusFirstInput());
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
