import { useHead } from "@unhead/vue";

export default function useSeoHead() {
  const { baseUrl, path, fullUrl } = useCanonicalUrl();

  useHead({
    meta: [
      {
        name: "x-ua-compatible",
        content: "IE=edge",
      },
    ],
    link: [
      {
        /* important to have this hard-coded to main link so that
         * incase the crawler reaches the subdomains that won't be an issue
         */
        href: fullUrl.value,
        rel: "canonical",
      },
    ],
    templateParams: {
      schemaOrg: {
        host: baseUrl,
        path,
        inLanguage: "de-DE",
      },
    },
  });
}
