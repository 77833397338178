<!-- eslint-disable vue/no-v-html -->
<template>
  <ParagraphLink
    class="group flex"
    :legalText="paragraph.legal_text.slug"
    :legalTextParagraph="paragraph.slug"
  >
    <div class="grow overflow-x-hidden rounded rounded-r-lg px-4 py-[.4rem]">
      <div
        class="flex items-center justify-between truncate text-xs leading-tight"
      >
        <p class="truncate">
          <!-- beware - do not refactor this into a separate component! as of Vue 3.2.47 the component's elements one would insert here do not have parentNode set, which breaks vike's interception of clicks on links -->
          <span
            v-for="(span, i) in legalTextHighlighted"
            :key="i"
            :class="{ 'font-bold': span.match }"
          >
            {{ span.text }}
          </span>
        </p>
        <span v-if="!active" class="hidden italic md:inline-block">
          {{ folName }}
        </span>
      </div>
      <p
        class="block truncate text-lg leading-[1.6rem] text-lexmea-pop-darkest"
      >
        <span
          v-for="(span, i) in paragraphHighlighted"
          :key="i"
          :class="{ 'font-bold': span.match }"
        >
          {{ span.text }}
        </span>
      </p>
      <p class="block truncate text-xs" v-html="paragraph?.text_highlight"></p>
    </div>
    <button
      v-if="active"
      class="hidden w-20 items-center justify-center text-gray-400 sm:flex"
      :class="{ 'text-inherit': active }"
      title="in neuem Tab öffnen"
      @click.prevent.stop="$emit('openInNewTab', paragraph)"
    >
      <i-heroicons-arrow-top-right-on-square-20-solid class="h-5 w-5" />
    </button>
  </ParagraphLink>
</template>

<script lang="ts" setup>
import type { SearchParagraph } from "~/api/legalTextParagraph";

interface Props {
  active: boolean;
  paragraph: SearchParagraph;
  query: string;
}

type Emits = {
  openInNewTab: [paragraph: SearchParagraph];
};

const props = defineProps<Props>();
defineEmits<Emits>();

const { paragraph, query } = toRefs(props);

const legalTextName = computed(() => paragraph.value.legal_text.full_name);

const { data: allFieldsOfLaw } = useFetchAllFieldOfLaws();

const folName = computed(() => {
  const folId =
    paragraph.value.field_of_law_primary_id ??
    paragraph.value.legal_text.field_of_law_id;

  const fol = allFieldsOfLaw.value?.find((f) => f.id === folId);
  return fol?.name ?? "";
});

const { highlighted: legalTextHighlighted } = useHighlighting(
  legalTextName,
  query
);

const { title } = useFullParagraphTitle(paragraph);

const { highlighted: paragraphHighlighted } = useHighlighting(title, query);
</script>
