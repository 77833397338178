export type HighlighterResult = {
  match: boolean;
  text: string;
};

export const useHighlighting = (
  text: MaybeRef<string>,
  query: MaybeRef<string>
) => {
  const queryRegex = computed(() => {
    const words = unref(query)
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
      .replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
      .split(" ")
      .filter((word) => word.length)
      .join("|");
    return new RegExp(`(?:${words})`, "gi");
  });

  const matches = computed(() =>
    // only do matching when text is truthy
    unref(text)
      ? Array.from(unref(text).matchAll(queryRegex.value)).map((match) => ({
          index: match.index!,
          size: match[0].length,
        }))
      : []
  );

  const ranges = computed(() => {
    const ranges: { match: boolean; start: number; end: number }[] = [];

    if (matches.value.length === 0) {
      ranges.push({
        match: false,
        start: 0,
        end: unref(text).length,
      });
      return ranges;
    }
    if (matches.value[0].index > 0) {
      ranges.push({
        match: false,
        start: 0,
        end: matches.value[0].index,
      });
    }

    for (const i in matches.value) {
      const match = matches.value[i];
      const end = match.index + match.size;
      ranges.push({
        match: true,
        start: match.index,
        end,
      });
      const nextMatch = matches.value[parseInt(i) + 1];
      if (nextMatch && nextMatch.index > end) {
        ranges.push({
          match: false,
          start: end,
          end: nextMatch.index,
        });
      }
    }

    if (ranges[ranges.length - 1].end < unref(text).length) {
      ranges.push({
        match: false,
        start: ranges[ranges.length - 1].end,
        end: unref(text).length,
      });
    }

    return ranges;
  });

  const highlighted = computed<HighlighterResult[]>(() => {
    // improves performance
    if (unref(query).trim().length === 0) {
      return [{ text: unref(text), match: false }];
    }
    return ranges.value.map((range) => ({
      text: unref(text).slice(range.start, range.end),
      match: range.match,
    }));
  });

  return {
    highlighted,
  };
};
