export const legal_text = {
  import_local_entries_modal: {
    title: "Inhalte importieren",
    are_you_sure: "Bist du sicher, dass du die Inhalte importieren möchtest?",
    back: "Zurück",
    abort: "Verwerfen",
    import: "Importieren",
    not_logged_in_added:
      "Du hast Inhalte angelegt, ohne eingeloggt gewesen zu sein:",
    import_to_account:
      "Sollen wir diese Inhalte in deinen Account importieren?",
  },
  leftColumn: {
    toc: "Inhaltsverzeichnis",
    expand: "Ausklappen",
    collapse: "Einklappen",
    references: "Verweise",
    in: "in ",
    to: "zu ",
    on: "auf ",
    no_incoming_references: "Keine eingehenden Verweise.",
    no_outgoing_references: "Keine ausgehenden Verweise.",
    tooltips: {
      toc: "Zum Inhaltsverzeichnis",
      expand: "Alle Überschriften ausklappen",
      collapse: "Alle Überschriften einklappen",
    },
  },
  middleColumn: {
    disclosure: "Hinweis",
    navigation: {
      add_tab: "Tab hinzufügen",
      new_tab: "Neuer Tab",
      delete_tab: "Tab löschen",
      scroll_tabs_to_left: "Tabs nach links scrollen",
      scroll_tabs_to_right: "Tabs nach rechts scrollen",
      next_paragraph: "Nächster Paragraph",
      previous_paragraph: "Vorheriger Paragraph",
      close_old_version: "Alte Version schließen",
      old_version_long: "a.F. (alte Fassung)",
      old_version_short: "a.F.",
      old_version: "alte Fassung",
      old_version_available: "a.F. verfügbar",
      current_version: "aktuelle Fassung",
      until: "bis ",
      source: "Quelle: ",
      imported_from: "Importiert von ",
      imported: "Import:",
      type_of_reference: "Art des Verweises: ",
    },
    schema_editor: {
      download_schemata: "Schemata herunterladen",
      download: "herunterladen",
      not_logged_in_tooltip:
        "Logge dich ein, um deine Schemata herunterzuladen.",
      can_not_download_curated_text:
        "Nur selbst erstellte Inhalte können gesammelt heruntergeladen werden.",
    },
    newSchemata: {
      header: "Hier neue Schemata oder Notizen anlegen",
      text: "... durch Klicken auf ein {clipboardIcon} oder {paperIcon} in der Symbolleiste über dem Gesetzestext.",
    },
    existingSchemata: {
      header: "Hier vorhandene Schemata / Notizen anzeigen",
      text: "... durch Klicken auf das {clipboardIcon} oder {paperIcon} im Gesetzestext oder in der Übersichts-Spalte rechts.",
    },
  },
  reference: {
    create_reference: "Verweis erstellen",
    add_another_norm: "Weitere Norm hinzufügen",
    save: "Speichern",
    inputs: {
      type_of_reference: "Art des Verweises (optional)",
      norm: "Norm*",
      additional_text: "Abs. / S. / Nr. / Var (optional)",
    },
  },
  context: {
    edit: "Bearbeiten",
    delete_all: "Alle löschen",
    move: "Verschieben",
  },

  textEditor: {
    status: {
      autoSave: "Autosave",
      active: "aktiv",
      inactive: "inaktiv",
      abort: "Abrrechen",
      last_edited: "Zuletzt bearbeitet:",
      hide: "Verstecken",
      publish: "Veröffentlichen",
      confirm_unpublish: "Wirklich offline nehmen?",
      confirm_publish: "Wirklich veröffentlichen?",
    },
    buttons: {
      schema: "Schema",
      note: "Notiz",
      link_chain: "Verweis",
      underline: "Unterstreichung",
      marker: "Markieren",
      delete: "Löschen",
      bookmark: "Lesezeichen",
      remove_bookmark: "Lesezeichen entfernen",
      eraser: "Radierer",
      close: "schließen",
      table_of_contents: "Inhaltsverzeichnis",
      pdf_download: "PDF-Download",
    },
    fontsize: {
      title: "Schriftgröße",
    },
    colors: {
      title: "Farbe",
      yellow: "Gelb",
      orange: "Orange",
      red: "Rot",
      purple: "Violett",
      blue: "Blau",
      green: "Grün",
      light_yellow: "Helles Gelb",
      light_orange: "Helles Orange",
      light_red: "Helles Rot",
      light_purple: "Helles Violett",
      light_blue: "Helles Blau",
      light_green: "Helles Grün",
    },
    blockFormats: {
      heading1: "Überschrift 1",
      standard: "Standard",
      heading2: "A. Überschrift 2",
      heading3: "I. Überschrift 3",
      heading4: "1. Überschrift 4",
      heading5: "a) Überschrift 5",
      heading6: "aa) Überschrift 6",
      heading7: "(1) Überschrift 7",
      heading8: "(a) Überschrift 8",
      heading9: "(aa) Überschrift 9",
      heading10: "i. Überschrift 10",
    },
    tooltips: {
      summary: "Zusammenfassung",
      problem: "Problem",
      definition: "Definition",
      reminder: "Markbox",
      structure_note: "Aufbauhinweis",
      close_schema: "Schema schließen",
      schema: "Schema",
      note: "Notiz",
      link_chain: "Verweis auf andere Norm",
      underline: "Unterstreichung",
      marker: "Markierung",
      delete: "Löschen",
      bookmark: "Lesezeichen an Norm",
      eraser: "Radierer",
      select_color: "Farbe auswählen",
      edit_schema: "Schema bearbeiten",
      stop_editing: "Bearbeiten beenden",
      pdf_download: "PDF-Download",
      only_self_created_can_be_deleted:
        "Nur selbst erstellte Schemata können gelöscht werden.",
    },
    errors: {
      color_could_not_be_changed: "Farbe konnte nicht geändert werden.",
      marking_could_not_be_moved: "Markierung konnte nicht verschoben werden.",
      marking_could_not_be_added: "Markierung konnte nicht angelegt werden.",
      link_chain_could_not_be_added: "Verweis konnte nicht angelegt werden.",
      content_could_not_be_changed: "Inhalt konnte nicht geändert werden.",
    },
    helper_texts: {
      move_schema: `Klicke auf eine Textstelle in der Norm, um das Schema "{entry}" zu verschieben.`,
      move_note: `Klicke auf eine Textstelle in der Norm, um die Notiz "{entry}" zu verschieben.`,
      move_link_chain: `Klicke auf eine Textstelle in der Norm, um die Verweisung zu verschieben.`,
      schema: "Schema anlegen: In Normtext klicken",
      note: "Notiz anlegen: In Normtext klicken.",
      link_chain: "Verweis anlegen: In Normtext klicken.",
      underline: "Unterstreichen: Normtext markieren.",
      mark: "Farbig markieren: Normtext markieren.",
      eraser: "Löschen: Auf Personalisierung klicken.",
      bookmarkAdded: "Lesezeichen hinzugefügt",
      bookmarkRemoved: "Lesezeichen entfernt",
    },
  },
  rightColumn: {
    schemas: {
      title: "Schemata",
      to: "zu ",
      no_schemas_for_entry: "Keine Schemata zur Norm vorhanden.",
      no_related_schemas: "Keine verwandten Schemata vorhanden.",
      lexmea_schemas: "LexMea-Schemata",
      own_schemas: "Eigene Schemata",
      not_logged_in_tooltip: "Logge dich ein, um eigene Schemata zu sehen.",
    },
    note_types: {
      knowledge: "Wissen",
      definition: "Definition",
      note: "Notiz",
    },
    notes: {
      title: "Notizen",
      to: "zu ",
      options: "Optionen",
      to_norm: "zur Norm ",
      delete_note: "Notiz löschen",
      no_notes_for_entry: "Keine Notizen vorhanden.",
      add_new_note_by_clicking:
        "Lege durch Klicken auf das Notiz-Symbol in der Toolbar eine Notiz im Normtext an, um sie hier anzuzeigen.",
    },
  },
};
