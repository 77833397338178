<template>
  <label class="block">
    <span class="text-sm text-gray-700" :class="{ 'font-bold': required }"
      >{{ label }}
      <span>{{ required ? "*" : "(optional)" }}</span>
    </span>
    <div
      class="flex w-full flex-col rounded border-0 bg-wild-sand p-1 transition-all disabled:bg-gray-300"
      :class="{
        'border-lexmea-selection-darker bg-white shadow outline-none ring-1 ring-lexmea-pop-darker':
          focused,
        'border border-maroon-flush': error,
      }"
    >
      <div v-if="maxChars" class="flex h-1/6 justify-end">
        <p class="text-xs">{{ model?.toString().length }}/{{ maxChars }}</p>
      </div>
      <textarea
        v-model="model"
        class="h-5/6 w-full resize-none rounded border-none bg-transparent pb-2"
        v-bind="$attrs"
        :maxLength="maxChars"
        :required="required"
        @focus="focused = true"
        @blur="focused = false"
      />
    </div>
    <button v-if="hasClearButton && model.length > 0">
      <span class="text-xs font-bold text-lexmea-blue-500" @click="model = ''"
        >LEEREN</span
      >
    </button>
    <Transition name="scale">
      <span v-if="error" class="text-sm text-maroon-flush">
        {{ typeof error === "string" ? error : error[1] }}
      </span>
    </Transition>
  </label>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    label: string;
    modelValue: string;
    error?: string | string[];
    maxChars?: number | undefined;
    required?: boolean;
    hasClearButton?: boolean;
  }>(),
  {
    modelValue: "",
    error: () => [],
    hasClearButton: false,
    maxChars: undefined,
  }
);

defineEmits<{
  "update:modelValue": [value: string];
}>();

const model = useVModel(props, "modelValue");

const focused = ref(false);
</script>

<style scoped>
textarea {
  margin: 0 !important;
}

textarea:focus {
  box-shadow: none;
}
</style>
