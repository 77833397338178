<template>
  <div class="flex flex-col gap-4">
    <LexmeaInput
      v-model="form.email"
      name="email"
      :error="errors?.email"
      :label="t('authentication.email')"
      type="email"
    />
    <LexmeaInput
      v-model="form.password"
      name="password"
      :error="errors?.password"
      :label="t('authentication.input.password')"
      :type="'password'"
      minlength="9"
    />
    <LexmeaInput
      v-model="form.password_confirmation"
      name="password_confirmation"
      :error="errors?.password_confirmation"
      :label="t('authentication.input.passwordRepeat')"
      :type="'password'"
      minlength="9"
    />
    <p class="-mt-1 text-xs">
      {{ t("authentication.input.passwordRegex") }}
    </p>
  </div>

  <div class="flex flex-col gap-2">
    <div class="flex gap-1 gap-x-3 md:gap-x-5">
      <img
        :src="dataProtectionIcon"
        :alt="t('cookies.data_protection_icon')"
        class="mt-2 h-[3.625rem] w-12 flex-shrink-0 p-1"
      />
      <div>
        <h3 class="mt-2 text-xs font-bold md:text-xs">
          {{ t("authentication.privacy.registerTitle") }}
        </h3>

        <ul class="mt-1 text-[9px] md:text-xs">
          <template
            v-for="content in tm('authentication.privacy.singularPoints')"
            :key="content"
          >
            <li>
              {{ rt(String(content)) }}
            </li>
          </template>
        </ul>
        <i18n-t
          keypath="authentication.privacy.discoverMore"
          tag="p"
          class="mb-2 mt-1 text-[9px] md:text-xs"
        >
          <template #privacyNotes>
            <a
              class="text-de font-bold text-lexmea-blue-400 underline"
              href="/datenschutzhinweis"
              target="_blank"
            >
              {{ t("authentication.privacy.privacyNotes") }}</a
            >
          </template>
        </i18n-t>
      </div>
    </div>
    <LexmeaCheckbox
      v-model="form.data_privacy_statement_agreed"
      name="data_privacy_statement"
      :error="errors.data_privacy_statement_agreed"
      small
    >
      <i18n-t
        keypath="authentication.input.acceptTerms"
        tag="p"
        class="mb-2 mt-1 text-[9px] md:text-xs"
      >
        <template #terms>
          <a
            class="font-bold text-lexmea-blue-400 underline"
            href="/agb"
            target="_blank"
          >
            {{ t("authentication.input.terms") }}</a
          >
        </template>
      </i18n-t>
    </LexmeaCheckbox>
    <LexmeaCheckbox
      v-model="form.newsletter_accepted"
      name="newsletter_accepted"
      :error="errors.newsletter_accepted"
      small
    >
      {{ t("authentication.input.contactMe") }}
    </LexmeaCheckbox>
  </div>
</template>

<script setup lang="ts">
import dataProtectionIcon from "~/assets/authentication/dataProtectionShield.svg";
import type { IRegisterForm } from "~/models/IUser";

const { tm, rt, t } = useI18n();

const props = defineProps<{
  modelValue: Partial<IRegisterForm>;
  errors: Partial<Record<keyof IRegisterForm, string>>;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: Partial<IRegisterForm>];
}>();

const form = useVModel(props, "modelValue", emit);
</script>
