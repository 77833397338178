export function useActiveTab() {
  const { data: tabs } = useFetchTabs();
  const tabRoute = useTabRoute();

  const activeTab = useArrayFind(tabs, ({ active }) => active);
  const activeTabIndex = useArrayFindIndex(tabs, ({ active }) => active);

  const activeTabUrl = computed(() => {
    if (activeTab.value === undefined) {
      return "/gesetz/gg/art-1";
    }
    return tabRoute(activeTab.value);
  });

  return {
    activeTab,
    activeTabIndex,
    activeTabUrl,
  };
}
