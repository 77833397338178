import type { DeskTab } from "~/models/ITab";

export const useLocalTabs = () => {
  const mounted = useMounted();
  const locals = useLocalStorage<DeskTab[]>("tabs", []);

  return computed({
    get: () => (mounted.value ? locals.value : []),
    set: (value) => (locals.value = value),
  });
};
