<template>
  <VDropdown
    v-if="!isSmallerThanSm && withPreview"
    :triggers="['hover']"
    :popperTriggers="['hover']"
    :disabled="!withPreview"
    :delay="{ show: 500, hide: 150 }"
  >
    <a ref="el" class="relative" :href="href" v-bind="$attrs">
      <slot />
    </a>
    <template #popper="{ hide }">
      <PreviewComponent
        v-if="legalTextParagraphId"
        :paragraphId="legalTextParagraphId"
        :linkType="linkType"
        :parentEl="el"
        @close="hide"
      />
    </template>
  </VDropdown>
  <a v-else ref="el" class="relative" :href="href" v-bind="$attrs">
    <slot />
  </a>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script lang="ts" setup>
import type { IBookmark } from "~/models/ILegalTextParagraph";
import type { ILinkType } from "~/models/ILinkType";
import type { IUserEntry } from "~/models/IUserEntry";

const { isSmallerThanSm } = useLexMeaTailwindBreakpoints();

const PreviewComponent = defineAsyncComponent(
  () => import("~/components/legaltextparagraph/paragraph/ParagraphPreview.vue")
);

type PrefetchOptions = {
  enabled: boolean;
  delay: number;
  immediate: boolean;
};

type Props = {
  legalText?: string;
  legalTextParagraph?: string;
  legalTextParagraphId?: number;
  linkType?: ILinkType;
  withPreview?: boolean;
  entry?: IUserEntry | IBookmark;
  prefetch?: Partial<PrefetchOptions>;
};

const defaultPrefetchOptions: PrefetchOptions = {
  enabled: true,
  delay: 0,
  immediate: false,
};

const props = withDefaults(defineProps<Props>(), {
  legalText: undefined,
  legalTextParagraph: undefined,
  legalTextParagraphId: undefined,
  linkType: undefined,
  entry: undefined,
  prefetch: () => ({ enabled: true, delay: 0, immediate: false }),
});

const { legalText, legalTextParagraph, entry } = toRefs(props);

const prefetch = computed(() => ({
  ...props.prefetch,
  ...defaultPrefetchOptions,
}));

const el = ref<HTMLElement>();

// expose el to parent, needed in SearchBar.vue to access the wrapper component for keyboard navigation
defineExpose({ el });

const { href } = useParagraphLink({
  legalTextSlug: legalText,
  paragraphSlug: legalTextParagraph,
  entry,
});

const isHovered = useElementHover(el, { delayEnter: 200, delayLeave: 600 });

usePrefetchParagraph(
  legalText,
  legalTextParagraph,
  logicAnd(
    () => prefetch.value.enabled,
    logicOr(() => prefetch.value.immediate, isHovered)
  ),
  prefetch.value.delay
);
</script>
