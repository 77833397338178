import { breakpointsTailwind } from "@vueuse/core";

export const useSplitpane = createSharedComposable(() => {
  const splitpaneElement = ref<HTMLElement>();
  const searchbarElement = ref<HTMLElement>();

  const paneSize = ref<number>(50);

  const { width: fullWidth } = useElementBounding(splitpaneElement);
  const { width: searchbarWidth } = useElementBounding(searchbarElement);

  const breakpoints = useBreakpoints(breakpointsTailwind);
  const betweenLgAndXl = breakpoints.between("lg", "xl");

  const baseSize = computed(() => {
    if (betweenLgAndXl.value) {
      return 50;
    }

    if (fullWidth.value === 0) {
      return 0;
    }
    const fraction = searchbarWidth.value / fullWidth.value;
    return Math.round(fraction * 100);
  });

  watchEffect(() => (paneSize.value = baseSize.value));

  // custom double click handler - only triggers the actual function when invoked twice within 300ms
  const makeSplitterClickHandler = () => {
    let clicks = 0;
    let timer: number | undefined;
    return () => {
      clicks++;
      if (clicks === 1) {
        timer = window.setTimeout(() => {
          clicks = 0;
        }, 300);
      } else {
        window.clearTimeout(timer);
        clicks = 0;
        paneSize.value = baseSize.value;
      }
    };
  };

  const inverseAbsoluteSize = computed(
    () => (1 - paneSize.value / 100) * fullWidth.value
  );

  return {
    searchbarElement,
    splitpaneElement,
    paneSize,
    inverseAbsoluteSize,
    fullWidth,
    handleSplitterClick: makeSplitterClickHandler(),
  };
});
