import type { IBookmark } from "~/models/ILegalTextParagraph";
import type { IUserEntry, ILink } from "~/models/IUserEntry";

export function useColorFromEntry() {
  const { user } = storeToRefs(useUserStore());

  // TODO: related to deletion of ILink from IUserEntry... maybe create a type containing IBookmark as well?
  const colorFromEntry = (
    entry: IUserEntry | IBookmark | (ILink & { curated?: boolean })
  ) => {
    switch (entry.type) {
      case "schema":
        if (user.value?.content_admin && entry.curated) {
          return entry.published_at ? "text-entry-blue" : "text-entry-red";
        }
        return entry.curated ? "text-schema-curated" : "text-schema-own";
      case "link":
        return "curated" in entry && entry.curated
          ? "text-lexmea-pop-darkest"
          : "text-lexmea-blue-400";
      case "note":
        return `text-entry-${entry.color}`;
      case "mark":
        return `text-entry-${entry.color}-${entry.color_type}`;
      case "bookmark":
        return `text-entry-${entry.color}-${entry.color_type}`;
      default:
        return "";
    }
  };

  return { colorFromEntry };
}
