export function useLanguageSwitch() {
  const { t, locale: localeRef } = useI18n({ useScope: "global" });

  const languagesWithStrings = computed(() => [
    {
      locale: "de",
      title: t("settings.languages.german"),
      icon: "emojione-flag-for-germany",
    },
    {
      locale: "en",
      title: t("settings.languages.english"),
      icon: "emojione-flag-for-united-kingdom",
    },
  ]);

  const localeCookie = useLanguageCookie();

  const setLocale = (locale: "de" | "en") => {
    localeRef.value = locale;
    localeCookie.value = locale;
  };

  return { languagesWithStrings, setLocale };
}
