export function usePageTransition() {
  const transitionStartHook = createEventHook();
  const transitionEndHook = createEventHook();

  // Only run this in the browser since during SSR we don't have to worry about this
  if (!import.meta.env.SSR) {
    const attrObserver = new MutationObserver((mutations) => {
      mutations.forEach((mu) => {
        if (mu.type !== "attributes" && mu.attributeName !== "class") return;

        // we know it's an Element because we're observing the body
        if ((mu.target as Element).classList.contains("transitioning")) {
          transitionStartHook.trigger();
        } else {
          transitionEndHook.trigger();
        }
      });
    });

    attrObserver.observe(document.body, {
      attributes: true,
      attributeFilter: ["class"],
    });
  }

  return { transitionStartHook, transitionEndHook };
}
