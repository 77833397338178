import colors from "~/../colors.json";
import type { EntryColor } from "~/models/IEntryColor";

export function useEntryColors() {
  const { t } = useI18n();
  const allColors = Object.keys(colors["entry"]).map((color) => {
    return {
      name: color as EntryColor,
      darkest: Object.values(colors["entry"][color].darkest).join(""),
      darker: Object.values(colors["entry"][color].darker).join(""),
      default: Object.values(colors["entry"][color].default).join(""),
      lighter: Object.values(colors["entry"][color].lighter).join(""),
      lightest: Object.values(colors["entry"][color].lightest).join(""),
      white: Object.values(colors["entry"][color].white).join(""),
    };
  });

  const noteTextFromColor = (color?: EntryColor) => {
    switch (color) {
      case "green":
        return t("legal_text.rightColumn.note_types.definition");
      case "blue":
        return t("legal_text.rightColumn.note_types.knowledge");
      default:
        return t("legal_text.rightColumn.note_types.note");
    }
  };

  return { allColors, noteTextFromColor };
}
