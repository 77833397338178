import { breakpointsTailwind } from "@vueuse/core";

const breakpoints = useBreakpoints({
  largeScreen: "1367px",
  ...breakpointsTailwind,
});
const desktopBreakpoint = "lg";
const tabletBreakpoint = "md";
const mobileBreakpoint = "sm";

export default function useLexMeaTailwindBreakpoints() {
  const mounted = useMounted();
  const makeBreakpoint = (breakpoint: any) =>
    logicOr(logicNot(mounted), breakpoints.smaller(breakpoint));

  return {
    isMediumScreen: breakpoints.between("lg", "largeScreen"),
    isSmallerThanSm: makeBreakpoint(mobileBreakpoint),
    isSmallerThanMd: makeBreakpoint(tabletBreakpoint),
    isSmallerThanLg: makeBreakpoint(desktopBreakpoint),
  };
}
