<template>
  <svg
    overflow="visible"
    viewBox="0 0 33 33"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    class="fill-current text-gray-600"
  >
    <path
      id="path7"
      d="M 14.371094 0 C 11.261238 0 8.7050781 2.5561595 8.7050781 5.6660156 C 8.7050781 8.2566875 10.478975 10.463945 12.869141 11.128906 L 12.845703 14 L 11.207031 14 A 1.666665 1.666665 0 0 0 9.5410156 15.666016 A 1.666665 1.666665 0 0 0 11.207031 17.333984 L 12.820312 17.333984 L 12.738281 27.642578 L 11.169922 27.408203 L 9.1054688 26.734375 L 7.28125 25.671875 L 5.71875 24.386719 L 5.6523438 24.255859 L 8.3066406 23.148438 L 0.6875 16.574219 L 0 26.613281 L 2.875 25.414062 L 3.3339844 26.308594 L 5.5585938 28.140625 L 7.8710938 29.486328 L 10.480469 30.339844 L 12.714844 30.671875 L 12.705078 31.857422 A 1.666665 1.666665 0 0 0 14.357422 33.537109 A 1.666665 1.666665 0 0 0 16.037109 31.884766 L 16.046875 30.625 L 16.210938 30.617188 L 19.125 29.894531 L 21.691406 28.628906 L 24.070312 26.892578 L 25 25.414062 L 27.953125 26.685547 L 27.380859 16.640625 L 19.6875 23.125 L 22.210938 24.212891 L 21.835938 24.810547 L 20.136719 26.050781 L 18.083984 27.0625 L 16.072266 27.560547 L 16.154297 17.333984 L 17.900391 17.333984 A 1.666665 1.666665 0 0 0 19.568359 15.666016 A 1.666665 1.666665 0 0 0 17.900391 14 L 16.181641 14 L 16.205078 11.025391 C 18.425167 10.255862 20.037109 8.1348571 20.037109 5.6660156 C 20.037109 2.5561595 17.48095 -5.9211895e-16 14.371094 0 z M 14.371094 3.3339844 C 15.679233 3.3339844 16.705078 4.3578761 16.705078 5.6660156 C 16.705078 6.9741551 15.679233 8 14.371094 8 C 13.062954 8 12.037109 6.9741551 12.037109 5.6660156 C 12.037109 4.3578761 13.062954 3.3339844 14.371094 3.3339844 z "
    />
  </svg>
</template>
