import { LexmeaApiClient } from "./apiClient";

export interface IssueReport {
  type: string;
  description: string;
  url: string;
  file?: File;
}

export class IssueReportClient extends LexmeaApiClient {
  postIssueReport = async (issueReport: IssueReport): Promise<void> => {
    await this.postForm("api/issueReport", issueReport);
  };
}
