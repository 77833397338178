export const imprint = {
  title: "Imprint",
  subtitle: "Our digital business card",
  company: {
    title: "Company",
    name: "LexMea GmbH",
    address: {
      co: "c/o Michael B. Strecker",
      street: "Leipziger Str. 44",
      city: "10117 Berlin",
      country: "Germany",
    },
    representatives: "Represented by:",
  },
  contact: {
    title: "Contact",
    email: "contact@lexmea.de",
  },
  registration: {
    title: "Court Registration",
    court: "Amtsgericht Berlin Charlottenburg",
    registerNumber:
      "Commercial Register Number (Handelsregister Nr.) HRB 238763 B",
  },
  taxId: {
    title: "VAT ID (USt-ID.)",
    id: "DE 35 15 76 540",
  },
  responsible: {
    title: "Responsible",
    law: "pursuant to § 18 II MStV",
    details:
      "Responsible for the content pursuant to § 18 Abs. 2 MStV: Michael B. Strecker, Leipziger Str. 44, 10117 Berlin",
  },
  disputeResolution: {
    title: "Dispute Resolution",
    info: "Information for consumers according to the VSBG and the Regulation (EU) 524/2013",
    platform:
      "The European Commission provides a platform for online dispute resolution, which you can find at {link}. LexMea is not obliged and not willing to participate in dispute resolution proceedings before a consumer arbitration board.",
  },
  disclaimer: {
    title: "Disclaimer for Content",
    content:
      "All content on LexMea has been created to the best of our knowledge, but no guarantee is given for its accuracy and completeness.",
  },
  copyright: {
    title: "Copyright",
    content:
      "The content provided on LexMea (especially examination schemes) is protected by copyright – also with regard to databases and similar facilities. Reproduction, distribution, exhibition, or public reproduction of the content is therefore only permitted with the written consent of LexMea, outside the possibilities of the Copyright Act.",
  },
};
