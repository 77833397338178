export function useSchemaSearch(query: Ref<string>) {
  const {
    refetch,
    data: searchSchemas,
    isLoading,
  } = useQuery({
    ...queries().entries.search(query)._ctx.schemas,
    enabled: false,
  });

  const data = computed(() => (query.value ? searchSchemas.value : undefined));

  debouncedWatch(query, (q) => !!q && refetch(), { debounce: 300 });

  return { data, isLoading };
}
