<template>
  <LexmeaSearch
    v-slot="{ option }"
    v-model:query="query"
    class="relative"
    :selectedItem="selectedCity"
    :displayValue="(item) => item.name"
    :label="'Stadt' + (optional ? ' (optional)' : '')"
    :options="cities"
    hoverLabel
    nullable
    @update:selectedItem="$emit('update:modelValue', $event?.id)"
  >
    {{ option.name }}
  </LexmeaSearch>
</template>

<script lang="ts" setup>
interface Props {
  modelValue?: number;
  optional?: boolean;
}

type Emits = {
  "update:modelValue": [value?: number];
};

const props = defineProps<Props>();

defineEmits<Emits>();

const { query, cities, selectedCity } = useCities(toRef(props, "modelValue"));
</script>
