<template>
  <label class="relative block">
    <LexmeaHoverLabel
      v-if="label"
      :active="anySelected"
      :focused="focused"
      :label="label"
    />
    <select
      v-model="model"
      v-bind="$attrs"
      class="w-full rounded border-none ring-1 ring-lexmea-gray-700 transition-all placeholder:text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-lexmea-pop-darker"
      @focus="focused = true"
      @blur="focused = false"
    >
      <option
        v-for="{ value, label: text } in options"
        :key="value"
        :value="value"
        :selected="value === model"
      >
        {{ text }}
      </option>
    </select>
  </label>
  <Transition name="scale">
    <span v-if="error" class="text-sm text-maroon-flush">
      {{ typeof error === "string" ? error : error[0] }}
    </span>
  </Transition>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script lang="ts" setup>
interface Option {
  value?: number;
  label: string;
}

const props = defineProps<{
  label: string;
  modelValue?: number;
  error?: string | string[];
  options: Option[];
  required?: boolean;
}>();

defineEmits<{
  "update:modelValue": [data: number];
}>();

const model = useVModel(props);

const focused = ref(false);

const anySelected = computed(() =>
  props.options.some(({ value }) => value === model.value)
);
</script>
