<template>
  <button
    v-ripple="{ color: rippleColor }"
    :disabled="disabled || loading"
    class="relative flex items-center justify-center whitespace-nowrap rounded-md text-sm font-semibold transition focus:outline-none focus:ring-2 focus:ring-lexmea-blue-400 focus:ring-opacity-50"
    :class="{
      'bg-lexmea-pop-darker text-white opacity-90 hover:opacity-100':
        props.design === 'primary',
      'text-lexmea-pop-darker hover:bg-lexmea-pop-darker/10':
        props.design === 'text',
      'bg-transparent text-lexmea-pop hover:text-lexmea-pop-lightest focus:!ring-0':
        props.design === 'link',
      'border-2 border-lexmea-pop-darker text-lexmea-pop-darker hover:border-lexmea-pop-lightest hover:text-lexmea-pop-lightest':
        props.design === 'outline',
      'px-8 py-3': props.size === 'md',
      'px-6 py-2': props.size === 'sm',
      'px-4 py-1': props.size === 'xs',
    }"
  >
    <i-line-md-loading-twotone-loop
      v-if="loading"
      class="absolute left-[50%] top-[50%] h-6 w-6 -translate-x-[50%] -translate-y-[50%]"
    />
    <span :class="{ invisible: loading }">
      <slot />
    </span>
  </button>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    loading?: boolean;
    design?: "primary" | "text" | "link" | "outline";
    size?: "xs" | "sm" | "md";
  }>(),
  {
    loading: false,
    design: "primary",
    size: "md",
  }
);

const { vRipple } = useVRipple();

const rippleColor = computed(() => {
  return ["outline", "link"].includes(props.design) ? "blue" : "white";
});
</script>

<style lang="postcss" scoped>
button[disabled] {
  @apply pointer-events-none text-gray-400;

  &:not(.bg-transparent) {
    @apply bg-lexmea-gray-500;
  }
}
</style>
