<template>
  <LexmeaSearch
    v-slot="{ option }"
    v-model:query="query"
    class="relative"
    :selectedItem="selectedUniversity"
    :displayValue="(item) => item.name"
    :options="universities"
    :label="
      $t('authentication.registration.additionalForm.university_optional')
    "
    hoverLabel
    nullable
    @update:selectedItem="$emit('update:modelValue', $event?.id)"
  >
    {{ option.name }}
  </LexmeaSearch>
</template>

<script lang="ts" setup>
interface Props {
  modelValue?: number;
}

type Emits = {
  "update:modelValue": [value: number];
};

const props = defineProps<Props>();

defineEmits<Emits>();

const { query, universities, selectedUniversity } = useUniversities(
  toRef(props, "modelValue")
);
</script>
