import type { IEmailForm, IUser, IUsernameForm } from "~/models/IUser";
import type { IVerifyToken } from "~/models/IVerifyToken";
import { LexmeaApiClient } from "./apiClient";

export class UserClient extends LexmeaApiClient {
  refreshUser = (): Promise<IUser> => {
    return this.get<IUser>("api/user");
  };

  login = async (form: any): Promise<void> => {
    await this.post("auth/login", form);
  };

  logout = async (): Promise<void> => {
    await this.post("auth/logout");
  };

  register = async (form: any): Promise<void> => {
    await this.post("auth/register", form);
  };

  validateEmail = (form: any): Promise<string> => {
    return this.post<string>("auth/email/verify", form);
  };

  resendEmailCode = async (form: { email: string }): Promise<void> => {
    await this.post("auth/email/verification-notification", form);
  };

  resetPassword = async (payload): Promise<void> => {
    await this.post("auth/reset-password/", payload);
  };

  verify = async ({ id, hash, expires, signature }: IVerifyToken) => {
    await this.get(`auth/email/verify/${id}/${hash}`, {
      searchParams: {
        expires,
        signature,
      },
    });
  };

  updateUser = async (user: Partial<IUser>): Promise<void> => {
    await this.put("auth/user/profile-information", user);
  };

  sendMail = async (email: string): Promise<void> => {
    await this.post("auth/email/verification-notification", { email });
  };

  forgotPassword = async (email: string): Promise<void> => {
    await this.post("auth/forgot-password", { email });
  };

  changeEmail = async (data: IEmailForm): Promise<void> => {
    await this.post("auth/user/change-email", data);
  };

  updateUsername = async (data: IUsernameForm): Promise<void> => {
    await this.post("auth/user/change-username", data);
  };

  updatePassword = async (payload): Promise<void> => {
    await this.put("auth/user/password", payload);
  };

  confirmPassword = (payload) => {
    return this.post("auth/user/confirm-password", payload);
  };

  deleteAccount = async (): Promise<void> => {
    await this.delete("auth/user/delete-account");
  };

  updateUserProfileImage = async (profile_image: File): Promise<void> => {
    await this.postForm("api/profile-image", { profile_image });
  };

  deleteUserProfileImage = async () => {
    await this.delete("api/profile-image");
  };
}
