import type { IPaginate } from "~/models/IPaginate";
import type {
  ChronikEntry,
  ColumnSort,
  UserContentCount,
  UserContentTabFoL,
} from "~/models/IBookshelf";
import { LexmeaApiClient } from "./apiClient";

export class BookshelfClient extends LexmeaApiClient {
  fetchUserContentCount = () => {
    return this.get<UserContentCount[]>("api/bookShelf/contentCount");
  };

  fetchEntries = (
    sorting: ColumnSort,
    type: string,
    deleted = false,
    page = 1
  ) => {
    return this.get<IPaginate<ChronikEntry>>("api/bookShelf/entries", {
      searchParams: {
        type: type,
        column: sorting.column,
        desc: sorting.desc ?? false,
        deleted,
        page,
      },
    });
  };

  fetchUserContentTree = (onOldParagraphs = false, onlyOwnSchemas = false) => {
    return this.get<UserContentTabFoL[]>("api/bookShelf/userContentTree", {
      searchParams: { onOldParagraphs, onlyOwnSchemas },
    });
  };
}
