// Hook `usePageContext()` to make `pageContext` available from any Vue component.
// See https://vike.dev/pageContext-anywhere

import type { PageContext } from "vike/types";
import type { App, InjectionKey } from "vue";

export const key: InjectionKey<PageContext> = Symbol();

export function usePageContext() {
  const pageContext = inject(key);
  if (!pageContext) {
    console.trace();
    throw new Error("Page Context is undefined on app instance");
  }

  return pageContext;
}

export function setPageContext(app: App, pageContext: PageContext) {
  app.provide(key, pageContext);
}
