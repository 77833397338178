<template>
  <div
    class="slide-animation h-1 w-full bg-lexmea-selection"
    :class="{ hidden }"
  ></div>
</template>

<script lang="ts" setup>
type Props = {
  hidden?: boolean;
};

withDefaults(defineProps<Props>(), {
  hidden: false,
});
</script>

<style scoped>
.slide-animation {
  animation: progress 2s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes progress {
  0% {
    transform: translateX(0) scaleX(0);
  }

  40% {
    transform: translateX(0) scaleX(0.4);
  }

  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
</style>
