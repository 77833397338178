import { OccupationClient } from "~/api/occupation";
import type { IOccupation } from "~/models/IOccupation";

export function useOccupations() {
  const occupations = ref<IOccupation[]>([]);
  const api = useApiClient(OccupationClient);

  const flattenedOccupations = computed(() =>
    occupations.value.flatMap(({ children, ...occ }) => [occ, ...children])
  );

  const fetchOccupations = async () => {
    occupations.value = await api.fetchOccupations();
  };

  tryOnMounted(fetchOccupations);

  return {
    occupations,
    flattenedOccupations,
  };
}
