// de -> transform to Datenschutz
export const data_protection = {
  title: "Datenschutzhinweise",
  subtitle: "DS-GVO und mehr!",
  intro_container: {
    title: "Das Wichtigste in Kürze",
    first_paragraph:
      "Auch wenn du es nicht sehen kannst: Bei LexMea gehört die {trustworthy_policy}, die bei deiner Nutzung unserer Services entstehen, genauso dazu wie ein hochwertiges Online-Angebot selbst.",
    trustworthy_policy:
      "sichere, vertrauens- und respektvolle Verarbeitung der Daten",
    second_paragraph:
      "Um die Sicherheit deiner Daten angemessen bei der Übertragung zu schützen, verwenden wir orientiert am {current_technology} (z.B. DDoS-Schutz, SQL Injection-Protection, Anti-CSRF-Token…).",
    current_technology:
      "aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z.B. SSL/TLS) und gesicherte technische Systeme",
    third_paragraph:
      "Um den Schutz deiner personenbezogenen Daten zu garantieren, gehen wir zudem an zahlreichen Stellen über {high_requirements}. Du findest diese Stellen durch unser LexMea-Datenschutzschild gekennzeichnet. Hier ein Überblick:",
    high_requirements:
      "den bereits hohen europäischen Datenschutzstandard der DS-GVO hinaus",
    firstSectionItems: [
      {
        title: "Volle Kontrolle",
        text: "Habe jederzeit volle Kontrolle über deine Daten durch unser {emphasized}, in dem du deine Einwilligungen verwalten kannst und über zentral zusammengestellte Links zur Geltendmachung deiner Rechte kommst.",
        emphasized: "Datenschutz-Cockpit",
      },
      {
        title: "Kein Verkauf deiner Daten",
        text: "Wir {emphasized} Wenn wir dir Werbung anzeigen, erhalten unsere Werbepartner dabei keine Informationen über dich, sondern wir kuratieren selbst (z.B. anhand des geöffneten Gesetzbuchs) auf unseren eigenen, europäischen Servern.",
        emphasized: "verkaufen deine Daten nie.",
      },
      {
        title: "Nur europäische Server",
        text: `Die von dir eingegebenen Daten werden von uns, auch wenn dies mit Mehrkosten für
uns verbunden ist, auf europäischen Servern. Auch bei der Auswahl unserer
Integrationen (etwa für IT-Sicherheit oder Analyse) haben wir uns ausschließlich für
europäische Serverstandorte entschieden.`,
      },
      {
        title: "Kein Google Analytics oder Facebook/Meta-Pixel",
        text: "Wir verzichten auf die Nutzung großer US-Dienste wie Google Analytics oder des Facebook/Meta-Pixels.",
      },
      {
        title: "Mehr Rechte",
        text: "Wir {emphasized} bei der Geltendmachung deiner Rechte (z.B. Widerspruchsrecht) und achten deine Entscheidung in jedem Fall, ohne rechtliche Schlupflöcher zu nutzen.",
        emphasized: "verzichten auf bürokratische Begründungspflichten",
      },
    ],
  },
  content: {
    section_0: {
      title: "Datenschutzhinweise für lexmea.de",
      content: `Diese Datenschutzhinweise dienen der Information der Nutzer über Art,
      Umfang und Zwecke der Erhebung und Verwendung personenbezogener Daten
      im Rahmen der Nutzung von lexmea.de.`,
    },
    section_1: [
      {
        title: "1. Name und Kontaktdaten des Verantwortlichen",
        paragraphs: [
          "LexMea GmbH",
          "Postanschrift: Leipziger Str. 44, c/o Michael B. Strecker, 10117 Berlin",
          "E-Mail:",
        ],
      },
    ],
    section_2: {
      title: "2. Kontakt des Datenschutzbeauftragten",
    },
    section_3: {
      title:
        "3. Welche Rechte dir zustehen und wie du diese einfach geltend machst",
      content:
        "Jeder betroffenen Person stehen die nachfolgenden Rechte zu, die jederzeit formlos – z.B. durch Senden einer E-Mail an {link} – geltend gemacht werden können:",
      subsections: [
        {
          title:
            "a) Recht auf Widerruf der Einwilligung (siehe Art. 7 III DS-GVO)",
          content: `Jede betroffene Person hat das Recht, eine erteilte Einwilligung
        jederzeit zu widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der
        Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird,
        wenn die Datenverarbeitung auf einer Einwilligung nach Art. 6 II a)
        oder Art. 9 II a) DS-GVO beruht.`,
          //   notice: `Wir werden uns in diesem Fall nicht auf die Möglichkeit berufen,
          // andere Rechtsgrundlagen des Art. 6 I DS-GVO für die Verarbeitung
          // personenbezogener Daten heranzuziehen, sondern die Daten
          // unverzüglich löschen.`,
        },
        {
          title:
            "b) Recht auf Auskunft über die eigenen Daten und Erhalt einer Kopie (Art. 15 DS GVO)",
          content: `Jede betroffene Person hat das Recht auf Auskunft, insb. über sie
        betreffende personenbezogene Daten selbst, die Verarbeitungszwecke und
        die Empfänger, gegenüber denen ihre personenbezogenen Daten
        offengelegt werden. Dazu gehört auch das Recht auf den Erhalt
        einer Kopie ihrer personenbezogenen Daten.`,
        },
        {
          title: "c) Recht auf Berichtigung (Art. 16 DS-GVO)",
          content: `Jede betroffene Person hat das Recht auf Berichtigung sie betreffender
        unrichtiger personenbezogener Daten und Vervollständigung
        unvollständiger personenbezogener Daten.`,
        },
        {
          title: `d) Recht auf Löschung und auf „Vergessenwerden“ (Art. 17 DS-GVO)`,
          content: `Jede betroffene Person hat das Recht, dass sie betreffende
        personenbezogene Daten unverzüglich gelöscht werden.`,
        },
        {
          title: "e) Recht auf Einschränkung der Verarbeitung (Art. 18 DS-GVO)",
          content: `Jede betroffene Person hat das Recht unter den Voraussetzungen des
        Art. 18 DS-GVO die Einschränkung der Verarbeitung der sie betreffenden
        personenbezogenen Daten zu verlangen.`,
        },
        {
          title: "f) Recht auf Datenübertragbarkeit (Art. 20 DS-GVO)",
          content: `Jede betroffene Person hat unter den Voraussetzungen des Art. 20
        DS-GVO das Recht auf Datenübertragbarkeit.`,
        },
        {
          title: "g) Widerspruchsrecht gegen die Verarbeitung (Art. 21 DS-GVO)",
          content: `Jede betroffene Person hat das Recht, Widerspruch gegen die
        Verarbeitung sie betreffender personenbezogener Daten inkl.
        Direktwerbung und Profiling einzulegen.`,
          notice: `Wir verarbeiten die personenbezogenen Daten dann in jedem Fall nicht mehr und
verlangen keine weiteren Voraussetzungen wie z.B. die Geltendmachung einer
„besonderen Situation“ des Betroffenen.`,
        },
        {
          title:
            "h) Recht auf Beschwerde bei einer Aufsichtsbehörde (Art. 77 DS-GVO)",
          content: `Jede betroffene Person hat unbeschadet eines anderweitigen
        verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf
        Beschwerde bei einer Aufsichtsbehörde. Die für LexMea zuständige
        Aufsichtsbehörde ist die:`,
        },
        {
          title:
            "Berliner Beauftragte für Datenschutz und Informationsfreiheit",
          content: [
            "Friedrichstr. 219, 10969 Berlin,",
            "Besuchereingang: Puttkamerstr. 16 – 18 (5. Etage)",
            "Telefon: +49 30 13889-0, Telefax: +49 30 2155050,",
            "E-Mail: {link}",
          ],
        },
      ],
    },
    section_4: {
      title:
        "4. Welche personenbezogenen Daten wir zu welchen Zwecken auf welcher Rechtsgrundlage verarbeiten",
      subsections: [
        {
          title: "a) Personalisierung deines Gesetzbuchs",
          content: [
            {
              title:
                "aa) Personalisierung deines Gesetzbuchs – Nicht eingeloggt",
              content: `Bei der Nutzung von LexMea personalisierst du dein Online-Gesetzbuch selbst - etwa über das Anlegen von Notizen, Verweisen, Markierungen etc. – und verwaltest dein Wissen. Um dir ohne jegliche Eintrittsbarrieren eine möglichst umfangreiche Nutzung unseres Services zu ermöglichen, kannst du solche Personalisierungen bereits ohne Registrierung vornehmen. Diese werden dann nicht auf unseren Servern, sondern lediglich im lokalen Speicher deines Browsers (local storage) gespeichert. Erst wenn du dich registrierst oder mit einem bereits vorhandenen Account einloggst, werden diese Informationen an uns übertragen und in deinen Account geladen. Die Rechtsgrundlage für diese Verarbeitung ist Art. 6 I a) DS GVO (Erfüllung eines Vertrags) i.V.m. § 25 II Nr. 2 TDDDG.`,
            },
            {
              title: "bb) Personalisierung deines Gesetzbuchs - Eingeloggt",
              content: [
                "Wenn du eingeloggt bist, werden deine Profildaten (insb. E-Mail-Adresse, Nutzername und bei gebuchten Zusatzfunktionen auch Zahlungsdaten), die Daten, die du eingibst, wenn du unser Produkt nutzt (z.B. Notizen) und die Metadaten (wie z.B. das Datum, an dem eine Notiz erstellt wurde - um in der Bibliothek danach sortieren zu können) von uns sicher auf europäischen Servern gespeichert.",
                "Anderen Nutzern werden deine Inhalte und Personalisierungen grundsätzlich nicht angezeigt, außer du nutzt aktiv die Inhalte-Teilen-Funktion. Du kannst dabei individuell verwalten, welche Inhalte für welche Personen freigegeben werden sollen. Die Rechtsgrundlage für diese Verarbeitung ist Art. 6 I a) DS GVO (Erfüllung eines Vertrags).",
              ],
            },
          ],
        },
        {
          title: "b) Nutzung und angezeigte Inhalte",
          content:
            "Wir erfassen, wie du unsere Services nutzt, z.B. welche Normen du dir ansiehst oder bearbeitest, um einerseits, Markt- und Meinungsforschung zu betreiben und andererseits, um unsere Dienste und die Inhalte, die wir dir anzeigen, an deinen Interessen auszurichten und dich somit von uninteressanten Angeboten zu verschonen.",
          notice:
            "Wenn dir personalisierte Werbung wie insb. juristische Stellenanzeigen angezeigt werden, erhalten unsere Werbepartner dabei keine Informationen über dich, sondern wir kuratieren selbst (z.B. anhand des geöffneten Gesetzbuchs) auf unseren eigenen, europäischen Servern.",
        },
        {
          title:
            "c) Bereitstellung und Verbesserung unseres Services sowie Schutz vor Angriffen",
          content: [
            {
              title:
                "aa) Keine Tracking- und Werbe-Cookies, keine Third-Party-Cookies",
              content:
                "Viele Webseiten speichern Cookies von Drittunternehmen (sog. Third-Party-Cookies) oder Tracking- und Werbecookies auf deinem Endgerät, insb. um das Nutzerverhalten auszuwerten oder Werbung anzuzeigen – wir nicht!",
              notice: `Wir nutzen kein Google-Analytics, kein Facebook/Meta-Pixel und verzichten auch
darüber hinaus vollständig auf das Setzen von Tracking- und Werbe-Cookies sowie
Third-Party-Cookies auf deinem Gerät, um ein besonders hohes Datenschutzniveau zu
gewährleisten.`,
            },
            {
              title: "bb) Notwendige Cookies",
              content:
                "Wir verwenden ausschließlich Cookies, die für die Funktion unserer Seite (notwendige Cookies) oder für Funktionen, die von dir ausdrücklich angefordert werden (funktionale Cookies) zwingend nötig sind. Dazu gehören z.B.:",
              list: [
                "Gewährleistung der Informationssicherheit (Anti-CSRF-Token)",
                "Anmeldung auf LexMea mit deinem persönlichen Account und Authentifizierung gegenüber unseren Servern zum Abruf deiner persönlichen Inhalte",
              ],
              sublist: [
                "Wenn du dich anmeldest, wird ein Cookie gespeichert, der dafür sorgt, dass du auch in den kommenden Tagen eingeloggt bleibst (Session-Cookie), ohne deine Daten jedes Mal erneut eingeben zu müssen.",
                "Wenn du beim Anmelden optional auf „Einloggt bleiben“ klickst, wird ein zweiter Cookie gespeichert, der dafür sorgt, dass du in den folgenden Monaten eingeloggt bleibst (Remember-Cookie).",
              ],
            },
            {
              title: "cc) Website-Verbesserung ohne Google-Analytics",
              content: `Um unsere Services für dich stetig nutzerfreundlicher und effektiver gestalten zu können, nutzen wir das Open-Source-Analyse-Tool PostHog. Die dabei erhobenen Daten werden auf europäischen Servern gehostet und nicht in die USA übermittelt. Personenbezogene Nutzerdaten werden so nur intern zur Verbesserung unserer Feature verwendet und nie an Werbepartner weitergegeben, geschweige denn verkauft." Wenn dir personalisierte Werbung wie insb. juristische Stellenanzeigen angezeigt werden, erhalten unsere Werbepartner dabei keine Informationen über dich, sondern wir kuratieren selbst (z.B. anhand des geöffneten Gesetzbuchs) auf unseren eigenen, europäischen Servern. Die Rechtsgrundlagen für diese Verarbeitungen sind Art. 6 I a) DS GVO (Erfüllung eines Vertrags) und Art. 6 I f) DS-GVO (berechtigte Interessen).`,
            },
            {
              title: "dd) Log-Files",
              content: `Dein Browser übermittelt beim Besuch von LexMea – ebenso wie beim Besuch jeder anderen Webseite – stets automatisch Informationen, die in einer Protokolldatei (sog. Log-File) gespeichert werden. Zu diesen Informationen zählen insb.: Verwendeter Webbrowser; Betriebssystem; die zuvor besuchte Seite (Referrer); bei Besuchern, die über eine Suchmaschine kommen, die Suchbegriffe; Datum, Uhrzeit, Zeitzone; Inhalt und Umfang der Anforderungen und übertragenen Datenmengen sowie die IP-Adresse. Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet: Gewährleistung eines reibungslosen Verbindungsaufbaus der Website; Gewährleistung des korrekten Anzeigens unserer Inhalte; Gewährleistung der Sicherheit unseres Services (z.B. Schutz vor Hackerangriffen) und Auswertung der Systemsicherheit und -stabilität; Gewährleistung einer komfortablen Nutzung unserer Website. Die IP-Adresse wird aus Datenschutzgründen durch uns um die letzten drei Ziffern gekürzt. Uns ist somit die Herstellung eines Bezuges zu einer bestimmten oder bestimmbaren natürlichen Person nicht mehr oder nur mit einem unverhältnismäßig großen Aufwand an Zeit, Kosten und Arbeitskraft möglich (sog. Anonymisierung). Sofern dabei dennoch personenbezogene Daten anfallen, ist die Rechtsgrundlage für diese Verarbeitung Art. 6 I f) DS GVO (Berechtigte Interessen des Anbieters).`,
            },
          ],
        },
        {
          title: "d) E-Mail-Benachrichtigungen",
          content: [
            {
              content:
                "Ohne deine Einwilligung senden wir dir keine Mails zu Neuigkeiten (Newsletter).",
              // notice:
              //   "Liegt uns keine Einwilligung vor, machen wir auch nicht von der nach Art. 6 I f) DS-GVO i.V.m. § 7 III UWG bestehenden Möglichkeit der Zusendung von Werbemails („Opt-out“) Gebrauch.",
            },
            {
              content: [
                "Wenn du uns im Rahmen des Registrierungsprozesses oder in deinem Datenschutz-Cockpit die Einwilligung erteilt hast, senden wir dir lediglich bei wirklich relevanten Neuigkeiten eine Mail – etwa bei neuen Funktionen, besonderen Events, Inhalten oder Praktika-/Stellenangeboten in deiner Region oder zu deinem Rechtsgebiet.",
                "Die meisten Firmen nutzen für den Versand von Newslettern US-amerikanische Dienste wie MailChimp, die die E-Mail-Adressen sowie Daten zur Interaktion mit versendeten Mails (z.B. Zeitpunkt des Abrufs, IP-Adresse, Browsertyp und Betriebssystem) an deren Server in den USA übertragen – wir nicht!",
              ],
              notice:
                "Wir nutzen allenfalls europäische Alternativen wie Brevo (ehem. Sendinblue) für den Versand von E-Mails.",
            },
            {
              content: [
                "Für den Versand, die Organisation und Analyse (z.B. ob ein Newsletter geöffnet wurde und welche Links anschließend angeklickt wurden) unserer Newsletter nutzen wir derzeit den europäischen Anbieter Brevo (in Deutschland betrieben durch die Sendinblue GmbH, Köpenicker Straße 126, 10179 Berlin, Deutschland). Die im Rahmen dessen entstehenden Daten werden von Brevo ausschließlich auf europäischen Servern gespeichert. Wir haben mit Brevo einen Vertrag über die Auftragsdatenverarbeitung abgeschlossen, in dem wir Brevo verpflichten, die Daten unserer Kunden zu schützen und sie nicht an nicht an Dritte weiterzugeben.",
                "Rechtsgrundlage hierfür ist Art. 6 I a) DS GVO (Einwilligung). Die Einwilligung hierfür kannst du jederzeit widerrufen, z.B. einfach über das Klicken auf den entsprechenden Link am Ende einer jeden Mail oder über dein Datenschutz Cockpit in den Konto Einstellungen. Alternativ kannst du deinen Abmeldewunsch auch jederzeit per Mail an {privacy_mail}.",
              ],
            },
          ],
        },
        {
          title:
            "e) Keine Verbindung zu Sozialen Medien (Instagram, Facebook etc.)",
          content: [
            "Viele Betreiber bauen auf ihren Seiten Social-Media-Plug-Ins ein. Diese ermöglichen es z.B. Inhalte direkt auf Social-Media zu teilen, ohne die Seite verlassen zu müssen. Solche Plug-Ins geben jedoch personenbezogene Daten über die Besucher der jeweiligen Seite an die Social-Media-Betreiber weiter und das unabhängig davon, ob diese dort einen Account besitzen oder nicht.",
            "Wir setzen keine Social-Media-Plug-Ins ein, die Daten an Dritte übermitteln.",
          ],
          notice:
            "Wir verzichten bewusst auf solche Social-Media-Plug-Ins, da wir der Auffassung sind, dass diese nicht denselben Schutz deiner Daten gewährleisten wie wir (vgl. die EuGH-Urteile zum „Safe-Harbour-Abkommen“ und dem „EU-US Privacy Shield“).",
        },
        {
          title: "f) Daten aus erhaltenen Bewerbungen",
          content: [
            "Wir freuen uns sehr über Bewerbungen für Jobs, Praktika oder das juristische Referendariat bei LexMea. Erhaltene Bewerbungen werden ausschließlich für Auswahlverfahren verarbeitet. Wenn wir dich in unserem Team begrüßen dürfen speichern wir deine Daten in deiner Mitarbeiterakte mindestens bis zur Beendigung der Zusammenarbeit.",
            "Kommt eine Zusammenarbeit zum intendierten Zeitpunkt nicht zustande, bewahren wir deine Daten lediglich auf, wenn du einwilligst (Art. 6 I a) DS-GVO) oder wenn wir ein berechtigtes Interesse daran haben (etwa um dich bei wichtigen Nachfragen erreichen zu können oder für rechtliche Beweiszwecke). Deine Einwilligung kannst du jederzeit widerrufen. Auch bei erteilter Einwilligung löschen wir die Daten spätestens 3 Jahre danach.",
            "Die Rechtsgrundlage für diese Verarbeitung ist Art. 6 I f) DS-GVO (Berechtigte Interessen des Anbieters), Art. 6 I b) DS-GVO (Vertrag bzw. vorvertragliche Maßnahmen), Art. 6 I a) DS-GVO (Einwilligung) und Art. 9 II a) DS-GVO (Verarbeitung besonderer Kategorien).",
            "Für den Fall des Schutzes lebenswichtiger Interessen erfolgt die Datenverarbeitung gemäß Art. 9 II c) DS-GVO. Für Zwecke der Gesundheitsversorgung, der Arbeitsmedizin, für die medizinische Diagnostik, für die Versorgung oder Behandlung im Gesundheits- oder Sozialbereich oder für die Verwaltung von Systemen und Diensten im Gesundheits- oder Sozialbereich erfolgt die Verarbeitung personenbezogener Daten gemäß Art. 9 II h) DS-GVO. Wenn Sie freiwillig Daten der besonderen Kategorien mitteilen, erfolgt die Verarbeitung auf Grundlage von Art. 9 II a) DS-GVO.",
          ],
        },
      ],
    },
    section_5: {
      title: "5. Empfänger der personenbezogenen Daten",
      subsections: [
        {
          title: "a) IT-Dienstleister",
          content:
            "Unser Webhosting-Anbieter Hetzner Online stellt Server in Nürnberg bereit und ist damit im Rahmen der von uns abgeschlossenen Verträge zur Auftragsverarbeitung Empfänger deiner personenbezogenen Daten.",
        },
        {
          title: "b) Zahlungsdiensleister",
          content:
            "Wenn du eine kostenpflichtige Diensterweiterung buchst und im Bezahlvorgang einen externen Zahlungsdienstleister auswählst, werden deine Daten an diesen übertragen. Rechtsgrundlage für diese Verarbeitung ist Art. 6 I a) DS GVO (Erfüllung eines Vertrags).",
        },
        {
          title: "c) Keine Weitergabe an Werbepartner",
        },
      ],
      notice:
        "Viele Anbieter finanzieren ihre Produkte über den Verkauf von personenbezogenen Daten. Wir von LexMea sind der Auffassung, dass sich ein hochwertiges, datenschutzfreundliches Produkt auch auf andere Art und Weise bereitstellen lässt. Deine personenbezogenen Daten werden daher niemals für Werbezwecke an Dritte weitergegeben.",
    },
    section_6: {
      title: "6. Übermittlung personenbezogener Daten in ein Drittland",
      notice: [
        "Viele Anbieter flüchten aus steuerlichen Gründen mit ihrem Hauptsitz in Drittstaaten. Wir als LexMea sind ein in Berlin ansässiges Start-up, das sich bewusst europäischen Rechtsnormen wie der Pflicht zur Gewährleistung eines hohen Datenschutzes sowie der Pflicht zur Zahlung fairer Steuern unterwirft.",
        "Wir hosten unsere Daten daher trotz der damit verbundenen Mehrkosten bewusst auf europäischen Servern und nutzen lediglich Integrationen mit europäischen Serverstandorten.",
      ],
      content:
        "Deine personenbezogenen Daten werden daher nicht in Drittländer außerhalb der EU übermittelt, außer du schließt einen zahlungspflichtigen Zusatzdienst ab und wählst freiwillig und informiert – unter anderen möglichen – einen außerhalb der EU ansässigen Zahlungsdiensteanbieter (wie z.B. PayPal, Mastercard oder Visa). Mehr als die zur Ausführung dieser Services zwingend nötigen Daten werden auch in diesem Fall nicht übermittelt.",
    },
    section_7: {
      title: "7. Dauer der Speicherung und Löschung personenbezogener Daten",
      content: [
        "Wir speichern Daten lediglich so lange, bis sie nicht mehr benötigt werden, um unsere Dienste bereitzustellen, oder bis dein Konto gelöscht wird, je nachdem, was zuerst eintritt. Dies ist eine Einzelfallbestimmung und hängt von Dingen ab wie der Art der Daten, warum sie erfasst und verarbeitet werden sowie den relevanten rechtlichen oder betrieblichen Speicherbedürfnissen. Zu letzterem zählen insbesondere die Aufbewahrungs- und Dokumentationspflichten aus dem Handelsgesetzbuch (HGB) und der Abgabenordnung (AO).",
        "Wenn du dein Konto in deinen Profil-Einstellungen löschst, schicken wir dir eine Mail mit der Bestätigung des Löschvorgangs. Damit kannst du dein Konto innerhalb von 30 Tagen reaktivieren und den Löschvorgang abbrechen. Nach 30 Tagen beginnt der Löschvorgang und du kannst deine hinzugefügten Inhalte, Personalisierungen oder sonstigen Daten dann nicht mehr zurückerlangen. Lediglich soweit dies zur Erhaltung von Beweismitteln bezüglich der Bereitstellung etwaiger kostenpflichtiger Dienste unseres Angebotes erforderlich ist, speichern wir für die Dauer der gesetzlichen Verjährungsfristen die Metadaten (jedoch keine Inhalte).",
      ],
    },
    section_8: {
      title: "8. Änderung der Datenschutzhinweise",
      content:
        "Es kann künftig nötig werden, den Inhalt dieser Datenschutzhinweise zu ändern. So z.B., wenn sich die gesetzlichen Bestimmungen ändern oder wenn LexMea neue Funktionen einführt und deine Daten daher zu anderen Zwecken als ursprünglich angegeben verarbeitet. Wir behalten uns daher eine Änderung dieser Datenschutzhinweise vor. Wir werden die Datenschutzhinweise auf unserer Website stets aktuell halten.",
    },
  },
};
