// de
export const terms_and_conditions = {
  title: "AGB",
  subtitle: "Allgemeine Geschäftsbedingungen",
  data_privacy: "Datenschutzhinweise",
  intro_container: {
    title: "Das Wichtigste in Kürze",
    first_paragraph:
      "Durch die Registrierung kommt ein Vertrag über die Bereitstellung digitaler Dienstleistungen zustande. Dem Nutzer entstehen hierdurch {no_cost}.",
    no_cost: "keine Kosten",
    second_paragraph:
      "Dies ist möglich, indem wir juristische Stellenanzeigen (etwa von Kanzleien), Veranstaltungshinweise (etwa von Repetitorien) sowie Werbeanzeigen (etwa zu Lehrbüchern u. dergl.) auf unserer Website platzieren. Wenn wir diese Werbung individualisieren geben wir {no_data_to_advertisers} weiter, sondern nehmen die Personalisierung selbst auf unseren eigenen, europäischen Servern vor. So zeigen wir beispielsweise Nutzern, die § 433 BGB öffnen, Lehrbücher zum Kaufrecht an, ohne dass unsere Werbepartner wissen, wer § 433 BGB öffnet.",
    no_data_to_advertisers: "keine Daten an Werbepartner",
    third_paragraph:
      "Für die Nutzung von nach Markteinführung veröffentlichten Zusatzfunktionen können Premium-Abonnements abgeschlossen werden. Die bei Markteinführung angebotenen Funktionen werden jedoch auch {stay_free}.",
    stay_free: "in Zukunft stets kostenfrei bleiben",
  },
  content: {
    section_intro: [
      {
        title: "Allgemeine Geschäftsbedingungen",
        paragraphs: [
          "Die LexMea GmbH, Leipziger Str. 44, 10117 Berlin (im Nachfolgenden „LexMea“ oder „wir“) betreibt unter der Bezeichnung „LexMea“ unter lexmea.de eine Web-Application.",
          "Für Geschäftsbeziehungen zwischen LexMea und dem / der Nutzer*in (im Nachfolgenden „Nutzer“) gelten diese Allgemeinen Geschäftsbedingungen in ihrer aktuellen Fassung zum Zeitpunkt des Vertragsschlusses.",
        ],
      },
    ],
    section_1: [
      {
        title: "§ 1 Vertragsschluss",
        paragraphs: [
          "Ein Nutzungsvertrag zwischen dem Nutzer und LexMea kommt durch die Bestätigung der Registrierung eines Nutzerkontos zustande. Die Bereitstellung der Datenbank sowie ihrer Inhalte durch LexMea stellt kein bindendes Angebot dar. Dem Kunden wird dadurch lediglich die Möglichkeit gegeben seinerseits ein verbindliches Angebot über den Abschluss eines Nutzungsvertrages abzugeben.",
          "Die Nutzung des Angebots steht dem Nutzer nach Bestätigung seiner Anmeldedaten über einen Verifizierungslink zur Verfügung. Nach Ablauf des in der Verifizierungsaufforderung angegebenen Zeitraums für die Aktivierung des Nutzerkontos, werden die Daten des Nutzers gelöscht und es ist eine erneute Registrierung nötig.",
        ],
      },
    ],
    section_2: [
      {
        title: "§ 2 Nutzungsumfang und Änderungen",
        paragraphs: [
          "LexMea stellt dem Nutzer im Rahmen des Nutzungsvertrages kostenlos zahlreiche Funktionen (im Nachfolgenden: „digitale Dienste“) zur Verfügung:",
        ],
        list: [
          "Zugang zu allen Standardfunktionen der Datenbank,",
          "Nutzung aller Standard-Personalisierungsfunktionen (Unterstreichungen, Markierungen, Verweise, eigene Schemata und Notizen)",
          "Zugang zu allen Standard-LexMea-Schemata",
          "Zugang zu Informationsangeboten und Anzeigen (Stellenangebote, Veranstaltungshinweise, Lehrbücher u.ä.).",
        ],
        paragraphs_after_list: [
          "LexMea passt sein Angebot kontinuierlich an aktuelle Anforderungen an. Hierfür werden periodisch redaktionelle Inhalte aktualisiert und technische Änderungen vorgenommen, um die Aufrechterhaltung der Nutzerfreundlichkeit und Bedienbarkeit von LexMea und die Anpassung an den aktuellen Stand der Technik zu gewährleisten (vereinbarte Aktualisierungen).",
          "Darüberhinausgehende Änderungen an diesen digitalen Diensten (Upgrades) erfolgen lediglich aus triftigem Grund, etwa zur Anpassung des Produkts an Nutzerfeedback, an eine neue technische Umgebung oder an erhöhte Nutzerzahlen sowie sonstige wichtige betriebstechnische Gründe und unter Information der Nutzer, ohne dass für solche Änderungen zusätzliche Kosten entstehen.",
          "Der Nutzungsvertrag für die Nutzung dieser digitalen Dienste wird unbefristet geschlossen und kann jederzeit ohne Frist von beiden Seiten gekündigt werden. Wurde eine kostenpflichtige Diensterweiterung abgeschlossen, so muss diese zuvor nach § 11 beendigt werden. Das Recht zur fristlosen Kündigung aus wichtigem Grund bleibt hiervon unberührt. Ein wichtiger Grund liegt für LexMea insbesondere vor, wenn der Nutzer schwerwiegend oder trotz Abmahnung durch LexMea wiederholt gegen diese Allgemeinen Geschäftsbedingungen oder sonstige vertragliche Verpflichtungen verstoßen hat (insb. wenn ein schwerwiegender oder wiederholter Missbrauchsfall gegen die Mitwirkungs- und Sicherheitspflichten vorliegt).",
          "Der Nutzer kündigt den digitalen Dienst durch die Löschung des Nutzerkontos in den Nutzerkontoeinstellungen.",
          "Mit der Kündigung besteht keine Zugriffsmöglichkeit auf das Konto und die dort gespeicherten Daten mehr. Ausgenommen hiervon sind Daten, zu deren Aufbewahrung LexMea gesetzlich verpflichtet ist oder an deren Aufbewahrung ein berechtigtes Interesse besteht, z.B. um nach einer berechtigten Sperrung ein erneutes Anmelden zu verhindern.",
        ],
      },
    ],
    section_3: [
      {
        title: "§ 3 Diensterweiterungen",
        paragraphs: [
          "LexMea erweitert seinen Dienstumfang kontinuierlich und führt neue Funktionen (Diensterweiterungen) ein. LexMea behält sich daher vor vertraglich nicht geschuldete Diensterweiterungen bereitzustellen, zu testen und wieder zu entfernen ohne die Nutzer gesondert und individuell hierüber zu informieren, solange dies keine Auswirkungen auf die Rechte oder Pflichten der Nutzer hat und nicht die unter § 2 aufgeführten digitalen Dienste betrifft. Im Unterschied zu den unter § 2 aufgeführten digitalen Diensten können Diensterweiterungen ggf. nur bei Abschluss eines gesonderten Abonnements verfügbar sein.",
        ],
      },
    ],
    section_4: [
      {
        title: "§ 4 Verfügbarkeit",
        paragraphs: [
          "LexMea ist bestrebt sein gesamtes Angebot möglichst störungsfrei und mindestens 95% der Zeit über das Kalenderjahr gerechnet, zur Verfügung zu stellen. Notwendige und regelmäßige Wartungsarbeiten, die vorher angekündigt werden, sind hiervon ausgenommen.",
          "Eine Nichtverfügbarkeit aufgrund von Störungen des Internets, Gründen höherer Gewalt oder sonstigen Umständen wie zum Beispiel Krieg oder Pandemien/Epidemien, die LexMea nicht zu vertreten hat, sind hiervon ausgenommen.",
          "Eine Nichtverfügbarkeit aufgrund von Cyberangriffen, die auch durch vernünftigerweise zu erwartende Sorgfalt in Sachen Cybersicherheit nicht hätten abgewendet werden können, ist hiervon ausgenommen.",
        ],
      },
    ],
    section_5: [
      {
        title: "§ 5 Urheber- und Nutzungsrechte",
        paragraphs: [
          "Der Nutzer erhält die einfache, persönliche, gebührenfreie, nicht ausschließliche, nicht übertragbare, widerrufliche und nicht unterlizenzierbare Lizenz für den Zugriff auf die digitalen Dienste sowie für deren Nutzung. Diese Lizenz und jede Zugriffsberechtigung zum Service werden automatisch widerrufen, wenn der Nutzer seine Mitwirkungspflichten nach § 6 verletzt.",
          "Diese Lizenz berechtigt zur Nutzung der digitalen Dienste auf LexMea. Redaktionell erstellte Inhalte (insb. Prüfungsschemata) dürfen abgerufen, heruntergeladen, lokal gespeichert, kopiert und verändert werden.",
          "Alle Rechte, die dem Nutzer in diesem Vertrag nicht ausdrücklich eingeräumt werden, sind LexMea vorbehalten. Der Nutzer erkennt an, dass es sich bei der Datenbank um ein von LexMea hergestelltes Datenbankwerk bzw. um eine Datenbank im Sinne von §§ 4 Abs. 2, 87a Abs. 1 UrhG handelt. Zugehörige Computerprogramme unterfallen dem Schutz der §§ 69a ff. UrhG. Bereitgestellte Inhalte unterfallen dem Schutz des § 2 UrhG. Rechte Dritter an geschützten Werken und Inhalten bleiben unberührt.",
          "Für die Funktion zum Teilen eigener Inhalte mit anderen Nutzern, räumt der Nutzer LexMea eine Lizenz zum Hosten, zum Verwenden, zum Verbreiten, zur Modifizierung und zum Anzeigen dieser Inhalte entsprechend der Nutzereinstellungen ein. Die Freigabe der eigenen Inhalte muss gesondert aktiviert werden und kann im Konto individuell verwaltet werden. Der Nutzer gewährleistet gegenüber LexMea, dass die Inhalte frei von Rechten Dritter genutzt werden können. LexMea behält sich vor veröffentlichte Inhalte bei Rechtsverletzungen ganz oder teilweise zu sperren oder zu löschen.",
        ],
      },
    ],
    section_6: [
      {
        title: "§ 6 Mitwirkungs- und Sicherheitspflichten",
        paragraphs: [
          "Der Nutzer hat seinerseits die technischen Voraussetzungen zur Verwendung der Web-App und zum Abrufen der Inhalte zu schaffen. Das ist insbesondere die Verwendung eines gängigen Browsers mit aktueller Software.",
          "Der Nutzer verpflichtet sich, gängige Sicherheitsvorkehrungen zum Schutz seiner Systeme vor Schadsoftware zu treffen. Der Nutzer unternimmt geeignete Schutzmaßnahmen um insbesondere eine Datenausspähung (§ 202a StGB), eine Datenveränderung (§ 303a StGB) oder Datensabotage (§ 303b StGB) zu verhindern.",
          "Der Nutzer hat, die Zugangsdaten, die er zur Anmeldung bei LexMea verwendet, vertraulich zu behandeln. Er trägt die alleinige Verantwortung für sämtliche Aktivitäten, die unter diesen Zugangsdaten auftreten. Kommt der Verdacht auf, dass sich jemand Zugriff auf das Nutzerkonto verschafft hat, hat der Nutzer LexMea unverzüglich per E-Mail an {email} zu unterrichten.",
          "Der Nutzer unterlässt es im Rahmen seiner Mitwirkungspflichten:",
        ],
        list: [
          "das Angebot LexMeas in einer Weise zu nutzen, die das Angebot oder die Server oder die mit dem Angebot verbundenen Netzwerke beeinträchtigen, stören oder negative Auswirkungen auf diese haben könnten.",
          "Viren oder einen anderen bösartigen Code hochzuladen oder anderweitig die Sicherheit des Angebots zu gefährden.",
          "Identifikationsmerkmale zu manipulieren, um die Herkunft von über den Dienst übertragenen Informationen zu verschleiern.",
          "Teile des Angebots ohne die vorherige schriftliche Genehmigung von LexMea zu „framen“ (i.e. einen Teilbereich der Seite als Frame in eine andere Seite zu transkludieren) oder zu „spiegeln“ (i.e. mit allen Unterseiten zu speichern).",
          "Meta-Tags (codierte Informationen, die im Head-Bereich eines HTML-Dokuments hinterlegt werden und der Angabe von Metadaten dienen), Codes oder andere Geräte, die einen Verweis auf LexMea oder das Angebot enthalten, eine Marke, einen Handelsnamen, eine Dienstmarke, ein Logo oder Slogan von LexMea zu nutzen, um Personen auf andere Websites zu führen(z.B. mittels Google-Keywords Ads / SEA).",
          "Teile unseres Angebotes zu ändern, anzupassen, unterzulizenzieren, zu übersetzen, zu verkaufen, zurückzuentwickeln, zu entschlüsseln, zu dekompilieren oder anderweitig zu zerlegen oder andere dazu bringen, dies zu tun.",
          "Aktivitäten zu begünstigen oder zu fördern, die gegen diesen Vertrag verstoßen.",
        ],
      },
    ],
    section_7: [
      {
        title: "§ 7 Widerruf",
        paragraphs: [
          "Verbrauchern steht ein Widerrufsrecht zu. Verbraucher sind natürliche Personen, die ein Rechtsgeschäft zu Zwecken abschließen, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.",
        ],
        subsections: [
          {
            title: "Widerrufsbelehrung",
            paragraphs: [
              "Verbraucher haben das Recht diesen Vertrag binnen vierzehn Tagen ohne Angabe von Gründen zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. Zur Wahrung der Widerrufsfrist reicht es aus, dass der Nutzer die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absendet.",
              "Das Widerrufsrecht erlischt vorzeitig, wenn LexMea die geschuldete Dienstleistung vollständig erbracht hat und mit der Ausführung der Dienstleistung erst begonnen hat, nachdem der Nutzer dazu seine ausdrückliche Zustimmung gegeben hat und gleichzeitig seine Kenntnis davon bestätigt hat, dass er sein Widerrufsrecht bei vollständiger Vertragserfüllung durch LexMea verliert.",
              "Um sein Widerrufsrecht auszuüben, muss der Nutzer LexMea mittels einer eindeutigen Erklärung – z.B. mit der Post versandter Brief oder E-Mail ({email}) – über seinen Entschluss, diesen Vertrag zu widerrufen, informieren. Der Nutzer kann hierfür – muss aber nicht - das unten dargestellte Muster-Widerrufsformular verwenden.",
            ],
          },
          {
            title: "Folgen des Widerrufs",
            paragraphs: [
              "Wenn der Nutzer diesen Vertrag widerruft, hat LexMea dem Nutzer die aufgrund des Vertragsschlusses erhaltenen Zahlungen, einschließlich etwaiger Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass der Nutzer eine andere Art der Lieferung als die von LexMea angebotene, günstigste Standardlieferung gewählt hat), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag, an dem die Mitteilung über den Widerruf dieses Vertrags bei LexMea eingegangen ist, zurückzuzahlen. Für diese Rückzahlung verwendet LexMea dasselbe Zahlungsmittel, das der Nutzer bei der ursprünglichen Transaktion eingesetzt hat, es sei denn, es wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden dem Nutzer wegen dieser Rückzahlung Entgelte berechnet.",
              "Hat der Nutzer verlangt, dass eine kostenpflichtige Diensterweiterung während der Widerrufsfrist beginnen soll, so haben er LexMea einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem der Nutzer LexMea von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichtet, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.",
            ],
          },
        ],
      },
    ],
    section_7_B: [
      {
        title: "Muster-Widerrufsformular",
        paragraphs: [
          "Für den Widerruf kann u.a. dieses Widerrufs Formular ausgefüllt und an {email} oder an LexMea GmbH, Leipziger Str. 44, 10117 Berlin gesendet werden (Unzutreffendes streichen):",
        ],
        list: [
          "Hiermit widerrufe ich den von mir abgeschlossenen Vertrag über die Erbringung der folgenden Dienstleistung: ____________________",
          "Bestellt am: __________________",
          "Name des Nutzers: ___________________",
          "Anschrift des Nutzers oder E-Mail-Adresse: ________________",
          "Unterschrift Nutzers (nur bei Mitteilung auf Papier)",
          "Datum",
        ],
      },
    ],
    section_8: [
      {
        title: "§ 8 Mängelgewährleistung und Haftung",
        paragraphs: [
          "LexMea bemüht sich bestmöglich den Inhalt der Datenbank auf aktuellem Stand zu halten und die bei der Erstellung von Inhalten entsprechende Sorgfalt aufzuwenden, wobei LexMea auf die Zulieferung von Daten durch Dritte angewiesen ist. Die inhaltliche Richtigkeit, Aktualität und Vollständigkeit der zur Verfügung gestellten Inhalte, Gesetze und sonstige Zusammenstellungen kann nicht gewährleistet werden. Der Nutzer ist gehalten, eine Plausibilitätsprüfung der Inhalte entsprechend ihrer Verwendung durchzuführen.",
          "LexMea haftet entsprechend der gesetzlichen Vorschriften unbeschränkt für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, beim arglistigen Verschweigen von Mängeln, für Ansprüche aus dem Produkthaftungsgesetz sowie für Schäden, die auf Vorsatz oder grober Fahrlässigkeit LexMeas oder eines gesetzlichen Vertreters oder Erfüllungsgehilfen beruhen, sowie für Schäden wegen Nichteinhaltung einer von LexMea gegeben Garantie, einer zugesicherten Eigenschaft oder eines von LexMea übernommenen Beschaffungsrisikos.",
          "In den übrigen Fällen, haftet LexMea unter Begrenzung auf Ersatz des vertragstypischen vorhersehbaren Schadens für solche Schäden, die auf einer leicht fahrlässigen Verletzung wesentlicher Vertragspflichten durch LexMea oder einen gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Wesentliche Vertragspflichten sind Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglichen.",
          "Eine weitergehende Haftung wird ausgeschlossen.",
          "Die verschuldensunabhängige Haftung von LexMea für bereits bei Vertragsschluss vorhandene Mängel nach § 536a Abs. 1 Halbsatz 1 BGB wird ausgeschlossen.",
        ],
      },
    ],
    section_9: [
      {
        title: "§ 9 Datenschutz",
        paragraphs: [
          "Datenschutz hat für LexMea höchste Priorität. Wir gehen daher an zahlreichen Stellen über das bereits hohe Schutzniveau der europäischen Datenschutz-Grundverordnung hinaus. Unsere {email} erläutern, wie wir personenbezogenen Daten erheben und nutzen.",
          "Die Nutzung der bei Markteinführung bereitgestellten digitalen Dienste LexMeas ist kostenfrei und wird dies auch in Zukunft bleiben. Dies können wir über Werbeanzeigen gewährleisten. Wenn wir diese Werbung individualisieren geben wir keine Daten an Werbepartner weiter, sondern nehmen die Personalisierung selbst auf unseren eigenen, europäischen Servern vor. So zeigen wir beispielsweise Nutzern, die § 433 BGB öffnen, Lehrbücher zum Kaufrecht an, ohne dass unsere Werbepartner wissen, wer § 433 BGB öffnet. LexMea erhebt, nutzt und verarbeitet daher personenbezogene Daten, um dem Nutzer individualisierte Inhalte und Anzeigen anbieten zu können. Im Datenschutzcockpit kann der Nutzende seine Privatsphäre-Einstellungen hinsichtlich der Verwendung seiner Daten jederzeit überprüfen und zentral verwalten.",
          "LexMea gewährleistet, dass sämtliche mit der Verarbeitung personenbezogener Daten befassten Personen sich eine Verpflichtung auf das Datengeheimnis unterzeichnet haben.",
          "Für den Fall, dass LexMea als Auftragsverarbeiter gem. Art. 28 Datenschutz-Grundverordnung für den Kunden tätig wird, erteilt der Kunde LexMea die allgemeine Genehmigung, weitere Auftragsverarbeiter (insb. den europäischen Server-Dienstleister Hetzner Online mit Sitz in Gunzenhausen) hinzuziehen. LexMea selbst führt geeignete technische und organisatorische Maßnahmen so durch, dass die Verarbeitung im Einklang mit den Anforderungen der Datenschutzgrundverordnung erfolgt und den Schutz der Rechte der betroffenen Person gewährleistet.",
          "Der Nutzer kann optional während des Registrierungsprozesses in den Empfang eines Newsletters (Produktänderungen, Anzeigen, Events u.ä.) einwilligen. Da Spam-Mails nerven, schicken wir nur bei wirklich relevanten Neuerungen eine E-Mail – etwa bei der Einführung gänzlich neuer Funktionen auf LexMea, besonderen Events in der Region des Nutzers oder zu seinem Rechtsgebiet. Dies kann in den Profileinstellungen sowie über einen Link am Ende jeder E-Mail jederzeit durch den Nutzer widerrufen werden.",
        ],
      },
    ],
    section_10: [
      {
        title: "§ 10 Allgemeine Bestimmungen",
        paragraphs: [
          "Widersprechende, abweichende oder zusätzliche Allgemeine Geschäftsbedingungen des Nutzers werden nicht Bestandteil des Vertrages, es sei denn LexMea stimmt deren Verwendung ausdrücklich zu. Diese Allgemeine Geschäftsbedingungen gelten auch im Falle einer Leistungserbringung durch LexMea in Kenntnis widersprechender oder abweichender Allgemeine Geschäftsbedingungen des Nutzers.",
          "Es bestehen keine mündlichen Nebenabreden oder Ergänzungen zum Zeitpunkt des Vertragsschlusses. Nebenabreden, Vertragsänderungen oder Ergänzungen bedürfen für ihre Wirksamkeit der Textform. Die Aufhebung des Textformerfordernisses unterliegt ebenfalls der Textform.",
          "Als Online-Unternehmen sind wir gem. VSBG und VO (EU) 524/2013 verpflichtet, Verbraucher i.S.d. § 13 BGB auf die Plattform zur Online-Streitbeilegung der Europäischen Kommission hinzuwiesen. Diese ist abrufbar unter: {email}. Wir sind grundsätzlich bereit, aber nicht verpflichtet, an einem solchen Streitbeilegungsverfahren teilzunehmen.",
          "Der Erfüllungsort ist Berlin. Ausschließlicher Gerichtsstand für Streitigkeiten zwischen LexMea und Nutzern, die Kaufmann, juristische Person oder ein öffentlich-rechtliches Sondervermögen sind, ist Berlin.",
          "Es gilt das Recht der Bundesrepublik Deutschland in sämtlichen Streitigkeiten, die sich aus dem Nutzungsverhältnis mit LexMea ergeben. Die Anwendung von Rechtsnormen, die in eine andere Rechtsordnung verweisen, ist ausgeschlossen.",
          "Wir behalten uns das Recht vor, diese Allgemeine Geschäftsbedingungen aus triftigen Gründen – insbesondere aufgrund einer geänderten Rechtslage, höchstrichterlicher Rechtsprechung, wenn die Vertragsänderungen für den Nutzer nur günstig sind oder aus ähnlichen Gründen, wenn die Änderungen nicht die gegenseitigen Hauptleistungspflichten betreffen und den Nutzer nicht unangemessen entgegen Treu und Glauben benachteiligen – mit einer Frist von zwei Monaten im Voraus zu ändern.",
          "Künftige Änderungen dieser Allgemeinen Geschäftsbedingungen, die aufgrund technischer Änderungen oder Weiterentwicklungen, neuer organisatorischer Anforderungen des Massenverkehrs, Regelungslücken in den Allgemeinen Geschäftsbedingungen, Veränderungen der Marktgegebenheiten oder anderen gleichwertigen Gründen erforderlich sind und den Kunden nicht unangemessen benachteiligen und die nicht zu einer erhöhten Zahlungsverpflichtung seitens des Nutzers führen, werden dem Kunden spätestens zwei Monate vor dem vorgeschlagenen Zeitpunkt ihres Wirksamwerdens per E-Mail oder in Textform angeboten. Die Änderungen werden in hervorgehobener Form, etwa durch eine synoptische Gegenüberstellung oder durch Herausstellung der Änderungen in Fettdruck, dargestellt. Hat der Nutzer mit LexMea im Rahmen der Geschäftsbeziehung einen elektronischen Kommunikationsweg vereinbart (z.B. Push-Nachrichten oder LexMea-Postfach), können die Änderungen auch auf diesem Wege angeboten werden. Die Änderungen werden wirksam, wenn der Nutzer seine Ablehnung nicht vor dem vorgeschlagenen Zeitpunkt des Wirksamwerdens der Änderungen per E-Mail oder schriftlich angezeigt hat und LexMea auf diese Rechtsfolge in der Änderungsmitteilung hingewiesen hat. Der Nutzer kann vor dem vorgeschlagenen Zeitpunkt des Wirksamwerdens der Änderungen auch fristlos kündigen. Hierauf wird ihn LexMea ebenfalls besonders hinweisen.",
        ],
      },
    ],
    section_11: [
      {
        title:
          "§ 11 Sonderbestimmungen für kostenpflichtige Diensterweiterungen",
        subsections: [
          {
            title: "Preise und Leistungsumfang",
            paragraphs: [
              "Angebotsbedingungen (Preise, Konditionen, Leistungsumfang) und gesonderte Nutzungsbedingungen der kostenpflichtigen Diensterweiterungen ergeben sich aus den im Zeitpunkt der Bestellung gültigen Fassung der Preisangaben und Produktbeschreibungen auf den Internetseiten von LexMea (lexmea.de).",
              "Die Nutzungsrechte beschränken sich auf das vom Nutzer jeweils bestellte Produkt. Sie sind persönlich, nicht ausschließlich, nicht übertragbar, widerruflich und nicht unterlizenzierbar. Ein gleichzeitiger Zugriff mehrerer Nutzer über dieselben persönlichen Zugangsdaten ist nicht möglich. Diese Lizenz und jede Zugriffsberechtigung zum Service werden automatisch widerrufen, wenn der Nutzer seine Mitwirkungs- und Sicherheitspflichten nach diesen Allgemeinen Geschäftsbedingungen verletzt. Alle Rechte, die dem Nutzer in diesem Vertrag nicht ausdrücklich eingeräumt werden, sind LexMea vorbehalten.",
              "Soweit sich aus den auf lexmea.de abrufbaren Angebotsbedingungen des jeweiligen Produktes nichts anderes ergibt oder der Nutzer mit LexMea schriftlich nichts Abweichendes vereinbart hat, ist die Vergütung jeweils ohne Abzug im Voraus zu leisten. Etwaige Kosten für die Abrufe einzelner Dienste sind klar gekennzeichnet und werden erst bei gesonderter Freischaltung fällig.",
              "Sofern der Nutzer bei Abschluss einer kostenpflichtigen Diensterweiterung einen externen Zahlungsanbieter auswählt, bedient sich LexMea dieses Anbieters, ohne dass dieser Vertragspartner wird.",
              "Sofern LexMea als Zahlungsmittel den Bankeinzug mittels SEPA-Lastschrift anbietet und akzeptiert, erfolgt die Ankündigung des Lastschrifteinzugs („Pre-Notification“) mit einer Frist von mindestens einem Werktag. Schlägt der Forderungseinzug trotz rechtzeitiger Ankündigung fehl und hat der Nutzer das Fehlschlagen zu vertreten, hat er LexMea die hierdurch entstandenen Mehrkosten zu erstatten.",
              "Bei Verzug des Kunden ist LexMea berechtigt, den Zugang zu Online-Diensten zu sperren und die Auslieferung weiterer vom Kunden bestellter Produkte einzustellen, bis sämtliche fällige Forderungen aus der gesamten Geschäftsbeziehung mit dem Kunden, gleich aus welchem Rechtsgrund, vom Kunden ausgeglichen worden sind. Geldschulden sind während des Verzugs vom Kunden nach den gesetzlichen Vorschriften, insbesondere gem. § 288 BGB, zu verzinsen.",
              "LexMea ist bei Dauerschuldverhältnissen zu Preiserhöhungen berechtigt, soweit diese bezogen auf das entsprechende Produkt innerhalb eines Jahres seit der letzten Preiserhöhung 7% oder in drei aufeinanderfolgenden Jahren seit der letzten Preiserhöhung 15% des zuvor geltenden Preises nicht übersteigen und die Preiserhöhung der bei LexMea auch unter Berücksichtigung gegebenenfalls eingetretener Kostenersparnisse eingetretenen Kostensteigerung für die Erzeugung und Bereitstellung der Produkte, insbesondere der erforderlichen Kosten für die Unterhaltung, Wartung und Weiterentwicklung der für die Leistungserbringung verwendeten technischen und personellen Infrastruktur oder der erforderlichen Kosten für die Lizenzierung von Werken Dritter, entspricht oder soweit die Preiserhöhung der Erhöhung der Marktpreise der jeweiligen in den Online-Diensten bereitgestellten Printwerke entspricht. Preiserhöhungen werden, wenn keine längere Frist in der Änderungsmitteilung bestimmt ist, mit Beginn des dritten Monats nach Zugang der Änderungsmitteilung beim Kunden wirksam. Der Kunde ist zur außerordentlichen Kündigung des Vertrags innerhalb von sechs Wochen ab Mitteilung der Preiserhöhung auf den Zeitpunkt des Inkrafttretens der Preiserhöhung berechtigt. Macht der Kunde von diesem Recht keinen Gebrauch und ist der Kunde auf diese Rechtsfolge in der Mitteilung der Preiserhöhung hingewiesen worden, wird der Vertrag zu den geänderten Preisen fortgeführt.",
              "Soweit bei Online-Diensten der Leistungsumfang mehr als nur unwesentlich erweitert wird, ist LexMea berechtigt, die Preise angemessen, nämlich im Verhältnis der eingetretenen Erweiterung, zu erhöhen. Die Erhöhung wird mit Beginn des übernächsten Monats nach Zugang der Änderungsmitteilung beim Kunden wirksam. Der Kunde ist zur außerordentlichen Kündigung des Vertrags innerhalb von sechs Wochen ab Mitteilung der Preiserhöhung auf den Zeitpunkt des Inkrafttretens der Preiserhöhung berechtigt. Macht der Kunde von diesem Recht keinen Gebrauch und ist der Kunde auf diese Rechtsfolge in der Mitteilung der Preiserhöhung hingewiesen worden, wird der Vertrag zu den geänderten Preisen fortgeführt.",
            ],
          },
          {
            title: "Laufzeit und Kündigung",
            paragraphs: [
              "Soweit sich aus den Angebotsbedingungen auf LexMea.de nichts Abweichendes ergibt, werden kostenpflichtige Diensterweiterungen nach Wahl des Nutzers mit einer Laufzeit von einem Monat oder einem Jahr abgeschlossen. Sofern der Nutzer die kostenpflichtige Diensterweiterung nicht mindestens fünf Tage vor Ablauf der Laufzeit kündigt, verlängert sich das Vertragsverhältnis auf unbestimmte Zeit mit einer Kündigungsfrist von einem Monat. LexMea kann verlängerte Vertragsverhältnisse mit Frist von drei Monaten kündigen, wenn Tarifoptionen auslaufen. Nutzer können ihre Verträge einfach per E-Mail an support{'@'}lexmea.de oder per Kündigungsbutton in den Nutzerkonto-Einstellungen kündigen. Bei ordentlicher Kündigung des Vertrags wird LexMea den Zugang des Nutzers zu den kostenpflichtigen Diensterweiterungen zum Ende der Laufzeit beenden. Die Nutzung der kostenfreien Dienste bleibt unter Nutzung desselben Kontos weiterhin möglich. Das Recht zur Kündigung des Vertrages aus wichtigem Grund bleibt unberührt. Ein wichtiger Grund liegt für LexMea insbesondere vor, wenn der Kunde trotz mindestens zweimaliger Mahnung mit der Zahlung einer Rechnung in Rückstand ist oder über das Vermögen des Kunden das zumindest vorläufige Insolvenzverfahren eröffnet worden ist. Schadensersatzansprüche bleiben hiervon unberührt.",
            ],
          },
        ],
      },
    ],
  },
};
