import type { ILegalText } from "~/models/ILegalText";
import type { ILegalTextParagraph } from "~/models/ILegalTextParagraph";
import type { DeskTab } from "~/models/ITab";
import { navigate } from "vike/client/router";

export function useTabsMutations() {
  const { activeTab, activeTabIndex } = useActiveTab();
  const tabRoute = useTabRoute();
  const localTabs = useLocalTabs();
  const syncEnabled = ref(false);

  const { data: tabs } = useFetchTabs();

  const { syncTabsMutation } = tabsMutations();

  const buildTabs = (tabs: DeskTab[], activeIndex: number) =>
    tabs.map((tab, index) => ({
      ...tab,
      active: index === activeIndex,
    }));

  const setTabs = (tabs: DeskTab[]) => {
    if (syncEnabled.value) {
      syncTabsMutation.mutate(tabs);
    }
    localTabs.value = tabs;
  };

  const addTab = async (tab: DeskTab) => {
    const newTabs = buildTabs([...tabs.value, tab], tabs.value.length);
    setTabs(newTabs);
    await navigate(tabRoute(tab));
  };

  const removeTab = async (index: number) => {
    // set new active when deleting active tab
    let nextTabIndex = activeTabIndex.value;
    if (activeTab.value) {
      // decrement active tab index by 1 when
      // 1. deleting the active tab and it's the last tab in the list
      // 2. deleting a tab before the active one
      if (
        (nextTabIndex === index && nextTabIndex === tabs.value.length - 1) ||
        index < nextTabIndex
      ) {
        nextTabIndex -= 1;
      }
    }
    const updatedTabs = buildTabs(
      tabs.value.filter((_tab, i) => i !== index),
      nextTabIndex
    );
    const newActiveTab = updatedTabs[nextTabIndex];
    setTabs(updatedTabs);
    await navigate(tabRoute(newActiveTab));
  };

  const switchActiveTab = (index: number) => {
    const updatedTabs = buildTabs(tabs.value, index);
    setTabs(updatedTabs);
  };

  const changeActiveTab = (tab: Partial<DeskTab>) => {
    if (activeTabIndex.value === -1) return;
    const copy = [...tabs.value];
    copy.splice(activeTabIndex.value, 1, {
      ...copy[activeTabIndex.value],
      ...{ ...tab, active: true },
    });
    if (JSON.stringify(copy) === JSON.stringify(tabs.value)) return;
    setTabs(copy);
  };

  const moveTab = async (from: number, to: number) => {
    const tabCopy = [...tabs.value];
    const tabToMove = tabCopy.splice(from, 1)[0];
    tabCopy.splice(to, 0, tabToMove);
    setTabs(tabCopy);
  };

  const toTab = (
    legalText: ILegalText,
    paragraph?: ILegalTextParagraph
  ): DeskTab => {
    // TODO: remove this from backend, we can just do this in the ParagraphTab component
    const text = `${paragraph?.paragraph ?? ""} ${legalText.name}`.trim();
    return {
      text,
      legalText: legalText.slug,
      paragraph: paragraph?.slug || "toc",
      active: false,
    };
  };

  return {
    addTab,
    changeActiveTab,
    removeTab,
    moveTab,
    toTab,
    switchActiveTab,
  };
}
