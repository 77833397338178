export function useMenuToggle() {
  const [isMenuOpen, toggleMenu] = useToggle();

  const menuElement = ref<HTMLElement | null>(null);
  const menuTriggerElement = ref<HTMLElement | null>(null);

  onClickOutside(menuElement, () => toggleMenu(false), {
    ignore: [menuTriggerElement],
  });

  useEventListener(menuTriggerElement, "click", () => toggleMenu());

  return { menuElement, menuTriggerElement, isMenuOpen, toggleMenu };
}
