export function useParagraphSearch(
  query: MaybeRef<string>,
  legalTextId: MaybeRef<number | undefined>
) {
  const {
    refetch,
    data: searchParagraphs,
    isLoading,
  } = useQuery({
    ...queries().paragraphs.search(query, legalTextId),
    // disable automatic fetching
    enabled: false,
  });

  const data = computed(() =>
    toValue(query) ? searchParagraphs.value : undefined
  );

  debouncedWatch(
    () => toValue(query),
    (q) => !!q && refetch(),
    { debounce: 300 }
  );

  return { data, isLoading };
}
