<template>
  <div>
    <label class="flex cursor-pointer items-center">
      <input
        v-model="model"
        type="checkbox"
        class="lexmea-checkbox mr-2"
        v-bind="$attrs"
      />
      <div class="overflow-hidden" :class="[small ? 'text-xs' : 'text-base']">
        <slot>
          {{ label }}
        </slot>
      </div>
    </label>
    <span v-if="error && error.length > 0" class="text-sm text-maroon-flush">
      {{ typeof error === "string" ? error : error[0] }}
    </span>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script lang="ts" setup>
// TODO: div is not valid within label. Restructure this!
const props = withDefaults(
  defineProps<{
    error?: string | string[];
    label?: string;
    small?: boolean;
    modelValue?: boolean;
  }>(),
  {
    label: "",
    error: "",
    modelValue: false,
  }
);

defineEmits<{
  "update:modelValue": [data: boolean];
}>();
const model = useVModel(props, "modelValue");
</script>
