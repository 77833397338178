<template>
  <button
    :class="'mr-2 flex items-center gap-1 ' + size"
    @click="setLocale(locale === 'de' ? 'en' : 'de')"
  >
    <i-material-symbols-light-language v-if="showIcon" class="size-5" />
    <span :class="{ 'font-bold': locale === 'de' }">DE</span>
    <span
      :class="[
        'block w-px bg-current',
        { 'h-3': size === 'sm' },
        { 'h-4': size === 'md' },
      ]"
    ></span>
    <span :class="{ 'font-bold': locale === 'en' }">EN</span>
  </button>
</template>

<script setup lang="ts">
const props = defineProps<{ size?: string; showIcon?: boolean }>();

const { size = "md", showIcon = false } = props;

const { locale } = useI18n();
const { setLocale } = useLanguageSwitch();
</script>
