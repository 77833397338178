<template>
  <svg
    v-if="focus"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 196 240"
    data-pw="schema-icon-focus"
  >
    <rect
      x="9"
      y="37"
      width="178"
      height="194"
      rx="29"
      ry="29"
      class="fill-current stroke-current"
      :class="colorClass ? colorClass + '-darker' : 'text-black'"
      stroke-width="18"
    />
    <path
      d="m71.04,18c.74-10.03,12.53-18,26.96-18s26.22,7.97,26.96,18h15.04v39H57V18h14.04Zm32.96-6c0,3.31-2.69,6-6,6s-6-2.69-6-6,2.69-6,6-6,6,2.69,6,6Zm-40,82c0,6.07-4.92,11-11,11s-11-4.93-11-11,4.92-11,11-11,11,4.92,11,11Zm0,34c0,6.07-4.92,11-11,11s-11-4.93-11-11,4.92-11,11-11,11,4.93,11,11Zm-11,45c6.08,0,11-4.93,11-11s-4.92-11-11-11-11,4.93-11,11,4.92,11,11,11Zm24-85h76v14h-76v-14Zm76,34h-76v14h76v-14Zm-76,34h76v14h-76v-14Z"
      class="fill-current stroke-none"
      :class="colorClass ? colorClass + '-lighter' : 'text-gray-500'"
      fill-rule="evenodd"
      stroke-width="18"
    />
  </svg>

  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 196 240"
    data-pw="schema-icon"
  >
    <rect
      x="9"
      y="37"
      width="178"
      height="194"
      rx="29"
      ry="29"
      class="fill-white stroke-current"
      :class="[
        filled ? 'fill-white' : 'fill-none',
        colorClass ? colorClass + '-darker' : 'text-black',
      ]"
      stroke-width="18"
    />
    <path
      d="m71.04,18c.74-10.03,12.53-18,26.96-18s26.22,7.97,26.96,18h15.04v39H57V18h14.04Zm32.96-6c0,3.31-2.69,6-6,6s-6-2.69-6-6,2.69-6,6-6,6,2.69,6,6Zm-40,82c0,6.07-4.92,11-11,11s-11-4.93-11-11,4.92-11,11-11,11,4.92,11,11Zm0,34c0,6.07-4.92,11-11,11s-11-4.93-11-11,4.92-11,11-11,11,4.93,11,11Zm-11,45c6.08,0,11-4.93,11-11s-4.92-11-11-11-11,4.93-11,11,4.92,11,11,11Zm24-85h76v14h-76v-14Zm76,34h-76v14h76v-14Zm-76,34h76v14h-76v-14Z"
      class="fill-current stroke-none"
      :class="colorClass ? colorClass + '-darker' : 'text-black'"
      fill-rule="evenodd"
      stroke-width="18"
    />
  </svg>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    focus?: boolean;
    colorClass?: string;
    filled?: boolean;
  }>(),
  {
    focus: false,
    filled: false,
    colorClass: undefined,
  }
);
</script>
