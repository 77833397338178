const useSearchbarFocus = createSharedComposable(() => {
  const searchBarRef = ref<HTMLInputElement | null>(null);

  const focusSearchBar = () => {
    if (searchBarRef.value) {
      searchBarRef.value.focus();
    }
  };

  const setSearchbarRef = (ref: HTMLInputElement) => {
    searchBarRef.value = ref;
  };

  return {
    setSearchbarRef,
    focusSearchBar,
  };
});

export default useSearchbarFocus;
