import { useToast as useVueToastification } from "vue-toastification";

export function useToast() {
  const toast = useVueToastification();

  const toastInfo = useLocalStorage<string | null>("toast", null, {
    initOnMounted: true,
  });

  const persist = (content: string, options: { type: string }) => {
    toastInfo.value = JSON.stringify({
      type: options.type,
      message: content,
    });
  };

  const wrapWithClearAndPersist =
    <T extends (...args: any[]) => any>(fn: T) =>
    (...args: Parameters<T>) => {
      toast.clear();
      return fn(...args);
    };

  const success = wrapWithClearAndPersist(toast.success);

  const error = wrapWithClearAndPersist(toast.error);

  const info = wrapWithClearAndPersist(toast.info);

  const warning = wrapWithClearAndPersist(toast.warning);

  const dismiss = toast.dismiss;

  return { success, error, info, warning, dismiss, persist, toastInfo };
}
