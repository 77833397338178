<template>
  <LexmeaSearch
    ref="searchInput"
    v-model:query.trim="query"
    class="relative"
    theme="grey"
    :displayValue="() => ''"
    :options="allResults"
    :label="$t('main_search_placeholder')"
    prependIcon
    compareBy="id"
    :compareOptionsBy="compareOptionsBy"
    nullable
    thickRing
    lightRing
    showPlaceholderOnFocus
    clearModelOnFocusOut
    lowHeightOnMobile
    dropdownClasses="bg-lexmea-gray-100"
    :iconPlace="isSmallerThanMd ? 'right' : 'left'"
    @update:selectedItem="selectedItem = $event"
  >
    <template #heading="{ option }">
      <div v-if="option.loading" class="flex items-center justify-center py-2">
        <i-line-md-loading-twotone-loop class="h-8 w-8" />
      </div>
      <span v-else class="px-4 py-2" :class="option.classes">
        {{ option.title }}
      </span>
    </template>
    <template #default="{ option, active }">
      <ParagraphSearchResult
        v-if="'paragraph' in option"
        :active="active"
        :paragraph="option"
        :query="query"
        @openInNewTab="openInNewTab"
      />
      <EntrySearchResult v-else :entry="option" :query="query" />
    </template>
    <template #info>
      <i18n-t
        v-if="(paragraphs?.data?.length ?? 0) < 5"
        class="block py-2 text-center text-xs font-bold italic"
        keypath="ui.search.missing_something"
        tag="span"
      >
        <template #feedback>
          <button
            class="cursor-pointer px-0.5 italic text-blue-700 underline"
            @click="openFeedback"
          >
            {{ $t("ui.search.feedback") }}
          </button>
        </template>
      </i18n-t>
    </template>
  </LexmeaSearch>
</template>

<script lang="ts" setup>
import type { ILegalText } from "~/models/ILegalText";
import type { SearchParagraph } from "~/api/legalTextParagraph";
import type { INote, ISchemaWithText } from "~/models/IUserEntry";
import { navigate } from "vike/client/router";
import useLexMeaTailwindBreakpoints from "~/composables/useLexMeaTailwindBreakpoints";
import { useVfm } from "vue-final-modal";

const { t } = useI18n();
type SearchResult = SearchParagraph | ISchemaWithText | INote;
type SearchPlainField = {
  title: string;
  loading: boolean;
  heading: true;
  classes?: string[];
};

const props = defineProps<{
  legalText?: ILegalText;
  autoFocusEnabled?: boolean;
}>();

const emit = defineEmits<{
  resultClicked: [];
}>();
const searchInput = ref<{ blur: () => void; focus: () => void }>();

const legalText = toRef(props, "legalText");
const legalTextId = computed(() => legalText.value?.id);

const query = ref("");

const { data: paragraphs, isLoading: paragraphsLoading } = useParagraphSearch(
  query,
  legalTextId
);
const { data: schemas, isLoading: schemasLoading } = useSchemaSearch(query);
const { data: notes, isLoading: notesLoading } = useNoteSearch(query);

const { isSmallerThanMd } = useLexMeaTailwindBreakpoints();
const { open: openFeedback } = useFeedbackModal();

const compareOptionsBy = (item: SearchResult | SearchPlainField) => {
  if ("paragraph" in item) {
    return `paragraph-${item.id}`;
  }
  if ("id" in item) {
    return `${item.type}-${item.id}`;
  }
  return item.title;
};

const entries = computed<Array<ISchemaWithText | INote>>(() => {
  const schemasData = schemas.value?.data ?? ([] as ISchemaWithText[]);
  const notesData = notes.value?.data ?? ([] as INote[]);
  // take 3 schemas + 2 notes, if there are enough results, otherwise take more of the other one
  return [
    ...schemasData.slice(0, Math.max(5 - notesData.length, 3)),
    ...notesData.slice(0, Math.max(5 - notesData.length, 2)),
  ];
});

const titleClasses = ["font-bold", "text-lg", "pb-0", "border-b-0"];

const allResults = computed(() => {
  const total: (SearchResult | SearchPlainField)[] = [
    {
      title: t("ui.search.laws_title"),
      heading: true,
      loading: false,
      classes: titleClasses,
    },
  ];

  const paragraphData = paragraphs?.value?.data.slice(0, 5);

  if (paragraphData && paragraphData.length > 0) {
    total.push(...paragraphData);
  } else {
    total.push({
      title: t("ui.search.no_laws"),
      loading: paragraphsLoading.value,
      heading: true,
    });
  }

  total.push({
    title: t("ui.search.schemata_title"),
    heading: true,
    loading: false,
    classes: titleClasses,
  });

  if (entries.value.length > 0) {
    total.push(...entries.value);
  } else {
    total.push({
      title: t("ui.search.no_schemata_notes"),
      loading: schemasLoading.value || notesLoading.value,
      heading: true,
      classes: ["py-1"],
    });
  }

  return total;
});

const selectedItem = ref<SearchResult>();
const { href } = useParagraphLink({
  legalTextSlug: () => {
    const item = unref(selectedItem);
    if (item && "paragraph" in item) {
      return item.legal_text.slug;
    }
  },
  paragraphSlug: () => {
    const item = unref(selectedItem);
    if (item && "paragraph" in item) {
      return item.slug;
    }
  },
  entry: () => {
    const item = unref(selectedItem);
    if (item && !("paragraph" in item)) {
      return item;
    }
  },
});

watch(selectedItem, async () => {
  if (!selectedItem.value) return;
  searchInput.value?.blur();
  emit("resultClicked");
  query.value = "";
  await navigate(href.value);
});

const { addTab, toTab } = useTabsMutations();

const openInNewTab = async (para: SearchParagraph) => {
  emit("resultClicked");
  await addTab(toTab(para.legal_text, para));
};

const { openedModals } = useVfm();

const handleKeyDown = (e: KeyboardEvent) => {
  const { key, altKey, ctrlKey, metaKey, target } = e;
  if (
    (target as Node)?.nodeName === "BODY" &&
    [altKey, ctrlKey, metaKey].every((pressed) => !pressed) &&
    (key === "Backspace" || key.match(/^[\d\w§]$/)?.length) &&
    openedModals.length === 0
  ) {
    searchInput.value?.focus();
  }
};

useEventListener("keydown", handleKeyDown);

const { setSearchbarRef } = useSearchbarFocus();
onMounted(() => {
  setSearchbarRef(searchInput.value as HTMLInputElement);
});
</script>

<style scoped>
:deep(em) {
  @apply font-bold not-italic;
}
</style>
