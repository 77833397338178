import type {
  IFieldOfLaw,
  IFieldOfLawWithParents,
} from "../models/IFieldOfLaw";
import { LexmeaApiClient } from "./apiClient";

export class FieldOfLawClient extends LexmeaApiClient {
  fetchAllFieldsOfLaw = (): Promise<IFieldOfLaw[]> => {
    return this.get<IFieldOfLaw[]>("api/fieldOfLaw");
  };

  fetchFieldOfLaw = (id: number): Promise<IFieldOfLawWithParents> => {
    return this.get<IFieldOfLawWithParents>("api/fieldOfLaw/" + id);
  };
}
