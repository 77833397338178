import type { MessageContext } from "vue-i18n";

const typeWithArticle = ({ named, linked }: MessageContext) => {
  const type = named("type");
  const word = linked(`entries.${type}`);
  const article =
    type === "schema"
      ? "das"
      : type === "note"
        ? "die"
        : type === "link"
          ? "diesen"
          : "diese";

  return { article, word };
};

export const entries = {
  schema: "Schema | Schemata",
  note: "Notiz | Notizen",
  mark: "Markierung | Markierungen",
  underline: "Unterstreichung | Unterstreichungen",
  link: "Verweis | Verweise",
  link_chain: "Verweiskette | Verweisketten",
  bookmark: "Lesezeichen | Lesezeichen",
  all: "Alle",
  deletion: {
    abort: "abbrechen",
    delete: "löschen",
    title: ({ named, linked }: MessageContext) => {
      const type = named("type");
      const word = linked(`entries.${type}`);
      return `${word} löschen`;
    },
    subtitle: "Markierung löschen",
    are_you_sure: (ctx: MessageContext) => {
      const { article, word } = typeWithArticle(ctx);
      return `Möchtest du ${article} ${word} wirklich löschen?`;
    },
    restore: (ctx: MessageContext) => {
      const { article, word } = typeWithArticle(ctx);
      const lib = ctx.linked("library");
      const chronic = ctx.linked("bookshelf.tabs.chronic");
      const deleted = ctx.linked("bookshelf.chronic.deleted.heading");
      const relativePronoun =
        article === "das" ? "es" : article === "diesen" ? "ihn" : "sie";
      return `Du kannst ${article} ${word} unter ${lib} > ${chronic} > ${deleted} drei Monate lang wiederherstellen, bevor wir ${relativePronoun} endgültig löschen.`;
    },
  },
};
