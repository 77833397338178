type ValidationResult = { $error: boolean; errors: string[] };
type ValidationRule = (value: any) => ValidationResult;
type ValidationRules = { [key: string]: ValidationRule[] };
type FormErrors = { [key: string]: string };

const required: ValidationRule = (
  value: string | boolean
): ValidationResult => {
  let isValid: boolean;
  if (typeof value === "string") {
    isValid = value.trim() !== "";
  } else {
    isValid = value;
  }
  const errors = isValid ? [] : ["required"];
  return { $error: errors.length > 0, errors };
};

const email: ValidationRule = (value: string): ValidationResult => {
  const errors: string[] = [];
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) errors.push("email");
  return { $error: errors.length > 0, errors };
};

const letters: ValidationRule = (value: string): ValidationResult => {
  const hasLetters = /[a-zA-Z]/.test(value);
  const errors = hasLetters ? [] : ["letters"];
  return { $error: errors.length > 0, errors };
};

const validateField = (
  value: any,
  rules: ValidationRule[]
): ValidationResult => {
  const errors: string[] = [];
  rules.forEach((rule) => {
    const result = rule(value);
    if (result.$error) {
      errors.push(...result.errors);
    }
  });
  return { $error: errors.length > 0, errors };
};

export function useRegisterValidation(form: any) {
  const { t } = useI18n();
  const rules: ValidationRules = {
    email: [required, email],
    password: [required, letters],
    password_confirmation: [required],
    data_privacy_statement_agreed: [required],
  };

  const validateForm = () => {
    const validationResults: any = {};
    for (const field in rules) {
      validationResults[field] = validateField(form[field], rules[field]);
    }
    validationResults.$validate = async () => {
      return Object.keys(validationResults).every(
        (field) => !validationResults[field].$error
      );
    };
    return validationResults;
  };
  // TODO: Backend I18n
  const setErrors = (): FormErrors => {
    const validationResults = validateForm();
    const formErrors: FormErrors = {};

    if (validationResults.email.$error) {
      if (validationResults.email.errors.includes("required")) {
        formErrors["email"] = t("authentication.errors.email_required");
      } else if (validationResults.email.errors.includes("email")) {
        formErrors["email"] = t("authentication.errors.email_invalid");
      }
    }

    if (validationResults.password.$error) {
      if (validationResults.password.errors.includes("required")) {
        formErrors["password"] = t(
          "authentication.errors.password_has_to_be_filled"
        );
      } else if (validationResults.password.errors.includes("letters")) {
        formErrors["password"] = t(
          "authentication.errors.password_needs_letters"
        );
      }
    }

    if (validationResults.password_confirmation.$error) {
      formErrors["password_confirmation"] = t(
        "authentication.errors.password_confirm_has_to_be_filled"
      );
    }

    if (validationResults.data_privacy_statement_agreed.$error) {
      formErrors["data_privacy_statement_agreed"] = t(
        "authentication.errors.terms_and_conditions_has_to_be_accepted"
      );
    }

    return formErrors;
  };

  const isFormCorrect: () => boolean = () => validateForm().$validate();

  return { setErrors, isFormCorrect };
}
