import type { DirectiveBinding, ObjectDirective } from "vue";

interface RippleOptions {
  duration?: number;
  color?: "blue" | "white";
}

const colors = {
  blue: "rgba(5,77,99,0.15)",
  white: "rgba(255, 255, 255, 0.3)",
};

const makeRippleEffectHandler =
  (el: HTMLElement, options: DirectiveBinding<RippleOptions>) =>
  (event: PointerEvent) => {
    el.style.position = "relative";
    el.style.overflow = "hidden";
    el.style.userSelect = "none";

    const ripple = document.createElement("span");

    // Calculate coordinates relative to the element (el) instead of event.target
    const elementCoordinates = el.getBoundingClientRect();
    const offsetY = event.clientY - elementCoordinates.y;
    const offsetX = event.clientX - elementCoordinates.x;

    const color = colors[options.value?.color || "white"] ?? colors["white"];
    const duration = options.value?.duration || 700;

    // Styles for the ripple
    ripple.style.position = "absolute";
    ripple.style.borderRadius = "50%";
    ripple.style.backgroundColor = color;
    ripple.style.transform = "scale(0)";
    ripple.style.opacity = "1";
    ripple.style.transition = `transform ${duration}ms, opacity ${duration}ms`;
    ripple.style.pointerEvents = "none"; // Avoid events on ripple

    // Position and size of the ripple
    const diameter = Math.max(
      elementCoordinates.width,
      elementCoordinates.height
    );

    const radius = diameter / 2;
    ripple.style.width = ripple.style.height = `${diameter}px`;
    ripple.style.left = `${offsetX - radius}px`;
    ripple.style.top = `${offsetY - radius}px`;

    el.appendChild(ripple);

    // Append the ripple to the element (el) and remove after animation
    setTimeout(() => {
      ripple.style.transform = "scale(4)";
      ripple.style.opacity = "0";
      setTimeout(() => ripple.remove(), duration); // Match transition time
    }, 50);
  };

export function useVRipple() {
  const handlers = new Map<
    HTMLElement,
    ReturnType<typeof makeRippleEffectHandler>
  >();

  const vRipple: ObjectDirective<HTMLElement, RippleOptions> = {
    mounted: (el, options) => {
      const handler = makeRippleEffectHandler(el, options);
      handlers.set(el, handler);
      el.addEventListener("pointerdown", handler);
    },
    unmounted: (el) => {
      const handler = handlers.get(el);
      if (handler) {
        el.removeEventListener("pointerdown", handler);
      }
    },
  };

  return { vRipple };
}
