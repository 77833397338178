import { CityClient } from "~/api/city";
import type { ICity } from "~/models/ICity";

// TODO: implement this in elastic search for better performance
export function useCities(cityId: Ref<number | undefined>) {
  const allCities = ref<ICity[]>([]);
  const cities = ref<ICity[]>([]);
  const query = ref("");
  const api = useApiClient(CityClient);

  const fetchCities = async () => {
    allCities.value = await api.fetchCities();
  };

  const performSearch = async () => {
    cities.value = await api.searchCities(query.value);
  };

  const sortedCities = useSorted(cities, (a, b) =>
    a.name.localeCompare(b.name)
  );

  const selectedCity = useArrayFind(
    allCities,
    (city) => city.id === cityId.value
  );

  tryOnMounted(async () => {
    await fetchCities();
  });

  debouncedWatch(
    query,
    async (q) => {
      if (q) {
        await performSearch();
      } else {
        cities.value = allCities.value;
      }
    },
    { debounce: 200 }
  );

  return {
    selectedCity,
    cities: sortedCities,
    query,
  };
}
