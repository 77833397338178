<template>
  <svg v-if="focus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 212">
    <path
      d="m9,38c0-16.02,12.98-29,29-29h136c16.02,0,29,12.98,29,29v84.27l-80.73,80.73H38c-16.02,0-29-12.98-29-29V38Z"
      class="fill-current stroke-current"
      :class="colorClass ? colorClass + '-darker' : 'text-black'"
      stroke-width="18"
    />
    <path
      d="m212,126l-86,86h-7.5l.38-70.25c.07-12.61,10.27-22.81,22.88-22.88l70.25-.38v7.5Z"
      class="fill-current"
      :class="colorClass ? colorClass + '-lighter' : 'text-black'"
    />
  </svg>

  <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 212">
    <path
      d="m9,38c0-16.02,12.98-29,29-29h136c16.02,0,29,12.98,29,29v84.27l-80.73,80.73H38c-16.02,0-29-12.98-29-29V38Z"
      class="stroke-current"
      :class="[
        filled ? 'fill-white' : 'fill-transparent',
        colorClass ? colorClass + '-darker' : 'text-black',
      ]"
      stroke-width="18"
    />
    <path
      d="m212,126l-86,86h-7.5l.38-70.25c.07-12.61,10.27-22.81,22.88-22.88l70.25-.38v7.5Z"
      class="fill-current"
      :class="colorClass ? colorClass + '-darker' : 'text-black'"
    />
  </svg>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    focus?: boolean;
    colorClass?: string;
    filled?: boolean;
  }>(),
  {
    filled: false,
    focus: false,
    colorClass: undefined,
  }
);
</script>
