<template>
  <span
    class="pointer-events-none absolute ml-3 select-none whitespace-nowrap rounded px-1 text-xs font-bold transition-all hover:cursor-text"
    :class="{
      'text-lexmea-pop-darker': focused,
      'text-gray-400': !focused,
      '-mt-2 bg-white': active,
      'mt-3': !active,
    }"
  >
    {{ label }}<span v-if="required">*</span>
  </span>
</template>

<script lang="ts" setup>
interface Props {
  active?: boolean;
  focused?: boolean;
  label: string;
  required?: boolean;
}

withDefaults(defineProps<Props>(), {
  active: false,
  focused: false,
  required: false,
});
</script>
