export const onboardingTour = {
  step_0: {
    title: "Glad you're here!",
    text: "Can we show you the {emphasized} in 1 minute?",
    emphasized: "most important features",
    buttonLeft: "Skip",
    buttonRight: "Start tour",
  },
  step_1: {
    title: "Search",
    text: "Start your work with a {emphasized} for provisions or keywords in @.lower:documents and notes.",
    emphasized: "search",
    buttonLeft: "Back",
    buttonRight: "Next 1/8",
  },
  step_2: {
    title: "Column 1: Overview Boxes",
    text: "Here you will find the {emphasized} relating to the provisions in column 2 and navigate to other provisions or the detailed table of contents.",
    emphasized: "overview boxes",
    emphasized2: "",
    buttonLeft: "Back",
    buttonRight: "Next 2/8",
  },
  step_3: {
    title: "Column 2: Law",
    text: "Here you will always find your {emphasized} and personalize it.",
    emphasized: "legal norm",
    buttonLeft: "Back",
    buttonRight: "Next 3/8",
  },
  step_4: {
    title: "Toolbox",
    text: "{emphasized} your provisions through: @.lower:documents{','} notes, underlinings, highlights, references or bookmarks.",
    emphasized: "Personalize",
    buttonLeft: "Back",
    buttonRight: "Next 4/8",
  },
  step_5: {
    title: "Tabs",
    text: "Open {emphasized} side by side in tabs.",
    emphasized: "multiple norms",
    buttonLeft: "Back",
    buttonRight: "Next 5/8",
  },
  step_6: {
    title: "Column 3: Contents",
    text: `In column 3 you will always find the {emphasized} (@.lower:documents and notes) attached to the provision by LexMea or by you. <div class="text-xs italic pt-3">Tip: When creating documents use the {emphasized2} for headings and boxes (like 'definition boxes' or 'problem boxes').</div>`,
    emphasized: "content",
    emphasized2: "format templates",
    buttonLeft: "Back",
    buttonRight: "Next 6/8",
  },
  step_7: {
    title: "Column 4: Overview Boxes",
    text: "Here you will find the {emphasized} relating to the content in column 3 and navigate to other @.lower:documents or notes.",
    emphasized: "overview boxes",
    emphasized2: "",
    buttonLeft: "Back",
    buttonRight: "Next 7/8",
  },
  step_8: {
    title: "Library",
    text: "Navigate from the workspace to the library for comprehensive {emphasized} of your content and statute books.",
    emphasized: "overviews",
    buttonLeft: "Back",
    buttonRight: "Get started",
  },
};
