<!-- eslint-disable vue/no-v-html -->
<template>
  <ParagraphLink
    class="flex w-full items-center rounded px-4 py-1"
    :legalText="legalTextSlug"
    :legalTextParagraph="paragraphSlug"
    :entry="entry"
  >
    <div class="pl-1 pr-4">
      <ClipboardIcon
        v-if="entry.type === 'schema'"
        class="inline-block h-6 w-6"
        :colorClass="colorFromEntry(entry)"
      />
      <PaperIcon
        v-else-if="entry.type === 'note'"
        class="inline-block h-6 w-6"
        :colorClass="colorFromEntry(entry)"
      />
    </div>
    <div class="flex flex-col gap-1 overflow-x-hidden">
      <div class="truncate text-lg leading-tight">
        <span v-html="entry.title" />
        <span
          v-if="entry.type === 'note'"
          class="ml-2 italic"
          v-text="'(' + noteTextFromColor(entry.color) + ')'"
        />
      </div>
      <div class="truncate text-sm" v-html="entry.text"></div>
      <div class="flex items-center gap-2">
        <AnchorIcon class="h-4 w-4" />
        <div class="block truncate text-xs">
          <span
            v-for="(span, i) in paragraphHighlighted"
            :key="i"
            :class="{ 'font-bold': span.match }"
          >
            {{ span.text }}
          </span>
        </div>
      </div>
    </div>
  </ParagraphLink>
</template>

<script lang="ts" setup>
import type { INote, ISchemaWithText } from "~/models/IUserEntry";

interface Props {
  query: string;
  entry: ISchemaWithText | INote;
}

const props = defineProps<Props>();

const { data: legalText } = useCurrentLegalText();
const {
  query: { data: legalTextParagraph },
} = useCurrentParagraph();

const onlyNavigateToEntry = computed(
  () => props.entry.type === "schema" && legalTextParagraph.value
);

const legalTextSlug = computed(() =>
  onlyNavigateToEntry.value ? legalText.value?.slug : undefined
);

const paragraphSlug = computed(() =>
  onlyNavigateToEntry.value ? legalTextParagraph.value!.slug : undefined
);

const { noteTextFromColor } = useEntryColors();
const { colorFromEntry } = useColorFromEntry();
const { title: paragraphTitle } = useFullParagraphTitle(
  () => props.entry.legal_text_paragraph
);

const { highlighted: paragraphHighlighted } = useHighlighting(
  paragraphTitle,
  toRef(props, "query")
);
</script>
