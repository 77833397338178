import type { IBookmark } from "~/models/ILegalTextParagraph";
import type { ColorPicker } from "~/types/ColorPicker";
import type { BookmarkTab } from "~/models/IBookshelf";
import { LexmeaApiClient } from "./apiClient";

export class BookmarkClient extends LexmeaApiClient {
  createBookmark = ({
    color,
    paragraphId,
  }: {
    color: ColorPicker;
    paragraphId: number;
  }): Promise<IBookmark> => {
    return this.post<IBookmark>("api/bookmark", {
      paragraph_id: paragraphId,
      ...color,
    });
  };

  fetchBookmarks = (): Promise<IBookmark[]> => {
    return this.get<IBookmark[]>("api/bookmark");
  };

  fetchBookmarkTree = (): Promise<BookmarkTab[]> => {
    return this.get<BookmarkTab[]>("api/bookmark/bookmarks");
  };

  updateBookmark = ({
    id,
    color,
  }: {
    id: number;
    color: ColorPicker;
  }): Promise<IBookmark> => {
    return this.put<IBookmark>("api/bookmark/" + id, color);
  };

  deleteBookmark = async (id: number) => {
    await this.delete("api/bookmark/" + id);
  };
}
