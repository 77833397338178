export const onboardingTour = {
  step_0: {
    title: "Schön, dass du hier bist!",
    text: "Dürfen wir dir in 1 Minute die {emphasized} zeigen?",
    emphasized: "wichtigsten Funktionen",
    buttonLeft: "Überspringen",
    buttonRight: "Tour starten",
  },
  step_1: {
    title: "Suche",
    text: "Beginne mit der {emphasized} nach Gesetzesnormen oder nach Stichworten in Schemata / Notizen.",
    emphasized: "Suche",
    buttonLeft: "Zurück",
    buttonRight: "Weiter 1/8",
  },
  step_2: {
    title: "Spalte 1: Übersichtsboxen",
    text: "Hier findest du die {emphasized} in Spalte 2 gehörigen {emphasized2} und navigierst zu anderen Normen oder zum ausführlichen Inhaltsverzeichnis.",
    emphasized: "zum Gesetz",
    emphasized2: "Übersichtsboxen",
    buttonLeft: "Zurück",
    buttonRight: "Weiter 2/8",
  },
  step_3: {
    title: "Spalte 2: Gesetz",
    text: "Hier findest du stets deine {emphasized} und personalisierst sie.",
    emphasized: "Gesetzesnorm",
    buttonLeft: "Zurück",
    buttonRight: "Weiter 3/8",
  },
  step_4: {
    title: "Toolbox",
    text: "{emphasized} dein Gesetzbuch durch: Schemata, Notizen, Unterstreichungen, Markierungen, Verweise, Bookmarks.",
    emphasized: "Personalisiere",
    buttonLeft: "Zurück",
    buttonRight: "Weiter 4/8",
  },
  step_5: {
    title: "Tabs",
    text: "Öffne {emphasized} in Tabs nebeneinander.",
    emphasized: "mehrere Normen",
    buttonLeft: "Zurück",
    buttonRight: "Weiter 5/8",
  },
  step_6: {
    title: "Spalte 3: Inhalte",
    text: 'In Spalte 3 findest du stets die durch LexMea oder durch dich an die Norm gehefteten {emphasized} (Schemata und Notizen). <div class="text-xs italic pt-3">Tipp: Nutze beim Erstellen von Schemata die {emphasized2} für Überschriften und Boxen wie Definitionen oder Aufbauhinweise.</div>',
    emphasized: "Inhalte",
    emphasized2: "Formatvorlagen",
    buttonLeft: "Zurück",
    buttonRight: "Weiter 6/8",
  },
  step_7: {
    title: "Spalte 4: Übersichtsboxen",
    text: "Hier findest du die zu den {emphasized} in Spalte 3 gehörigen {emphasized2} und navigierst zu anderen Schemata oder Notizen.",
    emphasized: "Inhalten",
    emphasized2: "Übersichtsboxen",
    buttonLeft: "Zurück",
    buttonRight: "Weiter 7/8",
  },
  step_8: {
    title: "Bibliothek",
    text: "Navigiere vom Arbeitsbereich zur Bibliothek für eine umfassende {emphasized} über deine Personalisierungen und Gesetze.",
    emphasized: "Gesamtübersicht",
    buttonLeft: "Zurück",
    buttonRight: "Loslegen",
  },
};
