export const team_page = {
  hero_section: {
    main_title: "Our Team",
    slogan: "Meet the LexMea-Team!",
  },
  team_members: [
    {
      defaultImageSource: "team/michael-benjamin-strecker.webp",
      alternativeImageSource: "team/michi.webp",
      name: "Michael B. Strecker",
      position: "Founder, CEO",
      abstract:
        "I am working to ensure that LexMea contains all the features you need for your daily work.",
      detailsText:
        "“I used to play soccer in my village - but I was so bad that I had to be the goalkeeper. Since then, I have focused more on intellectual activities.”",
      detailsBulletPointList: [
        "Studied law at LMU Munich (Dipl.-Jur. Univ., with distinction) and political science at HfP Munich (Dipl. sc. pol. Univ., top 15%)",
        "Research assistant at the Chair of Public Law and Law of Digitalization, Prof. Dr. T. Wischmeyer (since 2020)",
        "Policy development officer for digital policy at a federal party headquarters (2020-2021)",
        "Lawyer with the first state examination (“Erstgeprüfter”) at the Federal Ministry of Justice and in the office of the Data Ethics Commission of the Federal Government (2018-2020)",
        "Teaching activities at LMU Munich, University of Bielefeld, and Humboldt University of Berlin",
      ],
    },
    {
      defaultImageSource: "team/tobi-main.webp",
      alternativeImageSource: "team/toby.webp",
      name: "Tobias A. Strecker",
      position: "Founder, CEO",
      abstract:
        "I am working to make LexMea easier for you to use in your legal work and to help you better manage your legal knowledge.",
      detailsText:
        '"In my youth, I tuned mopeds and thus gained my first insights into the justice system."',
      detailsBulletPointList: [
        "Studied law and economics at Humboldt University of Berlin and University of Tübingen",
        "Lecturer at Humboldt University of Berlin (since 2012)",
        "Legal clerkship at the Berlin Court of Appeal with stations in Stuttgart and New York (2012-2014)",
        "Lecturer at Hokkaido University Japan (2015-2017)",
        "Managing Director of the Research Institute for Lawyers' Law at Humboldt University of Berlin (2017-2019)",
        "Lawyer at Bodenheimer (since 2018)",
        "Lecturer and Programme Director English Law & German Law LL.B. & LL.M. at King’s College London (since 2019)",
      ],
    },
    {
      defaultImageSource: "team/chris-robin-ennen.webp",
      alternativeImageSource: "team/chris.webp",
      name: "Chris-Robin Ennen",
      position: "Founder, CTO",
      abstract:
        "As a full-stack developer and tech lead, I work on everything related to the website and manage our internal services.",
      detailsText:
        "“I'll take care of it right away, just these few lines of code ...”",
      detailsBulletPointList: [
        "Trained as an IT specialist at Robert Bosch GmbH",
        "Studied media informatics at HdM in Stuttgart",
        "Scholar at the House of the Academic Association Hütte",
      ],
    },
    {
      defaultImageSource: "team/kian-ashnad.webp",
      alternativeImageSource: "team/kian.gif",
      name: "Kian Ashnad",
      position: "Tech Lead",
      abstract:
        "As a tech lead, I coordinate the work of the development team and ensure that LexMea continuously develops new features.",
      detailsText:
        "“I have many hobbies, like programming, coding, and software development.”",
      detailsBulletPointList: [
        "Work experience in Germany, Switzerland, and the Middle East",
        "More than 7 years of experience in software development",
        "Studied technical computer science",
      ],
    },
    {
      defaultImageSource: "team/clemens-wendt.webp",
      alternativeImageSource: "team/clemens.webp",
      name: "Clemens Wendt",
      position: "Content Manager",
      abstract:
        "As a content manager, I work to provide you with perfect but at the same time as understandable content as possible.",
      detailsText:
        "“Since my time at Waldorf school, my favorite hobby has been the anthroposophical movement art eurythmy.”",
      detailsBulletPointList: [
        "Double degree in law at Humboldt University of Berlin and King's College London",
        "Student assistant at one of the leading law firms for international business law",
      ],
    },
    {
      defaultImageSource: "team/elahe.webp",
      alternativeImageSource: "team/elahe.gif",
      name: "Elahe Kamandi",
      position: "Marketing Manager",
      abstract:
        "As a marketing manager, I convince new customers of the benefits that LexMea offers.",
      detailsText:
        "“In my free time, I weave carpets. We have more carpets than floor.”",
      detailsBulletPointList: [
        "Specialist in data-driven marketing and business intelligence",
        "Studied industrial engineering",
      ],
    },
    {
      defaultImageSource: "team/alina.webp",
      alternativeImageSource: "team/alina-gif.webp",
      name: "Alina Hamm",
      position: "Accountant",
      abstract:
        "As a financial manager, I work on the systematic recording, documentation, and monitoring of cash flows at LexMea.",
      detailsText:
        "“Yoga increases strength, flexibility, endurance, and mental clarity! - But then during the yoga class ...”",
      detailsBulletPointList: [
        "Trained as an industrial clerk and worked in the finance department of a medium-sized company",
        "Double degree in economics and mathematics at Carl von Ossietzky University of Oldenburg",
        "Working student at the Fraunhofer Heinrich-Hertz Institute",
      ],
    },
    {
      defaultImageSource: "team/robert.webp",
      alternativeImageSource: "team/robert.gif",
      name: "Robert Maina",
      position: "Developer",
      abstract:
        "As a frontend developer, I continuously improve and extend the features of the web app for you.",
      detailsText:
        "“Even though I come from Kenya, marathon running is hell. So it was clear: software development.”",
      detailsBulletPointList: [
        "Software developer",
        "Studied computer science",
        "Frontend nerd",
      ],
    },
    {
      defaultImageSource: "team/sebastian.webp",
      alternativeImageSource: "team/sebastian.gif",
      name: "Sebastian Jans",
      position: "Developer & Designer",
      abstract:
        "As a designer and front-end developer, I work to ensure that our platform is well-structured and looks good.",
      detailsText: "“This button should be a few pixels bigger.”",
      detailsBulletPointList: [
        "Bachelor of Science in Materials Science at the University of Stuttgart",
        "Master of Engineering in Design Engineering at the Universitat Politecnica de Valencia",
        "Co-Founder at Baukunst, a startup from Valencia that creates digital platforms with a focus on social, economic, and sustainable impact",
        "Co-Founder and Tech Lead at Vivus, a startup that creates sustainable everyday products",
      ],
    },
    {
      defaultImageSource: "team/carolin.webp",
      alternativeImageSource: "team/carolin.gif",
      name: "Carolin Künzel",
      position: "",
      abstract:
        "I work on networking LexMea and creating the best user experience for all users with different interests.",
      detailsText:
        "“I love martial arts and handball – usually right in the middle instead of just being there.”",
      detailsBulletPointList: [
        "Studied law at the University of Trier (Dipl. Jur.) (2015-2021)",
        "Founder and chairwoman of Legal Tech Aachen e.V.",
        "Guest student at the University of Saarland for IT law and legal informatics (2022-2023)",
        "Guest lecturer for Legal Tech at FH Aachen (since 2022)",
        "Legal clerkship at the Regional Court of Aachen (2022-2024) with an administrative station at the State Ministry for Home Affairs, Municipal Affairs, Digital Affairs, and Construction (Department: Digital Affairs) and an elective station at Fieldfisher_X (company for technology-supported legal services)",
      ],
    },
    {
      defaultImageSource: "team/new.webp",
      alternativeImageSource: "team/new.gif",
      name: "Join the team!",
      position: "Job / Internship",
      abstract:
        "Complete your mandatory internship or legal clerkship at LexMea or support our team permanently.",
      detailsText:
        "We look forward to your informal application with a short resume by email to:<br/><u>start@lexmea.de</u>",
      detailsListTitle: "Become part of our team as (m/f/d):",
      detailsBulletPointList: [
        "Mandatory intern",
        "Working student",
        "Legal clerk",
        "Freelancer",
        "Permanent employee",
      ],
    },
  ],
};
