import type { DeskTab, UserDeskTab } from "~/models/ITab";
import { LexmeaApiClient } from "./apiClient";

export class TabsClient extends LexmeaApiClient {
  #fromUserDeskTab(tab: UserDeskTab): DeskTab {
    return {
      ...tab,
      legalText: tab.legal_text,
      active: !!tab.active,
    };
  }

  syncTabs = async (tabs: DeskTab[]) => {
    const userTabs = tabs.map((tab) => ({
      ...tab,
      legal_text: tab.legalText,
      paragraph: tab.paragraph ?? null,
    }));
    const data = await this.post<UserDeskTab[]>("api/tabs", {
      tabs: userTabs,
    });

    return data.map(this.#fromUserDeskTab);
  };

  getUserTabs = async () => {
    const data = await this.get<UserDeskTab[]>("api/tabs");
    return data.map(this.#fromUserDeskTab);
  };
}
