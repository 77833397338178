<template>
  <form v-if="currentPage === 'confirmCode'" @submit.prevent="checkCode">
    <div class="flex flex-col gap-4">
      <i18n-t keypath="authentication.registration.confirmEmail" tag="p">
        <template #email>
          <strong>{{ registerData.email }}</strong>
        </template>
      </i18n-t>
      <LexmeaVerificationInput
        ref="codeInput"
        :error="codeError"
        @code:updated="codeUpdated"
        @enterPressed="checkCode"
      />
      <p>{{ $t("authentication.registration.codeIsValidFor") }}</p>
    </div>
    <div class="flex justify-between">
      <LexmeaButton
        :loading="loading"
        design="link"
        type="button"
        :disabled="isActive"
        @click="resendCode"
      >
        {{ $t("authentication.registration.resendCode") }}
        <Transition name="fade">
          <span v-if="isActive">({{ count }})</span>
        </Transition>
      </LexmeaButton>
      <LexmeaButton
        :loading="loading"
        :disabled="code.length < 5"
        type="submit"
      >
        {{ $t("authentication.registration.continue") }}
      </LexmeaButton>
    </div>
  </form>
  <form
    v-else-if="currentPage === 'userName'"
    class="flex flex-col gap-7"
    @submit.prevent="updateName"
  >
    <p class="text-center font-bold">
      {{ $t("authentication.registration.chooseUsername") }}
    </p>
    <LexmeaInput
      v-model="nameForm.name"
      name="name"
      :error="nameError"
      :label="$t('authentication.username')"
      required
    />
    <div class="flex justify-end">
      <LexmeaButton
        :loading="loading"
        :disabled="nameForm.name.length < 3"
        type="submit"
      >
        {{ $t("authentication.registration.continue") }}
      </LexmeaButton>
    </div>
  </form>
  <form v-else class="flex flex-col gap-5" @submit.prevent="submitRegister">
    <p v-if="currentPage === 'additionalData'" class="text-center font-bold">
      {{ $t("authentication.registration.almostDone") }}
    </p>

    <p v-else class="text-center">
      <i18n-t keypath="authentication.registration.registerFreeNow" tag="span">
        <template #freeNow>
          <strong>{{ $t("authentication.registration.freeNow") }}</strong>
        </template>
      </i18n-t>
    </p>
    <AdditionalForm
      v-if="currentPage === 'additionalData'"
      v-model="additionalForm"
      :errors="errors"
    />
    <RequiredForm v-else v-model="requiredForm" :errors="errors" />

    <!-- should not be filled by user -->
    <input type="hidden" class="law_firm_from" value="" />

    <div class="flex justify-between py-4">
      <LexmeaButton
        v-if="currentPage === 'additionalData'"
        design="link"
        type="button"
        @click="currentPage = 'userName'"
      >
        {{ $t("authentication.registration.back") }}
      </LexmeaButton>
      <LexmeaButton
        v-else
        type="button"
        design="link"
        @click="$emit('switchTab')"
      >
        {{ $t("authentication.toLogin") }}
      </LexmeaButton>
      <LexmeaButton
        v-if="currentPage === 'additionalData'"
        type="button"
        :loading="loading"
        @click="submitAddionalData"
      >
        {{ $t("authentication.registration.complete") }}
      </LexmeaButton>
      <LexmeaButton v-else :loading="loading" type="submit">
        {{ $t("authentication.register") }}
      </LexmeaButton>
    </div>
  </form>
</template>

<script setup lang="ts">
import type { IRegisterForm, IUser } from "~/models/IUser";
import type LexmeaVerificationInput from "../input/LexmeaVerificationInput.vue";
import { navigate } from "vike/client/router";
import { ApiClientError } from "~/api/apiClient";

const emit = defineEmits<{
  close: [];
  "update:closingDisabled": [isDisabled: boolean];
  switchTab: [];
}>();

const { register, validateEmail, resendEmailCode } = useUserStore();

const currentPage = ref<
  "requiredData" | "confirmCode" | "userName" | "additionalData"
>("requiredData");

watchEffect(() =>
  emit("update:closingDisabled", currentPage.value !== "requiredData")
);

const requiredForm = reactive({
  email: "",
  password: "",
  password_confirmation: "",
  data_privacy_statement_agreed: undefined,
  newsletter_accepted: false,
});

const { isFormCorrect, setErrors } = useRegisterValidation(requiredForm);

const emailResentFromLogin = (email: string) => {
  currentPage.value = "confirmCode";
  requiredForm.email = email;
};
defineExpose({ emailResentFromLogin });

const codeInput = ref<InstanceType<typeof LexmeaVerificationInput> | null>(
  null
);
const code = ref("");
const codeUpdated = (updatedCode: string) => {
  code.value = updatedCode;
  codeError.value = "";
};

const registerData = computed<IRegisterForm>(() => ({
  ...requiredForm,
}));

const nameForm = reactive({
  name: "",
});
const nameData = computed<Partial<IUser>>(() => ({
  ...nameForm,
}));

const additionalForm = reactive({
  occupation_id: undefined,
  city_id: undefined,
  university_id: undefined,
});
const additionalData = computed<Partial<IUser>>(() => ({
  ...additionalForm,
}));

const loading = ref(false);
const errors = ref<Partial<Record<keyof IRegisterForm, string>>>({});
const codeError = ref("");
const nameError = ref("");

const submitRegister = async () => {
  const honeypotValue = <HTMLInputElement>(
    document.querySelector(".law_firm_from")
  );
  if (honeypotValue.value !== "") {
    // Form submission is spam
    return;
  }

  errors.value = {};

  if (!isFormCorrect()) {
    return (errors.value = setErrors());
  }

  loading.value = true;
  try {
    await register(registerData.value);
    currentPage.value = "confirmCode";
  } catch (e) {
    if (e instanceof ApiClientError) {
      const err = e.errors;
      if (err) {
        errors.value = err;
      }
    }
  }
  loading.value = false;
};

const checkCode = async () => {
  codeError.value = "";
  loading.value = true;
  codeInput.value?.reset();
  try {
    const suggestedName = await validateEmail({
      email: requiredForm.email,
      code: code.value,
    });

    currentPage.value = "userName";
    nameForm.name = suggestedName;

    // Remove Query Params & Redirect
    const url = new URL(window.location.href);
    url.searchParams.delete("email");
    url.searchParams.delete("verify_email");
    await navigate(url.pathname, {
      overwriteLastHistoryEntry: true,
    });
  } catch (e) {
    if (e instanceof ApiClientError) {
      codeError.value = "Ungültiger Code";
    }
  }
  loading.value = false;
};

const { warning } = useToast();

const { isActive, count, reset, pause, resume } = useTimeCounter(59, 0, 59);

const resendCode = async () => {
  codeError.value = "";
  resume();

  try {
    await resendEmailCode({ email: requiredForm.email });
  } catch (_error) {
    pause();
    reset();
  }

  warning(
    `Wir haben dir erneut eine E - Mail mit einem Verifizierungscode ${requiredForm.email} gesendet.Bitte schaue auch in deinem Spam - Ordner nach.`
  );
};

const userStore = useUserStore();
const updateName = async () => {
  loading.value = true;
  nameError.value = "";
  try {
    await userStore.updateUser(nameData.value);
    currentPage.value = "additionalData";
  } catch (e) {
    if (e instanceof ApiClientError) {
      const err = e.errors?.name;
      if (typeof err === "string") {
        nameError.value = err;
      }
    }
  }
  loading.value = false;
};

const submitAddionalData = async () => {
  await userStore.updateUser(additionalData.value);
  emit("close");
  userStore.importLocalEntries = true;
};

onMounted(() => {
  userStore.importLocalEntries = false;
});
</script>
