import type { MaybeComputedElementRef } from "@vueuse/core";

/**
 * Sync a related element's position
 * @param el The element to sync the scroll position of
 * @param relatedEl The related element to sync the scroll position to
 */
export function useVScrollSync(relatedEl: MaybeComputedElementRef) {
  const vScrollSync = (el: HTMLElement) => {
    const { y, height } = useElementBounding(relatedEl);

    // Cache the element to prevent unrefing it in the watchEffect
    const unrefferedEl = computed(() => unrefElement(el));

    watchEffect(() => {
      const rel = unrefferedEl.value;
      if (rel && height.value) {
        rel.style.top = `${y.value + height.value}px`;
      }
    });
  };

  return { vScrollSync };
}
