<template>
  <div class="flex items-center">
    <img v-if="props.isBlue" v-bind="$attrs" :src="logoBlueIcon" alt="LexMea" />
    <img v-else v-bind="$attrs" :src="logoIcon" alt="LexMea" />
  </div>
</template>

<script setup lang="ts">
import logoBlueIcon from "~/assets/logo-blue.svg";
import logoIcon from "~/assets/logo.svg";

const props = withDefaults(
  defineProps<{
    isBlue?: boolean;
  }>(),
  {
    isBlue: false,
  }
);
</script>
