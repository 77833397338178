import { HTTPError, type Input, type KyInstance, type Options } from "ky";

export type ApiClient = KyInstance;

type LaravelError = {
  message: string;
  errors?: Record<string, unknown>;
};

export class ApiClientError extends Error {
  constructor(
    message: string,
    public errors?: Record<string, unknown>,
    options?: ErrorOptions,
    public response?: Response,
    public request?: Request
  ) {
    super(message, options);
  }
}

export abstract class LexmeaApiClient {
  readonly #client: ApiClient;

  constructor(client: ApiClient) {
    if (!client) {
      const err = new Error("No client provided");
      console.error("No client provided: ", err.stack);
      throw err;
    }

    this.#client = client;
  }

  head = (url: Input, options?: Options) => {
    return this.#client.head(url, options);
  };

  get = async <T>(url: Input, options?: Options) => {
    const res = await this.#wrapClientMethod(this.#client.get(url, options));
    try {
      const json = await res.json<T>();
      return json;
    } catch (error) {
      if (error instanceof SyntaxError) {
        console.error("Error parsing JSON response", error);
        console.error(`Request URL: ${res.url}`);
        console.error(`Body: ${await res.text()}`);
      }
      throw error;
    }
  };

  post = <T>(
    url: Input,
    json?: Options["json"],
    options?: Omit<Options, "json">
  ) => {
    return this.#wrapClientMethod(
      this.#client.post(url, { json, ...options }).json<T>()
    );
  };

  postBlob = (url: Input, json?: Options["json"], options?: Options) => {
    return this.#wrapClientMethod(
      this.#client.post(url, { json, ...options }).blob()
    );
  };

  postForm = <T>(
    url: Input,
    data: Record<string, any>,
    putWorkaround: boolean = false,
    options?: Options
  ) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));
    if (putWorkaround) {
      formData.append("_method", "PUT");
    }
    return this.#wrapClientMethod(
      this.#client.post(url, { body: formData, ...options }).json<T>()
    );
  };

  put = <T>(
    url: Input,
    json: Options["json"],
    options?: Omit<Options, "json">
  ) => {
    return this.#wrapClientMethod(
      this.#client.put(url, { json, ...options }).json<T>()
    );
  };

  patch = <T>(
    url: Input,
    json: Options["json"],
    options?: Omit<Options, "json">
  ) => {
    return this.#wrapClientMethod(
      this.#client.patch(url, { json, ...options }).json<T>()
    );
  };

  delete = <T = unknown>(url: Input, options?: Options) => {
    return this.#wrapClientMethod(this.#client.delete(url, options).json<T>());
  };

  #wrapClientMethod = async <T>(response: Promise<T>) => {
    try {
      return await response;
    } catch (error) {
      if (error instanceof HTTPError) {
        const errorResponse = await error.response.json<LaravelError>();
        throw new ApiClientError(
          errorResponse.message,
          errorResponse.errors,
          {},
          error.response,
          error.request
        );
      }
      throw error;
    }
  };
}
