<script setup lang="ts">
import { type IssueReport, IssueReportClient } from "~/api/issueReport";
import { ApiClientError } from "~/api/apiClient";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const emit = defineEmits<{
  close: [];
}>();

const issueTypes = computed(() => [
  { label: t("feedback_form.types_of_feedback.general"), value: 1 },
  { label: t("feedback_form.types_of_feedback.functions"), value: 2 },
  { label: t("feedback_form.types_of_feedback.design"), value: 3 },
  { label: t("feedback_form.types_of_feedback.contents"), value: 4 },
  { label: t("feedback_form.types_of_feedback.missing_feature"), value: 5 },
]);

type IssueReportForm = {
  type: number;
  description: string;
  file?: File | undefined;
};

type Bogus = {
  carModel: string | undefined;
  houseWallMaterial: string | undefined;
};

const issueReport = ref<IssueReportForm & Bogus>({
  type: 1,
  description: "",
  file: undefined,

  carModel: undefined,
  houseWallMaterial: undefined,
});

const pageContext = usePageContext();
const currentPath = toRef(pageContext, "urlPathname");

const errors = ref<Partial<Record<keyof IssueReportForm, string[]>>>({});

const toast = useToast();

const issueReportClient = useApiClient(IssueReportClient);

const sendReport = async () => {
  errors.value = {};

  if (issueReport.value.carModel || issueReport.value.houseWallMaterial) {
    emit("close");
    toast.success(t("feedback_form.thank_you_for_feedback"));
    return;
  }

  const reportType = issueTypes.value.find(
    (t) => t.value === issueReport.value.type
  )?.label;

  if (!reportType) {
    return;
  }

  const data: IssueReport = {
    type: reportType,
    description: issueReport.value.description,
    url: currentPath.value,
  };

  if (issueReport.value.file) {
    data.file = issueReport.value.file;
  }

  try {
    await issueReportClient.postIssueReport(data);
    emit("close");
    toast.success(t("feedback_form.thank_you_for_feedback"));
    issueReport.value = {
      type: 1,
      description: "",
      file: undefined,
      houseWallMaterial: issueReport.value.houseWallMaterial,
      carModel: issueReport.value.carModel,
    };
  } catch (e) {
    if (e instanceof ApiClientError) {
      const err = e.errors;
      if (err) {
        errors.value = err;
      } else {
        toast.error(e.message);
      }
    }
  }
};
</script>

<template>
  <form enctype="multipart/form-data" @submit.prevent="sendReport">
    <LexmeaSelect
      v-model="issueReport.type"
      class="mb-4"
      :label="$t('feedback_form.types_of_feedback.label')"
      :error="errors.type"
      :required="true"
      :options="issueTypes"
    />
    <LexmeaTextarea
      v-model="issueReport.description"
      class="mb-4"
      name="description"
      :label="$t('feedback_form.modal.description')"
      :maxChars="255"
      :error="errors.description"
      rows="5"
      :required="true"
    />
    <LexmeaFile
      v-model:file="issueReport.file"
      v-model:error="errors.file"
      :label="$t('feedback_form.modal.add_screenshot')"
      :typeRestrictions="['jpeg', 'png']"
      accept="image/jpeg, image/png"
    />

    <label class="hidden" for="housewallmaterial"
      >Your House Wall Material:</label
    >
    <input
      id="housewallmaterial"
      v-model="issueReport.houseWallMaterial"
      class="hidden"
      type="text"
      name="houseWallMaterial"
      tabindex="-1"
      autocomplete="off"
    />

    <label class="hidden" for="carmodel">Your car model:</label>
    <input
      id="carmodel"
      v-model="issueReport.carModel"
      class="hidden"
      type="text"
      name="carModel"
      tabindex="-1"
      autocomplete="off"
    />

    <LexmeaButton type="submit" class="ml-auto w-[200px]">
      Senden
    </LexmeaButton>
  </form>
</template>
