export const data_protection = {
  title: "Privacy Notice",
  subtitle: "GDPR and beyond!",
  intro_container: {
    title: "The Essentials in Brief",
    first_paragraph:
      "Although you may not see it, at LexMea, the {trustworthy_policy} arising from your use of our services is as integral to our service as the high-quality visible online content we offer.",
    trustworthy_policy:
      "secure, trustworthy, and respectful processing of the data",
    second_paragraph:
      "To adequately protect the security of your data during transmission, we use methods based on the {current_technology} (e.g., DDoS protection, SQL injection protection, anti-CSRF tokens…).",
    current_technology:
      "current state-of-the-art in encryption (e.g., SSL/TLS) and secure technical systems",
    third_paragraph:
      "To guarantee the protection of your personal data, we also go beyond {high_requirements} in many areas. You can find these areas marked by our LexMea data protection shield. Here is an overview:",
    high_requirements:
      "the already high European data protection standard of the GDPR",
    firstSectionItems: [
      {
        title: "Full Control",
        text: "Always have full control over your data through our {emphasized}, where you can manage your consents and access centrally compiled links to exercise your rights.",
        emphasized: "Data Protection Cockpit",
      },
      {
        title: "No Sale of Your Data",
        text: "We {emphasized} When we show you advertisements, our advertising partners do not receive any information about you. Instead we curate it ourselves (e.g., based on the opened law book) on our own European servers.",
        emphasized: "never sell your data.",
      },
      {
        title: "Only European Servers",
        text: "The data you enter is hosted by us, even if this involves additional costs for us, on European servers. We have also chosen exclusively European server locations for our integrations (e.g., for IT security or analytics).",
      },
      {
        title: "No Google Analytics or Facebook/Meta-Pixel",
        text: "We do not use major US services like Google Analytics or the Facebook/Meta-Pixel.",
      },
      {
        title: "More Rights",
        text: "We {emphasized} when exercising your rights (e.g., right to object) and respect your decision in any case, without using legal loopholes. Privacy Notice for lexmea.de",
        emphasized: "do not require bureaucratic justifications",
      },
    ],
  },
  content: {
    section_0: {
      title: "Privacy Notice for lexmea.de",
      content: `This privacy notice serves to inform users about the type, scope, and purposes of the collection and use of personal data in the context of using lexmea.de.`,
    },
    section_1: [
      {
        title: "1. Name and Contact Details of the Responsible Party",
        paragraphs: [
          "LexMea GmbH",
          "Postal address: Leipziger Str. 44, c/o Michael B. Strecker, 10117 Berlin",
          "Email:",
        ],
      },
    ],
    section_2: {
      title: "2. Contact of the Data Protection Officer",
    },
    section_3: {
      title: "3. Your Rights and How to Exercise Them Easily",
      content:
        "Every data subject has the following rights, which can be exercised at any time informally – e.g. by sending an email to {link}:",
      subsections: [
        {
          title: "a) Right to Withdraw Consent (Art. 7(3) GDPR)",
          content: `Each data subject has the right to withdraw a given consent at any time without affecting the lawfulness of the processing based on consent before its withdrawal if the data processing is based on consent under Art. 6(1)(a) or Art. 9(2)(a) GDPR.`,
          // notice: `In this case, we will not rely on the possibility of invoking other legal bases of Art. 6 I GDPR for the processing of personal data, but will delete the data immediately.`,
        },
        {
          title: "b) Right of Access and Right to Obtain a Copy (Art. 15 GDPR)",
          content: `Each data subject has the right to obtain access to their personal data, including information on the purposes of the processing and the recipients to whom the personal data have been disclosed. This also includes the right to obtain a copy of their personal data.`,
        },
        {
          title: "c) Right to Restriction of Processing (Art. 18 GDPR)",
          content: `Each data subject has the right to request the restriction of processing under the conditions set out in Art. 18 GDPR.`,
        },
        {
          title: "d) Right to Erasure and “to be Forgotten” (Art. 17 GDPR)",
          content: `Each data subject has the right to have their personal data erased without undue delay.`,
        },
        {
          title: "e) Right to Restriction of Processing (Art. 18 GDPR)",
          content: `Each data subject has the right to request the restriction of the processing of their personal data under the conditions of Art. 18 GDPR.`,
        },
        {
          title: "f) Right to Data Portability (Art. 20 GDPR)",
          content: `Each data subject has the right to data portability under the conditions of Art. 20 GDPR.`,
        },
        {
          title: "g) Right to Object to Processing (Art. 21 GDPR)",
          content: `Each data subject has the right to object to the processing of their personal data, including for direct marketing and profiling purposes.`,
          notice: `In this case, we will no longer process the personal data and will not require further requirements such as the need to assert a "special situation" of the data subject.`,
        },
        {
          title:
            "h) Right to Lodge a Complaint with a Supervisory Authority (Art. 77 GDPR)",
          content: `Every data subject has the right to lodge a complaint with a supervisory authority, without prejudice to any other administrative or judicial remedy. The supervisory authority responsible for LexMea is:`,
        },
        {
          title:
            "Berliner Beauftragte für Datenschutz und Informationsfreiheit (Berlin Commissioner for Data Protection and Freedom of Information)",
          content: [
            "Friedrichstr. 219, 10969 Berlin,",
            "Visitor entrance: Puttkamerstr. 16 – 18 (5th floor)",
            "Phone: +49 30 13889-0, Fax: +49 30 2155050,",
            "Email: {link}",
          ],
        },
      ],
    },

    section_4: {
      title:
        "4. What personal data we process for which purposes on which legal basis",
      subsections: [
        {
          title: "a) Personalization of your statute book",
          content: [
            {
              title: "aa) Personalization of your statute book – Not logged in",
              content: `When using LexMea, you personalize your online statute book yourself - for example, by
creating notes, references, highlights, etc. - and manage your knowledge. To enable you
to use our service as extensively as possible without any entry barriers, you can make
such personalisations even without registration. These are then not stored on our
servers, but only in the local storage of your browser (local storage). Only when you
register or log in with an existing account, this information is transferred to us and
loaded into your account. The legal basis for this processing is Art. 6(1)(a) GDPR
(fulfillment of a contract) in conjunction with § 25(2) No. 2 TDDDG.`,
            },
            {
              title: "bb) Personalization of your statute book - Logged in",
              content: [
                "When you are logged in, your profile data (especially email address, username, and payment data for additional booked features), the data you enter when using our product (e.g., notes), and the metadata (such as the date when a note was created - to sort them accordingly in your library) are securely stored on European servers.",
                "Your content and personalisations are generally not displayed to other users unless you actively use the content-sharing function. You can individually manage which content should be shared with which people. The legal basis for this processing is Art. 6(1)(a) GDPR (fulfillment of a contract).",
              ],
            },
          ],
        },
        {
          title: "b) Use and displayed content",
          content:
            "We collect how you use our product, e.g., which norms you view or edit, to tailor our services and the content we display to your needs, thus sparing you from uninteresting offers.",
          notice:
            "When personalized advertising, such as legal job advertisements, is displayed to you, our advertising partners do not receive any information about you, but we curate it ourselves (e.g., based on the opened law book) on our own European servers.",
        },
        {
          title:
            "c) Offering and improving of our services and protection against attacks",
          content: [
            {
              title:
                "aa) No tracking and advertising cookies, no third-party cookies",
              content:
                "Many websites store cookies from third-party companies (so-called third-party cookies) or tracking and advertising cookies on your device, especially to evaluate user behavior or display advertising – we do not!",
              notice:
                "We do not use Google Analytics, Facebook/Meta-Pixel and also refrain from setting tracking and advertising cookies as well as third-party cookies on your device to ensure a particularly high level of data protection.",
            },
            {
              title: "bb) Necessary cookies",
              content:
                "We may only use cookies that are strictly necessary for the function of our site (necessary cookies) or for functions that you explicitly request (functional cookies):",
              list: [
                "Ensuring information security (Anti-CSRF token)",
                "Logging into LexMea with your personal account and authenticating against our servers to retrieve your personal content",
              ],
              sublist: [
                "When you log in, a cookie is stored that keeps you logged in for the following days (session cookie) without requiring you to enter your data again.",
                "If you optionally click on 'Stay logged in' when logging in, a second cookie is stored that keeps you logged in for the following months (remember cookie).",
              ],
            },
            {
              title: "cc) Website improvement without Google Analytics",
              content: `To make our services more user-friendly and effective for you, we use the open-source
analysis tool PostHog. The data collected is hosted on European servers and not
transferred to the USA. Personal user data is only used internally to improve our features
and never passed on to advertising partners, let alone sold. When personalized
advertising, such as legal job advertisements, is displayed to you, our advertising
partners do not receive any information about you, but we curate it ourselves (e.g.,
based on the opened law book) on our own European servers. The legal bases for these
processes are Art. 6(1)(a) GDPR (fulfillment of a contract) and Art. 6(1)(f) GDPR
(legitimate interests).`,
            },
            {
              title: "dd) Log files",
              content: `Your browser automatically transmits information when visiting LexMea – just like when
visiting any other website – which is stored in a log file. This information includes, in
particular: used web browser; operating system; the previously visited page (referrer); for
visitors coming from a search engine, the search terms; date, time, time zone; content
and scope of the requests and transferred data volumes as well as the IP address. The
mentioned data is processed by us for the following purposes: ensuring a smooth
connection setup of the website; ensuring the correct display of our content; ensuring
the security of our services (e.g., protection against hacker attacks) and evaluation of
system security and stability; ensuring a comfortable use of our website. The IP address
is shortened by us for data protection reasons by the last three digits. Thus, it is no
longer possible or only possible with a disproportionate amount of time, cost, and effort
to establish a reference to a specific or identifiable natural person (so-called
anonymization). If personal data is still involved, the legal basis for this processing is Art.
6(1)(f) GDPR (legitimate interests of the provider).`,
            },
          ],
        },
        {
          title: "d) Email notifications",
          content: [
            {
              content:
                "Without your consent, we do not send you emails about news (newsletter).",
              // notice:
              //   "If we do not have consent, we also do not make use of the possibility of sending advertising emails ('opt-out') according to Art. 6 I f) GDPR in conjunction with § 7 III UWG.",
            },
            {
              content: [
                "If you have given us consent during the registration process or in your privacy cockpit, we will only send you an email with really relevant news – such as new features, special Commented [A1]: Redundant? events, content, or internship/job offers in your region or legal field.",
                "Most companies use US services such as MailChimp for sending newsletters, which transfer email addresses and data on interaction with sent emails (e.g., time of retrieval, IP address, browser type, and operating system) to servers in the USA – we do not!",
              ],
              notice:
                "Instead we may use European alternatives, such as Brevo (formerly Sendinblue), for sending emails.",
            },
            {
              content: [
                "For sending, organizing, and analyzing (e.g., whether a newsletter was opened and which links were subsequently clicked) our newsletters, we currently use the European provider Brevo (operated in Germany by Sendinblue GmbH, Köpenicker Straße 126, 10179 Berlin, Germany). The data generated in this context is stored exclusively on European servers by Brevo. We have concluded a data processing agreement with Brevo, in which we oblige Brevo to protect our customers' data and not to pass it on to third parties.",
                "The legal basis for this is Art. 6(1)(a) GDPR (consent). You can revoke your consent at any time, e.g., simply by clicking on the corresponding link at the end of each email or via your privacy cockpit in the account settings. Alternatively, you can also send your unsubscribe request at any time by email to {privacy_mail}.",
              ],
            },
          ],
        },
        {
          title: "e) No connection to social media (Instagram, Facebook, etc.)",
          content: [
            "Many operators integrate social media plugins on their sites. These allow, for example, content to be shared directly on social media without leaving the site. However, such plugins transmit personal data about the visitors of the respective site to the social media operators, regardless of whether they have an account there or not.",
            "We do not use social media plugins which transfer data to third parties.",
          ],
          notice:
            "We deliberately refrain from using such social media plugins because we believe that they do not provide the same level of data protection as we do (see the ECJ rulings on the 'Safe Harbor Agreement' and the 'EU-US Privacy Shield').",
        },
        {
          title: "f) Data from received applications",
          content: [
            "We are very pleased to receive applications for jobs, internships, or legal clerkships at LexMea. Received applications are processed exclusively for selection procedures. If we can welcome you to our team, we store your data in your employee file at least until the end of the collaboration.",
            "If a collaboration does not come about at the intended time, we only keep your data if you consent (Art. 6(1)(a) GDPR) or if we have a legitimate interest in doing so (e.g., to be able to reach you in case of important inquiries or for legal evidence purposes). You can revoke your consent at any time. Even with given consent, we delete the data at the latest 3 years later.",
            "The legal basis for this processing is Art. 6(1)(f) GDPR (legitimate interests of the provider), Art. 6(1)(b) GDPR (contract or pre-contractual measures), Art. 6(1)( a) GDPR (consent), and Art. 9(2)(a) GDPR (processing of special categories).",
            "In the case of the protection of vital interests, data processing is carried out in accordance with Art. 9(2)(c) GDPR. For purposes of health care, occupational medicine, medical diagnosis, provision or treatment in the health or social sector, or the management of systems and services in the health or social sector, the processing of personal data is carried out in accordance with Art. 9(2)(h) GDPR. If you voluntarily provide data of special categories, the processing is based on Art. 9(2)(a) GDPR.",
          ],
        },
      ],
    },
    section_5: {
      title: "5. Recipients of Personal Data",
      subsections: [
        {
          title: "a) IT Service Providers",
          content:
            "Our web hosting provider provides servers in the European Union and is therefore a recipient of your personal data under the contracts we have concluded for processing data.",
        },
        {
          title: "b) Payment Service Providers",
          content:
            "If you book a paid service extension and select an external payment service provider during the payment process, your data will be transferred to them. The legal basis for this processing is Art. 6(1)(a) GDPR (fulfillment of a contract).",
        },
        {
          title: "c) No Disclosure to Advertising Partners",
        },
      ],
      notice:
        "Many providers finance their products through the sale of personal data. At LexMea, we believe that a high-quality, privacy-friendly product can also be provided in other ways.",
    },
    section_6: {
      title: "6. Transfer of Personal Data to a Third Country",
      notice: [
        "Many providers relocate their headquarters to third countries for tax reasons. As LexMea, we are a Berlin-based start-up that consciously submits to European legal norms such as the obligation to ensure a high level of data protection and the obligation to pay fair taxes.",
        "We therefore consciously host our data on European servers, even when this is associated higher costs, and only use integrations with European server locations.",
      ],
      content:
        "Your personal data will therefore not be transferred to third countries outside the EU, unless you conclude a paid additional service and voluntarily and knowingly choose – among other possible options – a payment service provider based in such countries (such as PayPal, Mastercard, or Visa). In this case, only the data strictly necessary for the execution of these services will be transferred.",
    },
    section_7: {
      title: "7. Duration of Storage and Deletion of Personal Data",
      content: [
        "We only store data as long as it is needed to provide our services or until your account is deleted, whichever comes first. This is determined on a case-by-case basis and depends on factors such as the nature of the data, why it is collected and processed, and relevant legal or operational storage needs. The latter includes, in particular, the retention and documentation obligations under the Commercial Code (HGB) and the Tax Code (AO).",
        "If you delete your account in your profile settings, we will send you an email confirming the deletion process. This allows you to reactivate your account within 30 days and cancel the deletion process. After 30 days, the deletion process begins, and you will no longer be able to retrieve your added content, personalizations, or other data. Only to the extent necessary to preserve evidence regarding the provision of any paid services you have used, we store the metadata (but not the content) for the duration of the statutory limitation periods.",
      ],
    },
    section_8: {
      title: "8. Changes to the Privacy Policy",
      content:
        "It may become necessary in the future to change the content of this privacy policy. For example, if the legal provisions change or if LexMea introduces new functions and your data is therefore processed for purposes other than originally stated. We therefore reserve the right to change this privacy policy. We will always keep the privacy policy up to date on our website.",
    },
  },
};
