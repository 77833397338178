import type { ILegalText } from "~/models/ILegalText";
import { LexmeaApiClient } from "./apiClient";

export class LegalTextClient extends LexmeaApiClient {
  readonly #basePath = "api/legalText";

  fetchAllLegalTexts = () => {
    return this.get<ILegalText[]>(this.#basePath);
  };

  fetchLegalTextById = (id: number) => {
    return this.get<ILegalText>([this.#basePath, id].join("/"));
  };

  fetchLegalTextBySlug = (slug: string) => {
    return this.get<ILegalText>([this.#basePath, slug].join("/"));
  };
}
