export const imprint = {
  title: "Impressum",
  subtitle: "Unsere digitale Visitenkarte",
  company: {
    title: "Unternehmen",
    name: "LexMea GmbH",
    address: {
      co: "c/o Michael B. Strecker",
      street: "Leipziger Str. 44",
      city: "10117 Berlin",
      country: "Deutschland",
    },
    representatives: "Vertreten durch: ",
  },
  contact: {
    title: "Kontakt",
    email: "kontakt@lexmea.de",
  },
  registration: {
    title: "Registereintragung",
    court: "Amtsgericht Berlin Charlottenburg",
    registerNumber: "Handelsregister HRB 238763 B",
  },
  taxId: {
    title: "USt-ID.",
    id: "DE 35 15 76 540",
  },
  responsible: {
    title: "Verantwortlicher",
    law: "i.S.d. § 18 II MStV",
    details:
      "Verantwortlich für den Inhalt i.S.d. § 18 Abs. 2 MStV: Michael B. Strecker, Leipziger Str. 44, 10117 Berlin",
  },
  disputeResolution: {
    title: "Streitbeilegung",
    info: "Informationen für Verbraucher nach dem VSBG und der VO (EU) 524/2013",
    platform:
      "Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung bereit, die Sie unter {link} finden. LexMea ist nicht verpflichtet und willigt nicht ein, an Streitbeilegungsverfahren bei einer Verbraucherschlichtungsstelle teilzunehmen.",
  },
  disclaimer: {
    title: "Haftungsausschluss für Inhalte",
    content:
      "Alle Inhalte auf LexMea wurden nach bestem Wissen erstellt, es wird jedoch keine Gewähr für deren Richtigkeit und Vollständigkeit übernommen.",
  },
  copyright: {
    title: "Urheberrechte",
    content:
      "Die auf LexMea bereitgestellten Inhalte (insb. Prüfungsschemata) sind urheberrechtlich – auch gegenüber Datenbanken und ähnlichen Einrichtungen – geschützt. Eine Vervielfältigung, Verbreitung, Ausstellung oder öffentliche Widergabe der Inhalte darf daher – außerhalb der Möglichkeiten des Urheberrechtsgesetzes – ausschließlich mit schriftlicher Genehmigung LexMeas erfolgen.",
  },
};
