import { UniversitiesClient } from "~/api/university";
import type { IUniversity } from "~/models/IUniversity";

// TODO: implement this in elastic search for better performance
export function useUniversities(universityId?: Ref<number | undefined>) {
  const allUniversities = ref<IUniversity[]>([]);
  const universities = ref<IUniversity[]>([]);
  const query = ref("");
  const api = useApiClient(UniversitiesClient);

  const sortedUniversities = useSorted(universities, (a, b) =>
    a.name.localeCompare(b.name)
  );

  const selectedUniversity = useArrayFind(
    allUniversities,
    (u) => u.id === universityId?.value
  );

  debouncedWatch(
    query,
    (q) => {
      if (q) {
        const lowercaseQuery = q.toLowerCase();
        universities.value = allUniversities.value.filter((u) =>
          u.name.toLowerCase().includes(lowercaseQuery)
        );
      } else {
        universities.value = allUniversities.value;
      }
    },
    { debounce: 200 }
  );

  tryOnMounted(async () => {
    allUniversities.value = await api.fetchUniversities();
  });

  return {
    selectedUniversity,
    universities: sortedUniversities,
    query,
  };
}
