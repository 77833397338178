<template>
  <div
    class="z-10 flex h-7 w-7 items-center justify-center rounded-full bg-white drop-shadow-md"
    :class="{
      'cursor-pointer ring-2 ring-lexmea-pop-darker': hovered,
    }"
    @mouseover="hovered = true"
    @mouseout="hovered = false"
  >
    <label>
      <input
        ref="fileInput"
        name="avatar"
        type="file"
        class="hidden"
        :accept="accept"
      />
      <svg
        v-if="icon === 'photo'"
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        width="20"
        class="hover:fill-lexmea-pop-dark fill-neutral-700 hover:cursor-pointer"
        :class="{ 'fill-lexmea-pop-dark': hovered }"
      >
        >
        <path
          d="M2.583 18.333q-.729 0-1.239-.51-.511-.511-.511-1.24V6.729q0-.729.511-1.239.51-.511 1.239-.511h2.521l1.542-1.667h4.875v3.5h2.458v2.48H17.5v7.291q0 .729-.51 1.24-.511.51-1.24.51Zm6.584-2.916q1.583 0 2.677-1.094t1.094-2.677q0-1.584-1.094-2.677-1.094-1.094-2.677-1.094-1.605 0-2.688 1.094-1.083 1.093-1.083 2.677 0 1.583 1.083 2.677t2.688 1.094Zm0-1.75q-.875 0-1.448-.584-.573-.583-.573-1.437 0-.854.573-1.438.573-.583 1.448-.583.854 0 1.437.583.584.584.584 1.438t-.584 1.437q-.583.584-1.437.584Zm6.583-6.959V5.062h-1.646v-1.75h1.646V1.646h1.75v1.666h1.667v1.75H17.5v1.646Z"
        />
      </svg>
    </label>
  </div>
</template>
<script setup lang="ts">
interface Props {
  icon: string | undefined;
  accept?: string;
}
defineProps<Props>();

const fileInput = ref<HTMLInputElement | null>(null);
const hovered = ref(false);

const resetInput = () => {
  if (fileInput.value) {
    fileInput.value.value = "";
  }
};

defineExpose({
  resetInput,
});
</script>
