import { createHead } from "@unhead/vue";
import { renderDOMHead } from "@unhead/dom";
import { InferSeoMetaPlugin } from "@unhead/addons";

export function createUnhead() {
  let pauseDOMUpdates = true;

  const head = createHead({
    plugins: [InferSeoMetaPlugin()],
    hooks: {
      "dom:beforeRender": (context) => {
        context.shouldRender = !pauseDOMUpdates;
      },
    },
  });

  const { transitionStartHook, transitionEndHook } = usePageTransition();

  // Pause DOM updates during page transitions
  transitionStartHook.on(() => (pauseDOMUpdates = true));

  transitionEndHook.on(() => {
    if (pauseDOMUpdates) {
      pauseDOMUpdates = false;
      renderDOMHead(head);
    }
  });

  return head;
}
