export const ui = {
  expand_box: "Box ausklappen",
  collapse_box: "Box einklappen",
  collapse: "Einklappen",
  expand: "Ausklappen",
  close: "Schließen",
  filter: "Filter",
  save: "Speichern",
  actions: "Aktionen",
  rotate: "Rotieren",
  expand_all: "Alle ausklappen",
  local_changes_are_being_saved: "Lokale Änderungen werden gespeichert...",
  toast: {
    error: {
      error_while_syncing:
        "Fehler beim Synchronisieren. Beim nächsten Laden der Seite wird es nochmal versucht.",
    },
    success: {
      entries_while_not_logged_in_are_imported:
        "Ohne Login angelegte Personalisierungen wurden importiert.",
    },
  },
  new_version_handler: {
    new_version_available: "Eine neue Version ist verfügbar.",
    reload: "Neu laden",
    abort: "Abbrechen",
  },
  search: {
    title: "Suche",
    feedback: "Feedback",
    laws_title: "Gesetze",
    schemata_title: "Schemata & Notizen",
    no_results: "Keine Ergebnisse gefunden",
    no_laws: "Keine Gesetze zur Suchanfrage",
    no_schemata_notes: "Keine Schemata / Notizen zur Suchanfrage",
    missing_something: "Fehlt dir etwas? Lass es uns via {feedback} wissen!",
  },
};
