import { useModal } from "vue-final-modal";
import LoginModal, { type Tab } from "~/components/header/LoginModal.vue";

export function useLoginModal() {
  const { open, patchOptions } = useModal({
    component: LoginModal,
    attrs: {
      tab: "login",
    },
  });

  const openModal = (tab: Tab) => {
    patchOptions({
      attrs: { tab },
    });
    return open();
  };

  return { openModal };
}
