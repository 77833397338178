<template>
  <a :href="libraryURL" :title="$t('library')">
    <slot></slot>
  </a>
</template>
<script setup lang="ts">
const { lastSubPagePersisted } = useLibrarySubPagePersisted();
const libraryURL = computed(
  () => lastSubPagePersisted.value || "/bibliothek/gesetze"
);
</script>
