export function useFetchTabs() {
  const localTabs = useLocalTabs();
  // const { loggedIn: enabled } = storeToRefs(useUserStore());

  const query = useQuery({
    ...queries().tabs.all,
    enabled: false,
  });

  if (!import.meta.env.SSR) {
    // initialize localTabs if user has no tabs, but only on client side
    watchOnce(localTabs, async () => {
      if (localTabs.value.length > 0) {
        return;
      }

      const { data, isSuccess } = await query.refetch();
      if (isSuccess) {
        localTabs.value = data;
      }
    });
  }

  return {
    data: localTabs,
  };
}
