export const team_mode = {
  create_channel: "Create Channel",
  share_document: "Share Document",
  subscribe_channel: "Subscribe to a channel",

  join_channel_dialog: {
    text: "Join a broadcast channel to view the shared content directly in the appropriate format.",
    user_name_label: "Username*",
    channel_label: "Channel*",
    password_label: "Password*",
    channel_color_help_text: "Select an icon for channel content",
    user_not_found: "User not found.",
  },

  share_channel_dialog: {
    share_in_channel: "Share in channel",
  },

  create_channel_dialog: {
    channel_name: "Channel name",
    channel_name_help_text:
      "The channel name may contain a maximum of 20 characters (e.g., 'BGB AT WS24/25'). The username (e.g., Prof-Musterfrau) should not be included. It will be displayed anyway.",
    password_protected_help_text: "Password required?",
    password: "Password",
    password_repeat: "Password (repeat)",
    create_channel: "Create channel",
    download_allowed_help_text: "Download allowed?",
  },
};
