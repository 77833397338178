import logoBlueIcon from "~/assets/logo-blue.svg?url";
import {
  useSchemaOrg,
  defineOrganization,
  defineWebPage,
  defineWebSite,
} from "@unhead/schema-org";

export function useSchemaStructuredData() {
  useSchemaOrg([
    defineOrganization({
      name: "LexMea GmbH",
      logo: logoBlueIcon,
    }),
    defineWebSite({
      name: "LexMea",
    }),
    defineWebPage(),
  ]);
}
