import type { ICity } from "~/models/ICity";
import { LexmeaApiClient } from "./apiClient";

export class CityClient extends LexmeaApiClient {
  fetchCities = (): Promise<ICity[]> => {
    return this.get<ICity[]>("api/city");
  };

  searchCities = (query: string): Promise<ICity[]> => {
    return this.get<ICity[]>("api/search/cities", { searchParams: { query } });
  };
}
