import type { PostHog } from "posthog-js";

export const usePosthog = () => {
  const { posthogKey } = useEnvironment();

  if (!posthogKey) {
    return;
  }

  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  watchEffect(() => {
    if (import.meta.env.SSR) {
      return;
    }
    const posthog = inject("posthog") as PostHog;
    if (user.value) {
      posthog?.identify(user.value.id.toString());
    } else {
      posthog?.reset();
    }
  });
};
